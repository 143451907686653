// tslint:disable: no-any
import ActionType from "../../types/ActionType"

export interface MenuActions {
  changeSelectedMenuHasSubItems: (payload: any) => ActionType
  changeDefaultClassnames: (currentClasses: string) => ActionType
  addContainerClassname: (classname: string, currentClasses: string) => ActionType
  clickOnMobileMenu: (currentClasses: string) => ActionType
  setContainerClassnames: (clickIndex: number, currentClasses: string, selectedMenuHasSubItems: boolean) => ActionType
}

class Menu {
  public static MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES"
  public static MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME"
  public static MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU"
  public static MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES"
  public static MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS"

  public static changeSelectedMenuHasSubItems = (payload: any) => {
    return {
      payload,
      type: Menu.MENU_CHANGE_HAS_SUB_ITEM_STATUS,
    }
  }

  public static changeDefaultClassnames = (currentClasses: string) => {
    return {
      payload: currentClasses,
      type: Menu.MENU_CHANGE_DEFAULT_CLASSES,
    }
  }

  public static addContainerClassname = (classname: string, currentClasses: string) => {
    const newClasses: string = !(currentClasses.indexOf(classname) > -1) ? `${currentClasses} ${classname}` : currentClasses

    return {
      payload: newClasses,
      type: Menu.MENU_CONTAINER_ADD_CLASSNAME,
    }
  }

  public static clickOnMobileMenu = (strCurrentClasses: string) => {
    const currentClasses: string[] | "" = strCurrentClasses ? strCurrentClasses.split(" ").filter((x) => x !== "" && x !== "sub-show-temporary") : ""

    let nextClasses = ""

    if (currentClasses.includes("main-show-temporary")) {
      nextClasses = currentClasses ? currentClasses.filter((x) => x !== "main-show-temporary").join(" ") : currentClasses
    } else {
      nextClasses = currentClasses ? `${currentClasses.join(" ")} main-show-temporary` : currentClasses
    }

    return {
      payload: { containerClassnames: nextClasses, menuClickCount: 0 },
      type: Menu.MENU_CLICK_MOBILE_MENU,
    }
  }

  public static setContainerClassnames = (clickIndex: number, currentClasses: string, selectedMenuHasSubItems: boolean) => {
    const newCurrentClasses: string[] | "" = currentClasses ? currentClasses.split(" ").filter((x: string) => x !== "") : ""
    let nextClasses = ""
    let newClickIndex: number = clickIndex

    if (!selectedMenuHasSubItems) {
      if (newCurrentClasses.includes("menu-default") && (newClickIndex % 4 === 0 || newClickIndex % 4 === 3)) {
        newClickIndex = 1
      }

      if (newCurrentClasses.includes("menu-sub-hidden") && newClickIndex % 4 === 2) {
        newClickIndex = 0
      }

      if (newCurrentClasses.includes("menu-hidden") && (newClickIndex % 4 === 2 || newClickIndex % 4 === 3)) {
        newClickIndex = 0
      }
    }

    if (newClickIndex % 4 === 0) {
      if (newCurrentClasses.includes("menu-default") && newCurrentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-default menu-sub-hidden"
      } else if (newCurrentClasses.includes("menu-default")) {
        nextClasses = "menu-default"
      } else if (newCurrentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-sub-hidden"
      } else if (newCurrentClasses.includes("menu-hidden")) {
        nextClasses = "menu-hidden"
      }
      newClickIndex = 0
    } else if (newClickIndex % 4 === 1) {
      if (newCurrentClasses.includes("menu-default") && newCurrentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-default menu-sub-hidden"
      } else if (newCurrentClasses.includes("menu-default")) {
        nextClasses = "menu-default sub-hidden"
      } else if (newCurrentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-sub-hidden"
      } else if (newCurrentClasses.includes("menu-hidden")) {
        nextClasses = "menu-hidden main-show-temporary"
      }
    } else if (newClickIndex % 4 === 2) {
      if (newCurrentClasses.includes("menu-default") && newCurrentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-default menu-sub-hidden sub-hidden"
      } else if (newCurrentClasses.includes("menu-default")) {
        nextClasses = "menu-default"
      } else if (newCurrentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-sub-hidden sub-hidden"
      } else if (newCurrentClasses.includes("menu-hidden")) {
        nextClasses = "menu-hidden main-show-temporary sub-show-temporary"
      }
    } else if (newClickIndex % 4 === 3) {
      if (newCurrentClasses.includes("menu-default") && newCurrentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-default menu-sub-hidden sub-show-temporary"
      } else if (newCurrentClasses.includes("menu-default")) {
        nextClasses = "menu-default sub-hidden"
      } else if (newCurrentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-sub-hidden sub-show-temporary"
      } else if (newCurrentClasses.includes("menu-hidden")) {
        nextClasses = "menu-hidden main-show-temporary"
      }
    }

    if (newCurrentClasses.includes("menu-mobile")) {
      nextClasses += " menu-mobile"
    }

    return {
      payload: {
        containerClassnames: nextClasses,
        menuClickCount: newClickIndex,
      },
      type: Menu.MENU_SET_CLASSNAMES,
    }
  }
}

export default Menu
