import ActionType from "../../types/ActionType"
import PersonDetailsActions from "../actions/personDetails"
import { PersonDetailsDefinitionsType } from "../../types/PersonDetailsType"

export interface PersonDetailsStore {
  errorMessage: string
  loadingPersonDetailDefinitions: boolean
  personDetailDefinitions: PersonDetailsDefinitionsType | null
}

const INITIAL_STATE: PersonDetailsStore = {
  errorMessage: "",
  loadingPersonDetailDefinitions: false,
  personDetailDefinitions: null,
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case PersonDetailsActions.FETCH_PERSONDETAILDEFINITIONS_TRIGGER: {
      return {
        ...state,
        loadingPersonDetailDefinitions: true,
      }
    }

    case PersonDetailsActions.FETCH_PERSONDETAILDEFINITIONS_SUCCESS: {
      return {
        ...state,
        loadingPersonDetailDefinitions: false,
        personDetailDefinitions: action.payload,
      }
    }

    case PersonDetailsActions.FETCH_PERSONDETAILDEFINITIONS_ERROR: {
      return {
        ...state,
        loadingPersonDetailDefinitions: false,
        errorMessage: action.payload,
      }
    }

    case PersonDetailsActions.CLEAR_PERSONDETAILDEFINITIONS:
      return INITIAL_STATE

    default:
      return state
  }
}
