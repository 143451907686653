import dayjs from "dayjs"
import { isEmpty, orderBy } from "lodash"

import { OptionValue } from "../components/common/FormSelectField"
import { getStateShort } from "../constants/states"
import { AddressType, CreateManagerBody, CreateOrganizationBody, EditAddressBody, EditOrganizationBody, Organization } from "../types/OrganizationType"
import { CreatePersonOrganization, EditPersonOrganizations, PersonDetail } from "../types/PersonType"
import { Program } from "../types/ProgramsType"
import PhoneUtils from "../utils/PhoneUtils"
import TextHelper from "./TextHelper"

type GetAddressForBody = (address?: AddressType) => EditAddressBody
type GetManagerForBody = (values: CreateManagerBody) => CreateManagerBody
type GetPersonDetailPayload = (personDetail?: PersonDetail | null) => PersonDetail | undefined
type GetPersonOrganizationForBody = (values: CreatePersonOrganization) => CreatePersonOrganization
type GetPersonOrganizationsForEdit = (values: EditPersonOrganizations) => any
type GetOrganizationForBody = (values: Organization | CreateOrganizationBody) => EditOrganizationBody
type IsNotNullOrEmpty = (value?: string | (string | null)[] | null) => boolean
type RoundPrecision = (value: number, precision?: number) => number
type SecondsToMinutes = (seconds: number) => number

export const secondsToMinutes: SecondsToMinutes = (seconds) => seconds / 60

export const roundPrecision: RoundPrecision = (value, precision = 0) => {
  if (precision <= 0) return Math.round(value)

  const multiplier: number = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

const getAddressString: (address?: AddressType) => string = (address) => {
  return address ? `${address.street}, ${address.city}, ${address.stateShort}, ${address.zipcode}` : "-"
}

const getManagerForBody: GetManagerForBody = (values) => ({
  email: values.email.trim(),
  firstName: values.firstName.trim(),
  lastName: values.lastName.trim(),
  password: values.password,
  phone: values.phone ? PhoneUtils.parseToInternational(values.phone) : "",
  userName: values.userName.trim(),
  externalPersonId: values.externalPersonId?.trim(),
})

const getOrganizationForBody: GetOrganizationForBody = (values) => ({
  active: values.active,
  address: getAddressForBody(values.address),
  contactPhone: values.contactPhone ? PhoneUtils.parseToInternational(values.contactPhone) : "",
  email: values.email.trim(),
  name: values.name.trim(),
  primaryContact: values.primaryContact.trim(),
  phone: values.phone ? PhoneUtils.parseToInternational(values.phone) : "",
  externalOrgId: values.externalOrgId?.trim(),
})

const getAddressForBody: GetAddressForBody = (address) => {
  if (address) {
    return {
      city: address.city.trim(),
      state: address.state,
      stateShort: getStateShort(address.state),
      street: address.street.trim(),
      street2: address.street2?.trim(),
      zipcode: address.zipcode,
    }
  }

  return {
    city: "",
    state: "",
    stateShort: "",
    street: "",
    street2: "",
    zipcode: "",
  }
}

const getPersonOrganizationForBody: GetPersonOrganizationForBody = (values) => {
  if (values) {
    return {
      active: values.active,
      email: isNotNullOrEmpty(values.email) ? values.email!.trim() : null,
      firstName: isNotNullOrEmpty(values.firstName) ? values.firstName!.trim() : null,
      lastName: isNotNullOrEmpty(values.lastName) ? values.lastName!.trim() : null,
      organizationId: values.organizationId,
      phone: PhoneUtils.parseToInternational(values.phone),
      phoneAlternate: isNotNullOrEmpty(values.phoneAlternate) ? PhoneUtils.parseToInternational(values.phoneAlternate!) : null,
      preferredName: isNotNullOrEmpty(values.preferredName) ? values.preferredName!.trim() : null,
      programId: values.programId,
      organizationPersonStatusId: values.organizationPersonStatusId,
      roleId: values.roleId,
      pin: values?.pin || null,
      optedOut: values.optedOut ? values.optedOut!.map((value) => value.toLowerCase()) : [],
      personDetail: getPersonDetailPayload(values.personDetail),
      surveyOptOuts: values.surveyOptOuts ?? [],
      customFields: values.customFields ?? {},
    }
  }

  return {
    active: true,
    email: "",
    firstName: "",
    lastName: "",
    organizationId: "",
    phone: "",
    phoneAlternate: "",
    preferredName: "",
    programId: "",
    roleId: "",
    pin: "",
    surveyOptOuts: [],
  }
}

export const getPersonDetailPayload: GetPersonDetailPayload = (personDetail) => {
  if (!personDetail) return

  return {
    zipCode: isNotNullOrEmpty(personDetail.zipCode) ? personDetail.zipCode : null,
    dateOfBirth: personDetail.dateOfBirth && dayjs(personDetail.dateOfBirth).isValid() ? personDetail.dateOfBirth : null,
    gender: isNotNullOrEmpty(personDetail.gender) ? personDetail.gender : null,
    preferredLanguage: isNotNullOrEmpty(personDetail.preferredLanguage) ? personDetail.preferredLanguage : null,
    hobbies: isNotNullOrEmpty(personDetail.hobbies) ? personDetail.hobbies : null,
    phoneCallBehavior: isNotNullOrEmpty(personDetail.phoneCallBehavior) ? personDetail.phoneCallBehavior : null,
    preferredTimeForCall: isNotNullOrEmpty(personDetail.preferredTimeForCall) ? personDetail.preferredTimeForCall : null,
    preferredMethodOfCommunication: isNotNullOrEmpty(personDetail.preferredMethodOfCommunication) ? personDetail.preferredMethodOfCommunication : null,
    phoneType: isNotNullOrEmpty(personDetail.phoneType) ? personDetail.phoneType : null,
    phoneAlternateType: isNotNullOrEmpty(personDetail.phoneAlternateType) ? personDetail.phoneAlternateType : null,
  }
}

const getPersonOrganizationsForEdit: GetPersonOrganizationsForEdit = (values) => {
  if (values) {
    return {
      person: {
        email: TextHelper.getStringDefaultValue(values.email),
        firstName: TextHelper.getStringDefaultValue(values.firstName),
        lastName: TextHelper.getStringDefaultValue(values.lastName),
        phone: isNotNullOrEmpty(values.phone) ? PhoneUtils.parseToInternational(values.phone!) : null,
        phoneAlternate: isNotNullOrEmpty(values.phoneAlternate) ? PhoneUtils.parseToInternational(values.phoneAlternate!) : null,
        preferredName: TextHelper.getStringDefaultValue(values.preferredName),
        userName: TextHelper.getStringDefaultValue(values.userName),
        personDetailId: TextHelper.getStringDefaultValue(values.personDetailId),
        personDetail: {
          zipCode: TextHelper.getStringDefaultValue(values.personDetail?.zipCode),
          dateOfBirth: TextHelper.getStringDefaultValue(values.personDetail?.dateOfBirth?.toString()),
          gender: TextHelper.getStringDefaultValue(values.personDetail?.gender),
          preferredLanguage: TextHelper.getStringDefaultValue(values.personDetail?.preferredLanguage),
          hobbies: TextHelper.getStringDefaultValue(values.personDetail?.hobbies),
          phoneCallBehavior: TextHelper.getStringDefaultValue(values.personDetail?.phoneCallBehavior),
          preferredTimeForCall: values.personDetail?.preferredTimeForCall && !isEmpty(values.personDetail?.preferredTimeForCall) ? values.personDetail?.preferredTimeForCall : null,
          preferredMethodOfCommunication:
            values.personDetail?.preferredMethodOfCommunication && !isEmpty(values.personDetail?.preferredMethodOfCommunication) ? values.personDetail?.preferredMethodOfCommunication : null,
          phoneType: TextHelper.getStringDefaultValue(values.personDetail?.phoneType),
          phoneAlternateType: TextHelper.getStringDefaultValue(values.personDetail?.phoneAlternateType),
        },
        optedOut: values.optedOut ? values.optedOut!.map((value) => value.toLowerCase()) : [],
        externalPersonId: TextHelper.getStringDefaultValue(values.externalPersonId),
      },
      active: values.active,
      pin: TextHelper.getStringDefaultValue(values?.pin || null),
      organizationId: values.organizationId,
      programId: values.programId,
      organizationPersonStatusId: values.organizationPersonStatusId,
      roleId: values.roleId,
      personId: values.personId,
      surveyOptOuts: values.surveyOptOuts ?? [],
      customFields: values.customFields ?? [],
    }
  }

  return {
    active: true,
    email: "",
    firstName: "",
    lastName: "",
    organizationId: "",
    phone: "",
    phoneAlternate: "",
    pin: "",
    preferredName: "",
    program: "",
    surveyOptOuts: [],
    customFields: [],
  }
}

const getProgramsOptions: (programs: Program[]) => OptionValue[] = (programs) => {
  const options: OptionValue[] = programs.map((program) => ({
    label: program.name,
    value: program?.id || "",
    object: program,
  }))
  return options
}

const isNotNullOrEmpty: IsNotNullOrEmpty = (value) => {
  if (!value) return false

  if (Array.isArray(value)) {
    return value.length > 0
  }

  return value.toString().trim().length > 0
}

export { getAddressString, getManagerForBody, getOrganizationForBody, getPersonOrganizationsForEdit, getProgramsOptions, getPersonOrganizationForBody, isNotNullOrEmpty }
