import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)

type IsValidDateFormat = (date?: string, format?: string) => boolean

export const isValidDateFormat: IsValidDateFormat = (date, format = "MM/DD/YYYY") => {
  if (!date) return false
  const formattedDate: string = dayjs(date, format).format(format)
  return date.length === format.length && formattedDate === date
}

export const dateFormatForReports = (date: string | Date | null) => {
  return date ? `${dayjs(date).utc().format("MMMM DD, YYYY") || ""} ${dayjs(date).utc().format("hh:mm A") || ""}` : ""
}

export const isSameOrAfter = (date: Date, dateToCompare: Date) => {
  return dayjs(date).isSame(dateToCompare, "date") || dayjs(date).isAfter(dateToCompare, "date")
}
