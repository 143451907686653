import { Dispatch } from "redux"

import API from "../../Api"
import AuthHelper from "../../helpers/AuthHelper"
import { CreatePersonOrganizationSurveyAndAswer, CreateSurveyAnswer } from "../../types/SurveyType"

type Fetch = (id: string) => (dispatch: Dispatch) => void
type CreateAndAnswer = (values: CreatePersonOrganizationSurveyAndAswer) => (dispatch: Dispatch) => void
type CompleteAnswers = (values: CreateSurveyAnswer) => (dispatch: Dispatch) => void
export interface PersonOrganizationSurveysActions {
  fetchPersonOrganizationSurvey: Fetch
  createPersonOrganizationSurveyAndAnswer: CreateAndAnswer
  completePersonOrganizationSurveyAnswers: CompleteAnswers
  cleanPersonOrganizationSurveyStatus: () => void
}

class PersonOrganizationSurveys {
  public static FETCH_PERSON_ORGANIZATION_SURVEYS_TRIGGER = "FETCH_PERSON_ORGANIZATION_SURVEYS_TRIGGER"
  public static FETCH_PERSON_ORGANIZATION_SURVEYS_SUCCESS = "FETCH_PERSON_ORGANIZATION_SURVEYS_SUCCESS"
  public static FETCH_PERSON_ORGANIZATION_SURVEYS_ERROR = "FETCH_PERSON_ORGANIZATION_SURVEYS_ERROR"

  public static CREATE_PERSON_ORGANIZATION_SURVEY_AND_ANSWER_TRIGGER = "CREATE_PERSON_ORGANIZATION_SURVEY_AND_ANSWER_TRIGGER"
  public static CREATE_PERSON_ORGANIZATION_SURVEY_AND_ANSWER_SUCCESS = "CREATE_PERSON_ORGANIZATION_SURVEY_AND_ANSWER_SUCCESS"
  public static CREATE_PERSON_ORGANIZATION_SURVEY_AND_ANSWER_ERROR = "CREATE_PERSON_ORGANIZATION_SURVEY_AND_ANSWER_ERROR"

  public static COMPLETE_PERSON_ORGANIZATION_SURVEY_ANSWERS_TRIGGER = "COMPLETE_PERSON_ORGANIZATION_SURVEY_ANSWERS_TRIGGER"
  public static COMPLETE_PERSON_ORGANIZATION_SURVEY_ANSWERS_SUCCESS = "COMPLETE_PERSON_ORGANIZATION_SURVEY_ANSWERS_SUCCESS"
  public static COMPLETE_PERSON_ORGANIZATION_SURVEY_ANSWERS_ERROR = "COMPLETE_PERSON_ORGANIZATION_SURVEY_ANSWERS_ERROR"

  public static CLEAN_PERSON_ORGANIZATION_SURVEY_STATUS = "CLEAN_PERSON_ORGANIZATION_SURVEY_STATUS"

  public static fetchPersonOrganizationSurvey: Fetch = (id: string) => async (dispatch) => {
    dispatch({
      type: PersonOrganizationSurveys.FETCH_PERSON_ORGANIZATION_SURVEYS_TRIGGER,
    })

    try {
      const {
        data: { data },
        status,
      } = await API.get(`/v2/personorganizationssurveys/${id}`, { headers: AuthHelper.getAdminHeaders() })

      if (status) {
        dispatch({
          payload: data,
          type: PersonOrganizationSurveys.FETCH_PERSON_ORGANIZATION_SURVEYS_SUCCESS,
        })
      }
      return
    } catch (err) {
      dispatch({
        payload: "Oops!... Looks like an error occurred!",
        type: PersonOrganizationSurveys.FETCH_PERSON_ORGANIZATION_SURVEYS_ERROR,
      })
    }
  }

  public static createPersonOrganizationSurveyAndAnswer: CreateAndAnswer = (values) => async (dispatch) => {
    dispatch({
      type: PersonOrganizationSurveys.CREATE_PERSON_ORGANIZATION_SURVEY_AND_ANSWER_TRIGGER,
    })

    try {
      const {
        data: { data },
        status,
      } = await API.post(`/v2/personorganizationssurveys`, values, { headers: AuthHelper.getAdminHeaders() })

      if (status) {
        dispatch({
          payload: data,
          type: PersonOrganizationSurveys.CREATE_PERSON_ORGANIZATION_SURVEY_AND_ANSWER_SUCCESS,
        })
      }
      return
    } catch (err) {
      dispatch({
        payload: "Oops!... Looks like an error occurred!",
        type: PersonOrganizationSurveys.CREATE_PERSON_ORGANIZATION_SURVEY_AND_ANSWER_ERROR,
      })
    }
  }

  public static completePersonOrganizationSurveyAnswers: CompleteAnswers = (values) => async (dispatch) => {
    dispatch({
      type: PersonOrganizationSurveys.COMPLETE_PERSON_ORGANIZATION_SURVEY_ANSWERS_TRIGGER,
    })

    try {
      const {
        data: { data },
        status,
      } = await API.post(`/v2/personorganizationssurveys/${values.id}/answers`, values, { headers: AuthHelper.getAdminHeaders() })

      PersonOrganizationSurveys.fetchPersonOrganizationSurvey(values.id)(dispatch)

      if (status) {
        dispatch({
          payload: data,
          type: PersonOrganizationSurveys.COMPLETE_PERSON_ORGANIZATION_SURVEY_ANSWERS_SUCCESS,
        })
      }
      return
    } catch (err) {
      dispatch({
        payload: "Oops!... Looks like an error occurred!",
        type: PersonOrganizationSurveys.COMPLETE_PERSON_ORGANIZATION_SURVEY_ANSWERS_ERROR,
      })
    }
  }

  public static cleanPersonOrganizationSurveyStatus = () => ({
    type: PersonOrganizationSurveys.CLEAN_PERSON_ORGANIZATION_SURVEY_STATUS,
  })
}

export default PersonOrganizationSurveys
