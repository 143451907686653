export enum RoleType {
  RECIPIENT = "RECIPIENT",
  CALLER = "CALLER",
  OUTREACH = "OUTREACH",
  INACTIVE = "INACTIVE",
}

export const roles: string[] = [RoleType.RECIPIENT, RoleType.CALLER, RoleType.OUTREACH]

export enum RoleCounterKey {
  TOTAL_OUTREACHES = "totalOutreaches",
  TOTAL_RECIPIENTS = "totalRecipients",
  TOTAL_CALLERS = "totalCallers",
  TOTAL_INACTIVE = "totalInactive",
}

export enum PasswordStatuses {
  VALID = "VALID",
  EXPIRED = "EXPIRED",
}
