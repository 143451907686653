export interface Pager {
  page: number
  totalItems: number
  itemsPerPage: number
  totalPages: number
}

export const PagerInitialValue: Pager = {
  page: 1,
  totalItems: 0,
  itemsPerPage: 10,
  totalPages: 0,
}
