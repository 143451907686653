import ActionType from "../../types/ActionType"
import CallAnalyticsActions from "../actions/callAnalytics"
import { CallAnalytics, ExportedCallAnalyticsReport } from "../../types/CallAnalyticsType"

export enum CallAnalyticsExportStatus {
  NOT_EXPORTED = 0,
  TRIGGERED,
  SUCCESS,
  ERROR,
}

export interface CallAnalayticsStatus {
  exportStatus: CallAnalyticsExportStatus
  fileDownloaded: boolean
}

export interface CallAnalyticsStore {
  callAnalytics: CallAnalytics[]
  callAnalyticsToExportReportOne: ExportedCallAnalyticsReport | null
  callAnalyticsToExportReportTwo: ExportedCallAnalyticsReport | null
  callAnalyticsToExportReportThree: ExportedCallAnalyticsReport | null
  callAnalyticsExportStatusOne: CallAnalayticsStatus
  callAnalyticsExportStatusTwo: CallAnalayticsStatus
  callAnalyticsExportStatusThree: CallAnalayticsStatus
  callAnalyticsReportRequestStatusOne: CallAnalayticsStatus
  callAnalyticsReportRequestStatusTwo: CallAnalayticsStatus
  callAnalyticsReportRequestStatusThree: CallAnalayticsStatus
  errorMessage: string
  loadingCallAnalytics: boolean
}

const STATUS_INITIAL_STATE: CallAnalayticsStatus = {
  exportStatus: CallAnalyticsExportStatus.NOT_EXPORTED,
  fileDownloaded: false,
}

const INITIAL_STATE: CallAnalyticsStore = {
  callAnalytics: [],
  callAnalyticsToExportReportOne: null,
  callAnalyticsToExportReportTwo: null,
  callAnalyticsToExportReportThree: null,
  callAnalyticsExportStatusOne: STATUS_INITIAL_STATE,
  callAnalyticsExportStatusTwo: STATUS_INITIAL_STATE,
  callAnalyticsExportStatusThree: STATUS_INITIAL_STATE,
  callAnalyticsReportRequestStatusOne: STATUS_INITIAL_STATE,
  callAnalyticsReportRequestStatusTwo: STATUS_INITIAL_STATE,
  callAnalyticsReportRequestStatusThree: STATUS_INITIAL_STATE,
  errorMessage: "",
  loadingCallAnalytics: true,
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case CallAnalyticsActions.FETCH_ONE_CALL_ANALYTICS_TRIGGER: {
      return {
        ...state,
        loadingCallAnalytics: true,
      }
    }

    case CallAnalyticsActions.FETCH_ONE_CALL_ANALYTICS_SUCCESS: {
      return {
        ...state,
        loadingCallAnalytics: false,
        callAnalytics: action.payload.callLogs,
        dateFrom: action.payload.callLogs.dateFrom,
        dateTo: action.payload.callLogs.dateTo,
      }
    }

    case CallAnalyticsActions.FETCH_ONE_CALL_ANALYTICS_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        loadingCallAnalytics: false,
      }
    }

    case CallAnalyticsActions.EXPORT_ONE_CALL_ANALYTICS_TRIGGER: {
      return {
        ...state,
        callAnalyticsToExportReportOne: null,
        callAnalyticsExportStatusOne: {
          ...state.callAnalyticsExportStatusOne,
          exportStatus: CallAnalyticsExportStatus.TRIGGERED,
        },
      }
    }

    case CallAnalyticsActions.EXPORT_ONE_CALL_ANALYTICS_SUCCESS: {
      return {
        ...state,
        callAnalyticsToExportReportOne: action.payload,
        callAnalyticsExportStatusOne: {
          ...state.callAnalyticsExportStatusOne,
          exportStatus: CallAnalyticsExportStatus.SUCCESS,
        },
      }
    }

    case CallAnalyticsActions.EXPORT_ONE_CALL_ANALYTICS_ERROR: {
      return {
        ...state,
        callAnalyticsToExportReportOne: null,
        callAnalyticsExportStatusOne: {
          ...state.callAnalyticsExportStatusOne,
          exportStatus: CallAnalyticsExportStatus.ERROR,
        },
      }
    }

    case CallAnalyticsActions.EXPORT_TWO_CALL_ANALYTICS_TRIGGER: {
      return {
        ...state,
        callAnalyticsToExportReportTwo: null,
        callAnalyticsExportStatusTwo: {
          ...state.callAnalyticsExportStatusTwo,
          exportStatus: CallAnalyticsExportStatus.TRIGGERED,
        },
      }
    }

    case CallAnalyticsActions.EXPORT_TWO_CALL_ANALYTICS_SUCCESS: {
      return {
        ...state,
        callAnalyticsToExportReportTwo: action.payload,
        callAnalyticsExportStatusTwo: {
          ...state.callAnalyticsExportStatusTwo,
          exportStatus: CallAnalyticsExportStatus.SUCCESS,
        },
      }
    }

    case CallAnalyticsActions.EXPORT_TWO_CALL_ANALYTICS_ERROR: {
      return {
        ...state,
        callAnalyticsToExportReportTwo: null,
        callAnalyticsExportStatusTwo: {
          ...state.callAnalyticsExportStatusTwo,
          exportStatus: CallAnalyticsExportStatus.ERROR,
        },
      }
    }

    case CallAnalyticsActions.EXPORT_THREE_CALL_ANALYTICS_TRIGGER: {
      return {
        ...state,
        callAnalyticsToExportReportThree: null,
        callAnalyticsExportStatusThree: {
          ...state.callAnalyticsExportStatusThree,
          exportStatus: CallAnalyticsExportStatus.TRIGGERED,
        },
      }
    }

    case CallAnalyticsActions.EXPORT_THREE_CALL_ANALYTICS_SUCCESS: {
      return {
        ...state,
        callAnalyticsToExportReportThree: action.payload,
        callAnalyticsExportStatusThree: {
          ...state.callAnalyticsExportStatusThree,
          exportStatus: CallAnalyticsExportStatus.SUCCESS,
        },
      }
    }

    case CallAnalyticsActions.EXPORT_THREE_CALL_ANALYTICS_ERROR: {
      return {
        ...state,
        callAnalyticsToExportReportOne: null,
        callAnalyticsExportStatusOne: {
          ...state.callAnalyticsExportStatusOne,
          exportStatus: CallAnalyticsExportStatus.ERROR,
        },
      }
    }

    case CallAnalyticsActions.CREATE_REPORT_REQUEST_ONE_CALL_ANALYTICS_TRIGGER: {
      return {
        ...state,
        callAnalyticsReportRequestStatusOne: {
          exportStatus: CallAnalyticsExportStatus.TRIGGERED,
        },
      }
    }

    case CallAnalyticsActions.CREATE_REPORT_REQUEST_ONE_CALL_ANALYTICS_SUCCESS: {
      return {
        ...state,
        callAnalyticsReportRequestStatusOne: {
          exportStatus: CallAnalyticsExportStatus.SUCCESS,
        },
      }
    }

    case CallAnalyticsActions.CREATE_REPORT_REQUEST_ONE_CALL_ANALYTICS_ERROR: {
      return {
        ...state,
        callAnalyticsReportRequestStatusThree: {
          exportStatus: CallAnalyticsExportStatus.ERROR,
        },
      }
    }
    case CallAnalyticsActions.CREATE_REPORT_REQUEST_TWO_CALL_ANALYTICS_TRIGGER: {
      return {
        ...state,
        callAnalyticsReportRequestStatusTwo: {
          exportStatus: CallAnalyticsExportStatus.TRIGGERED,
        },
      }
    }

    case CallAnalyticsActions.CREATE_REPORT_REQUEST_TWO_CALL_ANALYTICS_SUCCESS: {
      return {
        ...state,
        callAnalyticsReportRequestStatusTwo: {
          exportStatus: CallAnalyticsExportStatus.SUCCESS,
        },
      }
    }

    case CallAnalyticsActions.CREATE_REPORT_REQUEST_TWO_CALL_ANALYTICS_ERROR: {
      return {
        ...state,
        callAnalyticsReportRequestStatusTwo: {
          exportStatus: CallAnalyticsExportStatus.ERROR,
        },
      }
    }

    case CallAnalyticsActions.CREATE_REPORT_REQUEST_THREE_CALL_ANALYTICS_TRIGGER: {
      return {
        ...state,
        callAnalyticsReportRequestStatusThree: {
          exportStatus: CallAnalyticsExportStatus.TRIGGERED,
        },
      }
    }

    case CallAnalyticsActions.CREATE_REPORT_REQUEST_THREE_CALL_ANALYTICS_SUCCESS: {
      return {
        ...state,
        callAnalyticsReportRequestStatusThree: {
          exportStatus: CallAnalyticsExportStatus.SUCCESS,
        },
      }
    }

    case CallAnalyticsActions.CREATE_REPORT_REQUEST_THREE_CALL_ANALYTICS_ERROR: {
      return {
        ...state,
        callAnalyticsReportRequestStatusThree: {
          exportStatus: CallAnalyticsExportStatus.ERROR,
        },
      }
    }

    case CallAnalyticsActions.SET_FILE_DOWNLOADED: {
      return {
        ...state,
        [action.payload.report]: {
          exportStatus: CallAnalyticsExportStatus.NOT_EXPORTED,
          fileDownloaded: action.payload.fileDownloaded,
        },
      }
    }

    case CallAnalyticsActions.EXPORT_CALL_ANALYTICS_CLEAR: {
      return {
        ...state,
        callAnalyticsToExportReportOne: null,
        callAnalyticsToExportReportTwo: null,
        callAnalyticsToExportReportThree: null,
        callAnalyticsToExportReportFour: null,
        callAnalyticsExportStatusOne: STATUS_INITIAL_STATE,
        callAnalyticsExportStatusTwo: STATUS_INITIAL_STATE,
        callAnalyticsExportStatusThree: STATUS_INITIAL_STATE,
        callAnalyticsExportStatusFour: STATUS_INITIAL_STATE,
      }
    }

    default: {
      return state
    }
  }
}
