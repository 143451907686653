import { AxiosResponse } from "axios"
import { Dispatch } from "redux"

import API from "../../Api"
import { BroadcastResponseActionType, BroadcastSortBy, BroadcastSortDirection, BroadcastStatusEnum, BroadcastTypesEnum } from "../../constants/broadcast"
import { UNKNOWN_ERROR } from "../../constants/messages"
import AuthHelper from "../../helpers/AuthHelper"
import { isNotNullOrEmpty } from "../../helpers/FormatHelper"
import { ProgramRecipientContactType } from "../../types/ProgramsType"
import { AudioRecorded } from "../../hooks/useAudioRecorder"
import { BroadcastDto } from "../../views/app/broadcasts/broadcast.types"

export interface FetchBroadcastsFilter {
  page: number
  searchBy?: string
  sortBy: BroadcastSortBy
  sortDirection: BroadcastSortDirection
  organizationId: string | null
  programId?: string
  recipientId?: string
  type?: BroadcastTypesEnum
  status?: BroadcastStatusEnum
}

type CreateBroadcast = (payload: BroadcastDto, action: BroadcastResponseActionType) => (dispatch: Dispatch) => void
type DuplicateBroadcast = (payload: BroadcastDto, callback: (newId: string) => void) => (dispatch: Dispatch) => void
type EditBroadcast = (id: string, payload: BroadcastDto, action: BroadcastResponseActionType) => (dispatch: Dispatch) => void
type DeleteBroadcast = (id: string) => (dispatch: Dispatch) => void
type ArchiveBroadcast = (id: string) => (dispatch: Dispatch) => void
type FetchBroadcast = (id: string) => (dispatch: Dispatch) => void
type FetchBroadcasts = (filter: FetchBroadcastsFilter) => (dispatch: Dispatch) => void
type FetchBroadcastProgramsRecipients = (organizationId: string, contactType?: ProgramRecipientContactType, includeStatus?: boolean) => (dispatch: Dispatch) => void
type ClearError = () => (dispatch: Dispatch) => void
type ClearBroadcast = () => (dispatch: Dispatch) => void
type UploadAudioBlob = (blob: Blob, audio?: AudioRecorded) => (dispatch: Dispatch) => void
type DeleteAudio = (audio: AudioRecorded) => (dispatch: Dispatch) => void

export interface BroadcastActions {
  createBroadcast: CreateBroadcast
  editBroadcast: EditBroadcast
  deleteBroadcast: DeleteBroadcast
  archiveBroadcast: ArchiveBroadcast
  duplicateBroadcast: DuplicateBroadcast
  fetchBroadcast: FetchBroadcast
  fetchBroadcasts: FetchBroadcasts
  fetchBroadcastProgramsRecipients: FetchBroadcastProgramsRecipients
  clearError: ClearError
  clearBroadcast: ClearBroadcast
  clearBroadcastMessages: ClearBroadcast
  uploadAudioBlob: UploadAudioBlob
  deleteAudio: DeleteAudio
}

class Actions {
  public static CLEAR_ERROR = "CLEAR_ERROR"
  public static CLEAR_BROADCAST_TRIGGER = "CLEAR_BROADCAST_TRIGGER"
  public static CLEAR_BROADCAST_MESSAGES_TRIGGER = "CLEAR_BROADCAST_MESSAGES_TRIGGER"
  public static CREATE_BROADCAST_SUCCESS = "CREATE_BROADCAST_SUCCESS"
  public static CREATE_BROADCAST_TRIGGER = "CREATE_BROADCAST_TRIGGER"
  public static CREATE_BROADCAST_ERROR = "CREATE_BROADCAST_ERROR"
  public static EDIT_BROADCAST_SUCCESS = "EDIT_BROADCAST_SUCCESS"
  public static EDIT_BROADCAST_TRIGGER = "EDIT_BROADCAST_TRIGGER"
  public static EDIT_BROADCAST_ERROR = "EDIT_BROADCAST_ERROR"
  public static DELETE_BROADCAST_SUCCESS = "DELETE_BROADCAST_SUCCESS"
  public static DELETE_BROADCAST_TRIGGER = "DELETE_BROADCAST_TRIGGER"
  public static DELETE_BROADCAST_ERROR = "DELETE_BROADCAST_ERROR"
  public static ARCHIVE_BROADCAST_SUCCESS = "ARCHIVE_BROADCAST_SUCCESS"
  public static ARCHIVE_BROADCAST_TRIGGER = "ARCHIVE_BROADCAST_TRIGGER"
  public static ARCHIVE_BROADCAST_ERROR = "ARCHIVE_BROADCAST_ERROR"
  public static DUPLICATE_BROADCAST_SUCCESS = "DUPLICATE_BROADCAST_SUCCESS"
  public static DUPLICATE_BROADCAST_TRIGGER = "DUPLICATE_BROADCAST_TRIGGER"
  public static DUPLICATE_BROADCAST_ERROR = "DUPLICATE_BROADCAST_ERROR"
  public static FETCH_BROADCAST_SUCCESS = "FETCH_BROADCAST_SUCCESS"
  public static FETCH_BROADCAST_TRIGGER = "FETCH_BROADCAST_TRIGGER"
  public static FETCH_BROADCAST_ERROR = "FETCH_BROADCAST_ERROR"
  public static FETCH_BROADCASTS_SUCCESS = "FETCH_BROADCASTS_SUCCESS"
  public static FETCH_BROADCASTS_TRIGGER = "FETCH_BROADCASTS_TRIGGER"
  public static FETCH_BROADCASTS_ERROR = "FETCH_BROADCASTS_ERROR"
  public static FETCH_BROADCAST_PROGRAMS_RECIPIENTS_TRIGGER = "FETCH_BROADCAST_PROGRAMS_RECIPIENTS_TRIGGER"
  public static FETCH_BROADCAST_PROGRAMS_RECIPIENTS_SUCCESS = "FETCH_BROADCAST_PROGRAMS_RECIPIENTS_SUCCESS"
  public static FETCH_BROADCAST_PROGRAMS_RECIPIENTS_ERROR = "FETCH_BROADCAST_PROGRAMS_RECIPIENTS_ERROR"
  public static UPLOAD_AUDIO_BLOB_SUCCESS = "UPLOAD_AUDIO_BLOB_SUCCESS"
  public static UPLOAD_AUDIO_BLOB_TRIGGER = "UPLOAD_AUDIO_BLOB_TRIGGER"
  public static UPLOAD_AUDIO_BLOB_ERROR = "UPLOAD_AUDIO_BLOB_ERROR"
  public static DELETE_AUDIO_SUCCESS = "DELETE_AUDIO_SUCCESS"
  public static DELETE_AUDIO_TRIGGER = "DELETE_AUDIO_TRIGGER"
  public static DELETE_AUDIO_ERROR = "DELETE_AUDIO_ERROR"

  public static createBroadcast: CreateBroadcast = (payload, action) => async (dispatch) => {
    dispatch({
      type: Actions.CREATE_BROADCAST_TRIGGER,
    })
    const content = isNotNullOrEmpty((payload.content ?? "") as string) ? payload.content : null
    const newBroadcast = {
      ...payload,
      authorId: AuthHelper.getUserAuth().userId!,
      content,
    } as BroadcastDto
    try {
      const { data }: AxiosResponse = await API.post("/v2/broadcasts", newBroadcast, { headers: AuthHelper.getAdminHeaders() })

      dispatch({
        payload: {
          data: data.data,
          action,
        },
        type: Actions.CREATE_BROADCAST_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: {
          data: newBroadcast,
          message: response?.data?.error || UNKNOWN_ERROR,
          action,
        },
        type: Actions.CREATE_BROADCAST_ERROR,
      })
    }
  }

  public static editBroadcast: EditBroadcast = (id, payload, action) => async (dispatch) => {
    dispatch({
      type: Actions.EDIT_BROADCAST_TRIGGER,
    })
    const content = isNotNullOrEmpty((payload.content ?? "") as string) ? payload.content : null
    const editBroadcast = {
      ...payload,
      id,
      authorId: AuthHelper.getUserAuth().userId,
      content,
    } as BroadcastDto
    try {
      const { data }: AxiosResponse = await API.put(`/v2/broadcasts/${id}`, editBroadcast, { headers: AuthHelper.getAdminHeaders() })

      dispatch({
        payload: {
          data: data.data,
          action,
        },
        type: Actions.EDIT_BROADCAST_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: {
          data: editBroadcast,
          message: response?.data?.error || UNKNOWN_ERROR,
          action,
        },
        type: Actions.EDIT_BROADCAST_ERROR,
      })
    }
  }

  public static deleteBroadcast: DeleteBroadcast = (id) => async (dispatch) => {
    dispatch({
      type: Actions.DELETE_BROADCAST_TRIGGER,
    })
    try {
      const { data }: AxiosResponse = await API.delete(`/v2/broadcasts/${id}`, { headers: AuthHelper.getAdminHeaders() })

      dispatch({
        payload: data.data,
        type: Actions.DELETE_BROADCAST_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: {
          message: response?.data?.error || UNKNOWN_ERROR,
        },
        type: Actions.DELETE_BROADCAST_ERROR,
      })
    }
  }

  public static archiveBroadcast: ArchiveBroadcast = (id) => async (dispatch) => {
    dispatch({
      type: Actions.ARCHIVE_BROADCAST_TRIGGER,
    })
    try {
      const { data }: AxiosResponse = await API.delete(`/v2/broadcasts/archive/${id}`, { headers: AuthHelper.getAdminHeaders() })

      dispatch({
        payload: data.data,
        type: Actions.ARCHIVE_BROADCAST_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: {
          message: response?.data?.error || UNKNOWN_ERROR,
        },
        type: Actions.ARCHIVE_BROADCAST_ERROR,
      })
    }
  }

  public static duplicateBroadcast: DuplicateBroadcast = (broadcast, callback) => async (dispatch) => {
    dispatch({
      type: Actions.DUPLICATE_BROADCAST_TRIGGER,
    })
    try {
      const { id } = broadcast
      const {
        data: { data: newBroadcast },
      }: AxiosResponse = await API.post(
        `/v2/broadcasts/${id}/duplicate`,
        {},
        {
          headers: AuthHelper.getAdminHeaders(),
        },
      )
      dispatch({
        payload: newBroadcast,
        type: Actions.DUPLICATE_BROADCAST_SUCCESS,
      })
      callback(newBroadcast.id)
    } catch (err) {
      const { response }: any = err
      console.error(err)
      dispatch({
        payload: {
          message: response?.data?.error || UNKNOWN_ERROR,
        },
        type: Actions.DUPLICATE_BROADCAST_ERROR,
      })
    }
  }

  public static fetchBroadcast: FetchBroadcast = (id) => async (dispatch) => {
    dispatch({
      type: Actions.FETCH_BROADCAST_TRIGGER,
    })
    try {
      const { data }: AxiosResponse = await API.get(`/v2/broadcasts/${id}`, {
        headers: AuthHelper.getAdminHeaders(),
      })
      dispatch({
        payload: data.data,
        type: Actions.FETCH_BROADCAST_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: response?.data?.error || UNKNOWN_ERROR,
        type: Actions.FETCH_BROADCAST_ERROR,
      })
    }
  }

  public static fetchBroadcasts: FetchBroadcasts = (filter) => async (dispatch) => {
    dispatch({
      type: Actions.FETCH_BROADCASTS_TRIGGER,
    })
    const { searchBy, programId, organizationId, page, sortBy, sortDirection, type, status } = filter
    try {
      const { data }: AxiosResponse = await API.get("/v2/broadcasts", {
        params: {
          page,
          sortBy,
          sortDirection,
          searchBy,
          programId,
          organizationId,
          type,
          status,
        },
        headers: AuthHelper.getAdminHeaders(),
      })
      dispatch({
        payload: data.data,
        type: Actions.FETCH_BROADCASTS_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: response?.data?.error || UNKNOWN_ERROR,
        type: Actions.FETCH_BROADCASTS_ERROR,
      })
    }
  }

  public static clearError: ClearError = () => (dispatch) => {
    dispatch({
      type: Actions.CLEAR_ERROR,
    })
  }

  public static clearBroadcast: ClearBroadcast = () => (dispatch) => {
    dispatch({
      type: Actions.CLEAR_BROADCAST_TRIGGER,
    })
  }

  public static clearBroadcastMessages: ClearBroadcast = () => (dispatch) => {
    dispatch({
      type: Actions.CLEAR_BROADCAST_MESSAGES_TRIGGER,
    })
  }

  public static uploadAudioBlob: UploadAudioBlob = (blob, audio) => async (dispatch) => {
    dispatch({
      type: Actions.UPLOAD_AUDIO_BLOB_TRIGGER,
    })
    try {
      if (audio) {
        await API.post(`/v2/broadcasts/audios/remove`, audio, {
          headers: {
            ...AuthHelper.getAdminHeaders(),
          },
        })
      }
      const formData = new FormData()
      formData.append("audio", blob, "audio.mp3")
      const { data }: AxiosResponse = await API.post("/v2/broadcasts/upload", formData, {
        headers: {
          ...AuthHelper.getAdminHeaders(),
          "Content-Type": "multipart/form-data",
        },
      })
      dispatch({
        payload: {
          ...data.data,
        },
        type: Actions.UPLOAD_AUDIO_BLOB_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: {
          message: response?.data?.error || UNKNOWN_ERROR,
        },
        type: Actions.UPLOAD_AUDIO_BLOB_ERROR,
      })
      return false
    }
  }

  public static deleteAudio: DeleteAudio = (audio) => async (dispatch) => {
    dispatch({
      type: Actions.DELETE_AUDIO_TRIGGER,
    })
    try {
      await API.post(`/v2/broadcasts/audios/remove`, audio, {
        headers: {
          ...AuthHelper.getAdminHeaders(),
        },
      })
      dispatch({
        type: Actions.DELETE_AUDIO_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: {
          message: response?.data?.error || UNKNOWN_ERROR,
        },
        type: Actions.DELETE_AUDIO_ERROR,
      })
      return false
    }
  }

  public static fetchBroadcastProgramsRecipients: FetchBroadcastProgramsRecipients =
    (organizationId, contactType, includeStatus = false) =>
    async (dispatch) => {
      let res: AxiosResponse

      dispatch({
        type: Actions.FETCH_BROADCAST_PROGRAMS_RECIPIENTS_SUCCESS,
      })

      try {
        res = await API.get("/v2/broadcasts/programsrecipients", {
          headers: AuthHelper.getAdminHeaders(),
          params: {
            organizationId,
            contactType,
            includeStatus,
          },
        })
      } catch (err) {
        const { response }: any = err
        console.error(response)
        dispatch({
          payload: response?.data?.error || UNKNOWN_ERROR,
          type: Actions.FETCH_BROADCAST_PROGRAMS_RECIPIENTS_ERROR,
        })

        return
      }

      dispatch({
        payload: res.data.data.rows,
        type: Actions.FETCH_BROADCAST_PROGRAMS_RECIPIENTS_SUCCESS,
      })
    }
}

export default Actions
