import ActionType from "../../types/ActionType"
import { NotePerson, NotePersonItem } from "../../types/NotePersonType"
import NotesPersonActions from "../actions/notesPerson"

export enum CreateNotePersonStatus {
  PRISTINE = 0,
  SUBMITTING,
  SUCCESS,
  ERROR,
}

export interface NotesPersonStore {
  createNotePersonStatus: CreateNotePersonStatus
  errorMessage: string
  loadingCreateNotePerson: boolean
  loadingNotesPerson: boolean
  loadingNotesPersonList: boolean
  notePerson: NotePerson | null
  notesPerson: NotePersonItem[]
  notesPersonList: NotePersonItem[]
  totalPagesNotesPerson: number
  downloadedNotes: Blob | undefined
}

const INITIAL_STATE: NotesPersonStore = {
  createNotePersonStatus: CreateNotePersonStatus.PRISTINE,
  errorMessage: "",
  loadingCreateNotePerson: true,
  loadingNotesPerson: true,
  loadingNotesPersonList: false,
  notePerson: null,
  notesPerson: [],
  notesPersonList: [],
  totalPagesNotesPerson: 1,
  downloadedNotes: undefined,
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case NotesPersonActions.FETCH_NOTES_PERSON_TRIGGER: {
      return {
        ...state,
        loadingNotesPerson: true,
      }
    }

    case NotesPersonActions.FETCH_NOTES_PERSON_SUCCESS: {
      return {
        ...state,
        loadingNotesPerson: false,
        totalPagesNotesPerson: action.payload.pager?.totalPages || 0,
        notesPerson: action.payload.rows,
      }
    }

    case NotesPersonActions.FETCH_NOTES_PERSON_ERROR: {
      return {
        ...state,
        loadingNotesPerson: false,
        errorMessage: action.payload,
      }
    }
    case NotesPersonActions.FETCH_ALL_NOTES_PERSON_TRIGGER: {
      return {
        ...state,
        loadingNotesPersonList: true,
      }
    }

    case NotesPersonActions.FETCH_ALL_NOTES_PERSON_SUCCESS: {
      return {
        ...state,
        loadingNotesPersonList: false,
        totalPagesNotesPerson: action.payload.pager?.totalPages || 0,
        notesPersonList: action.payload.rows,
      }
    }

    case NotesPersonActions.FETCH_ALL_NOTES_PERSON_ERROR: {
      return {
        ...state,
        loadingNotesPersonList: false,
        errorMessage: action.payload,
      }
    }

    case NotesPersonActions.DOWNLOAD_ALL_NOTES_PERSON_TRIGGER: {
      return {
        ...state,
      }
    }

    case NotesPersonActions.DOWNLOAD_ALL_NOTES_PERSON_SUCCESS: {
      return {
        ...state,
        downloadedNotes: action.payload,
      }
    }

    case NotesPersonActions.DOWNLOAD_ALL_NOTES_PERSON_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
      }
    }

    case NotesPersonActions.CLEAR_DOWNLOAD_ALL_NOTES_PERSON: {
      return {
        ...state,
        downloadedNotes: undefined,
      }
    }

    case NotesPersonActions.CREATE_NOTES_PERSON_TRIGGER: {
      return {
        ...state,
        createNotePersonStatus: CreateNotePersonStatus.SUBMITTING,
        loadingCreateNotePerson: true,
      }
    }

    case NotesPersonActions.CREATE_NOTES_PERSON_SUCCESS: {
      return {
        ...state,
        createNotePersonStatus: CreateNotePersonStatus.SUCCESS,
        loadingCreateNotePerson: false,
        notePerson: action.payload,
      }
    }

    case NotesPersonActions.CREATE_NOTES_PERSON_ERROR: {
      return {
        ...state,
        createNotePersonStatus: CreateNotePersonStatus.ERROR,
        loadingCreateNotePerson: false,
        errorMessage: action.payload,
      }
    }

    case NotesPersonActions.CLEAR_NOTES_PERSON:
      return INITIAL_STATE

    default:
      return state
  }
}
