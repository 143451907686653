import React from "react"
import { NavLink, useHistory } from "react-router-dom"
import { Button, Card, CardTitle, Container, Row } from "reactstrap"
import { Colxx } from "../components/common/CustomBootstrap"
import { FallbackProps } from "react-error-boundary"
import { History } from "history"
import AuthHelper from "../helpers/AuthHelper"

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const history: History = useHistory()
  console.error("ErrorFallback", error)
  const handleClick = () => {
    if (!AuthHelper.isLoggedIn()) {
      history.replace("/public/login")
      return
    }
    resetErrorBoundary()
  }
  return (
    <React.Fragment>
      <div className='fixed-background' data-testid='not-found-holder' />
      <Container className='container' style={{ marginTop: "10%" }}>
        <Row className='h-100'>
          <Colxx xxs='12' md='10' className='mx-auto my-auto'>
            <Card className='auth-card'>
              <div className='position-relative image-side ' data-testid='not-found-card-holder'>
                <p className='text-white h2' data-testid='not-found-card-title'>
                  CALLHUB
                </p>
                <p className='white mb-0' data-testid='not-found-card-subtitle'>
                  Ooops... looks like an error occurred!
                </p>
              </div>
              <div className='form-side'>
                <NavLink to='/' className='white'>
                  <span className='logo-single' />
                </NavLink>
                <CardTitle className='mb-4' data-testid='not-found-card-error-tittle'>
                  Something went wrong
                </CardTitle>
                <p className='mb-0 text-muted text-small mb-0' data-testid='not-found-card-error-code'>
                  Error code
                </p>
                <p className='display-1 font-weight-bold mb-5' data-testid='not-found-card-error-code-500'>
                  500
                </p>
                <Button color='primary' className='btn-shadow' size='lg' onClick={handleClick} data-testid='not-found-card-error-button-go-back-home'>
                  Go Back
                </Button>
              </div>
            </Card>
          </Colxx>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default {
  Component: ErrorFallback,
  onReset: (useQueryReset: () => void) => (details) => {
    console.info(`Something went wrong: ${details}`)
    console.info(`Resetting the state of your app so the error doesn't happen again`)
    useQueryReset()
  },
}
