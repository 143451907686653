import ActionType from "../../types/ActionType"
import Modals from "../actions/modals"

export enum CreateModalStatus {
  PRISTINE = "PRISTINE",
  TRIGGER = "TRIGGER",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export interface ModalStore {
  createModalStatus: CreateModalStatus
  createModalErrorMessage: string
}

const INITIAL_STATE: ModalStore = {
  createModalStatus: CreateModalStatus.PRISTINE,
  createModalErrorMessage: "",
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case Modals.CREATE_MODAL_TRIGGER: {
      return {
        ...state,
        createModalStatus: CreateModalStatus.TRIGGER,
      }
    }

    case Modals.CREATE_MODAL_SUCCESS: {
      return {
        ...state,
        createModalStatus: CreateModalStatus.SUCCESS,
      }
    }

    case Modals.CREATE_MODAL_ERROR: {
      return {
        ...state,
        createModalStatus: CreateModalStatus.ERROR,
        createModalErrorMessage: action.payload,
      }
    }

    case Modals.CREATE_MODAL_CLEAR: {
      return INITIAL_STATE
    }

    default:
      return state
  }
}
