import ActionType from "../../types/ActionType"
import { SurveyQuestionType, SurveyType } from "../../types/SurveyType"
import SurveysActions from "../actions/surveys"

export interface SurveysStore {
  surveys: SurveyType[]
  errorMessage: string
  loadingSurveys: boolean
  surveysQuestions: SurveyQuestionType[]
  loadingSurveysQuestions: boolean
}

const INITIAL_STATE: SurveysStore = {
  surveys: [],
  surveysQuestions: [],
  errorMessage: "",
  loadingSurveys: false,
  loadingSurveysQuestions: false,
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case SurveysActions.FETCH_SURVEYS_TRIGGER: {
      return {
        ...state,
        loadingSurveys: true,
      }
    }

    case SurveysActions.FETCH_SURVEYS_SUCCESS: {
      return {
        ...state,
        surveys: action.payload,
        loadingSurveys: false,
      }
    }

    case SurveysActions.FETCH_SURVEYS_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        loadingSurveys: false,
      }
    }

    case SurveysActions.FETCH_SURVEYS_QUESTIONS_TRIGGER: {
      return {
        ...state,
        loadingSurveysQuestions: true,
      }
    }

    case SurveysActions.FETCH_SURVEYS_QUESTIONS_SUCCESS: {
      return {
        ...state,
        surveysQuestions: action.payload.rows,
        loadingSurveysQuestions: false,
      }
    }

    case SurveysActions.FETCH_SURVEYS_QUESTIONS_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        loadingSurveysQuestions: false,
      }
    }

    case SurveysActions.FETCH_SURVEYS_QUESTIONS_BY_SURVEY_TYPE_TRIGGER: {
      return {
        ...state,
        loadingSurveysQuestions: true,
      }
    }

    case SurveysActions.FETCH_SURVEYS_QUESTIONS_BY_SURVEY_TYPE_SUCCESS: {
      return {
        ...state,
        surveysQuestions: action.payload.rows,
        loadingSurveysQuestions: false,
      }
    }

    case SurveysActions.FETCH_SURVEYS_QUESTIONS_BY_SURVEY_TYPE_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        loadingSurveysQuestions: false,
      }
    }

    default: {
      return state
    }
  }
}
