import { RoleType } from "../../constants/roles"
import { getPersonOrganizationsToEdit } from "../../helpers/ParserHelper"
import ActionType from "../../types/ActionType"
import { EditPersonOrganizations, ExportedPersonOrganizations, NamedProfile, PersonOrganization, PersonOrganizationProfile } from "../../types/PersonType"
import { CompleteSurveyType } from "../../types/SurveyType"
import OrganizationActions from "../actions/organizations"
import PersonOrganizations from "../actions/personOrganizations"

export enum CreatePersonOrganizationStatus {
  PRISTINE = 0,
  SUBMITTING,
  SUCCESS,
  ERROR,
}

export enum EditPersonOrganizationStatus {
  PRISTINE = 0,
  SUBMITTING,
  SUCCESS,
  ERROR,
}

export enum PersonOrganizationsExportStatus {
  NOT_EXPORTED = 0,
  TRIGGERED,
  SUCCESS,
  ERROR,
}

export enum DeletePersonOrganizationStatus {
  NOT_DELETED = 0,
  TRIGGERED,
  ERROR,
  SUCCESS,
}

export enum UpdateRolePersonOrganizationStatus {
  NOT_UPDATE = 0,
  TRIGGERED,
  ERROR,
  SUCCESS,
}

export enum RemoveMatchStatus {
  NOT_REMOVED = 0,
  TRIGGERED,
  ERROR,
  SUCCESS,
}

export interface PersonOrganizationsStore {
  createPersonOrganizationStatus: CreatePersonOrganizationStatus
  createPersonOrganizationAdminStatus: CreatePersonOrganizationStatus
  deletePersonOrganizationStatus: DeletePersonOrganizationStatus
  updateRolePersonOrganizationStatus: UpdateRolePersonOrganizationStatus
  updateRolePersonOrganizationStatusErrorMessage: string | null
  editPersonOrganizationStatus: EditPersonOrganizationStatus
  errorMessage: string
  errorMessageDelete: string
  errorMessageEdit: string
  errorMessageList: string
  loadingOrganizationPersonOrganizationRolePrograms: boolean
  loadingPersonOrganization: boolean
  loadingPersonOrganizations: boolean
  matches: PersonOrganization[]
  profiles: PersonOrganizationProfile[] | null
  personOrganizations: PersonOrganization[]
  personOrganization: PersonOrganization | null
  personOrganizationToUpdate: EditPersonOrganizations | null
  personOrganizationsExportStatus: PersonOrganizationsExportStatus
  personOrganizationsToExport: ExportedPersonOrganizations | null
  successEditMessage: string
  removeMatchStatus: RemoveMatchStatus
  totalPages: number
  totalPagesPersonOrganizationsByOrg: number
  totalFilteredInactiveByOrg: number
  totalFilteredOutreachesByOrg: number
  totalFilteredRecipientsByOrg: number
  totalFilteredCallersByOrg: number
  usernameAlreadyExistsErrorMsg: string
  loadingCompletePersonOrganizationSurvey: boolean
  completePersonOrganizationSurveyErrorMessage: string | null
  completePersonOrganizationSurvey: CompleteSurveyType | null
  loadingPersonOrganizationSurveyToOpen: boolean
  personOrganizationSurveyToOpen: CompleteSurveyType | null
  personOrganizationSurveyToOpenErrorMessage: string | null
}

const INITIAL_STATE: PersonOrganizationsStore = {
  createPersonOrganizationStatus: CreatePersonOrganizationStatus.PRISTINE,
  createPersonOrganizationAdminStatus: CreatePersonOrganizationStatus.PRISTINE,
  deletePersonOrganizationStatus: DeletePersonOrganizationStatus.NOT_DELETED,
  updateRolePersonOrganizationStatus: UpdateRolePersonOrganizationStatus.NOT_UPDATE,
  updateRolePersonOrganizationStatusErrorMessage: "",
  editPersonOrganizationStatus: EditPersonOrganizationStatus.PRISTINE,
  errorMessage: "",
  errorMessageDelete: "",
  errorMessageEdit: "",
  errorMessageList: "",
  loadingOrganizationPersonOrganizationRolePrograms: true,
  loadingPersonOrganization: true,
  loadingPersonOrganizations: false,
  matches: [],
  personOrganizations: [],
  profiles: null,
  personOrganization: null,
  personOrganizationToUpdate: null,
  personOrganizationsExportStatus: PersonOrganizationsExportStatus.NOT_EXPORTED,
  personOrganizationsToExport: null,
  successEditMessage: "",
  removeMatchStatus: RemoveMatchStatus.NOT_REMOVED,
  totalPages: 1,
  totalPagesPersonOrganizationsByOrg: 1,
  totalFilteredInactiveByOrg: 0,
  totalFilteredOutreachesByOrg: 0,
  totalFilteredRecipientsByOrg: 0,
  totalFilteredCallersByOrg: 0,
  usernameAlreadyExistsErrorMsg: "",
  loadingCompletePersonOrganizationSurvey: false,
  completePersonOrganizationSurvey: null,
  completePersonOrganizationSurveyErrorMessage: null,
  loadingPersonOrganizationSurveyToOpen: false,
  personOrganizationSurveyToOpen: null,
  personOrganizationSurveyToOpenErrorMessage: null,
}

export default (state = INITIAL_STATE, action: ActionType): PersonOrganizationsStore => {
  switch (action.type) {
    case PersonOrganizations.EDIT_PERSON_ORGANIZATIONS_TRIGGER:
      return {
        ...state,
        editPersonOrganizationStatus: EditPersonOrganizationStatus.SUBMITTING,
      }

    case PersonOrganizations.EDIT_PERSON_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        editPersonOrganizationStatus: EditPersonOrganizationStatus.SUCCESS,
        successEditMessage: "person.edit-success-message",
      }

    case PersonOrganizations.EDIT_PERSON_ORGANIZATIONS_ERROR:
      return {
        ...state,
        editPersonOrganizationStatus: EditPersonOrganizationStatus.ERROR,
        errorMessageEdit: action.payload || "person.edit-error-message",
      }

    case PersonOrganizations.FETCH_PERSON_ORGANIZATIONS_TRIGGER:
      return {
        ...state,
        loadingPersonOrganizations: true,
      }

    case PersonOrganizations.FETCH_PERSON_ORGANIZATIONS_ERROR:
      return {
        ...state,
        errorMessageList: action.payload,
        loadingPersonOrganizations: false,
      }

    case PersonOrganizations.FETCH_PERSON_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loadingPersonOrganizations: false,
        personOrganizations: action.payload.personOrganizations,
        totalPages: action.payload.pager.totalPages,
      }

    case PersonOrganizations.FETCH_ORGANIZATION_PERSON_ORGANIZATIONS_TRIGGER:
      return {
        ...state,
        loadingPersonOrganizations: true,
      }

    case PersonOrganizations.FETCH_ORGANIZATION_PERSON_ORGANIZATIONS_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        loadingPersonOrganizations: false,
      }

    case PersonOrganizations.FETCH_ORGANIZATION_PERSON_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loadingPersonOrganizations: false,
        totalPagesPersonOrganizationsByOrg: action.payload.pager.totalPages,
        personOrganizations: action.payload.personOrganizations,
        totalFilteredInactiveByOrg:
          (action.payload.personOrganizations ?? []).filter((x: PersonOrganization) => {
            return x.active
          }) ?? [],
        ...{
          ...(action.payload.role === RoleType.OUTREACH ? { totalFilteredOutreachesByOrg: action.payload.role === RoleType.OUTREACH ? action.payload.pager.totalItems : 0 } : {}),
          ...(action.payload.role === RoleType.RECIPIENT ? { totalFilteredRecipientsByOrg: action.payload.role === RoleType.RECIPIENT ? action.payload.pager.totalItems : 0 } : {}),
          ...(action.payload.role === RoleType.CALLER ? { totalFilteredCallersByOrg: action.payload.role === RoleType.CALLER ? action.payload.pager.totalItems : 0 } : {}),
        },
      }

    case OrganizationActions.FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        totalFilteredInactiveByOrg: action.payload.totalInactive ?? 0,
        totalFilteredOutreachesByOrg: action.payload.totalOutreachs ?? 0,
        totalFilteredRecipientsByOrg: action.payload.totalRecipients,
        totalFilteredCallersByOrg: action.payload.totalCallers,
      }

    case PersonOrganizations.FETCH_PERSON_ORGANIZATION_TRIGGER:
      return {
        ...state,
        loadingPersonOrganization: true,
      }

    case PersonOrganizations.FETCH_PERSON_ORGANIZATION_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        loadingPersonOrganization: false,
      }

    case PersonOrganizations.FETCH_PERSON_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loadingPersonOrganization: false,
        matches: action.payload.matches,
        profiles: action.payload.profiles,
        personOrganization: action.payload.personOrganization,
        personOrganizationToUpdate: getPersonOrganizationsToEdit(action.payload.personOrganization),
      }

    case PersonOrganizations.EXPORT_PERSON_ORGANIZATIONS_TRIGGER:
      return {
        ...state,
        personOrganizationsToExport: null,
        personOrganizationsExportStatus: PersonOrganizationsExportStatus.TRIGGERED,
      }

    case PersonOrganizations.EXPORT_PERSON_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        personOrganizationsToExport: action.payload,
        personOrganizationsExportStatus: PersonOrganizationsExportStatus.SUCCESS,
      }

    case PersonOrganizations.EXPORT_PERSON_ORGANIZATIONS_ERROR:
      return {
        ...state,
        personOrganizationsToExport: null,
        personOrganizationsExportStatus: PersonOrganizationsExportStatus.ERROR,
      }

    case PersonOrganizations.COMPLETE_PERSON_ORGANIZATION_SURVEY_TRIGGER:
      return {
        ...state,
        loadingCompletePersonOrganizationSurvey: true,
      }

    case PersonOrganizations.COMPLETE_PERSON_ORGANIZATION_SURVEY_SUCCESS:
      return {
        ...state,
        loadingCompletePersonOrganizationSurvey: false,
        completePersonOrganizationSurvey: action.payload,
      }

    case PersonOrganizations.COMPLETE_PERSON_ORGANIZATION_SURVEY_ERROR:
      return {
        ...state,
        loadingCompletePersonOrganizationSurvey: false,
        completePersonOrganizationSurveyErrorMessage: action.payload,
      }

    case PersonOrganizations.OPEN_PERSON_ORGANIZATION_SURVEY_TRIGGER:
      return {
        ...state,
        loadingPersonOrganizationSurveyToOpen: true,
      }

    case PersonOrganizations.OPEN_PERSON_ORGANIZATION_SURVEY_SUCCESS:
      return {
        ...state,
        loadingPersonOrganizationSurveyToOpen: false,
        personOrganizationSurveyToOpen: action.payload,
        completePersonOrganizationSurvey: null,
      }

    case PersonOrganizations.OPEN_PERSON_ORGANIZATION_SURVEY_ERROR:
      return {
        ...state,
        personOrganizationSurveyToOpenErrorMessage: action.payload,
      }

    case PersonOrganizations.EXPORT_PERSON_ORGANIZATIONS_CLEAR:
      return {
        ...state,
        personOrganizationsToExport: null,
        personOrganizationsExportStatus: PersonOrganizationsExportStatus.NOT_EXPORTED,
      }

    case PersonOrganizations.CREATE_ORGANIZATION_PERSON_ORGANIZATIONS_TRIGGER: {
      return {
        ...state,
        createPersonOrganizationStatus: CreatePersonOrganizationStatus.SUBMITTING,
      }
    }

    case PersonOrganizations.CREATE_ORGANIZATION_PERSON_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        createPersonOrganizationStatus: CreatePersonOrganizationStatus.SUCCESS,
      }
    }

    case PersonOrganizations.CREATE_ORGANIZATION_PERSON_ORGANIZATIONS_ERROR: {
      return {
        ...state,
        createPersonOrganizationStatus: CreatePersonOrganizationStatus.ERROR,
      }
    }

    case PersonOrganizations.CREATE_ORGANIZATION_PERSON_ADMIN_SUCCESS: {
      return {
        ...state,
        createPersonOrganizationAdminStatus: CreatePersonOrganizationStatus.SUCCESS,
      }
    }

    case PersonOrganizations.CREATE_ORGANIZATION_PERSON_ADMIN_ERROR: {
      return {
        ...state,
        createPersonOrganizationAdminStatus: CreatePersonOrganizationStatus.ERROR,
      }
    }

    case PersonOrganizations.DELETE_PERSON_ORGANIZATION_TRIGGER:
      return {
        ...state,
        errorMessageDelete: "",
        deletePersonOrganizationStatus: DeletePersonOrganizationStatus.TRIGGERED,
      }

    case PersonOrganizations.DELETE_PERSON_ORGANIZATION_ERROR: {
      return {
        ...state,
        errorMessageDelete: action.payload,
        deletePersonOrganizationStatus: DeletePersonOrganizationStatus.ERROR,
      }
    }

    case PersonOrganizations.DELETE_PERSON_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        errorMessageDelete: "",
        deletePersonOrganizationStatus: DeletePersonOrganizationStatus.SUCCESS,
      }
    }

    case PersonOrganizations.UPDATE_ROLE_PERSON_ORGANIZATION_TRIGGER:
      return {
        ...state,
        updateRolePersonOrganizationStatus: UpdateRolePersonOrganizationStatus.TRIGGERED,
      }

    case PersonOrganizations.UPDATE_ROLE_PERSON_ORGANIZATION_ERROR: {
      return {
        ...state,
        updateRolePersonOrganizationStatus: UpdateRolePersonOrganizationStatus.ERROR,
      }
    }

    case PersonOrganizations.UPDATE_ROLE_PERSON_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        updateRolePersonOrganizationStatus: UpdateRolePersonOrganizationStatus.SUCCESS,
      }
    }

    case PersonOrganizations.UPDATE_RECIPIENT_TO_ONBOARDING_PERSON_ORGANIZATION_TRIGGER:
      return {
        ...state,
        updateRolePersonOrganizationStatusErrorMessage: "",
        updateRolePersonOrganizationStatus: UpdateRolePersonOrganizationStatus.TRIGGERED,
      }

    case PersonOrganizations.UPDATE_RECIPIENT_TO_ONBOARDING_PERSON_ORGANIZATION_ERROR: {
      return {
        ...state,
        updateRolePersonOrganizationStatusErrorMessage: action.payload,
        updateRolePersonOrganizationStatus: UpdateRolePersonOrganizationStatus.ERROR,
      }
    }

    case PersonOrganizations.UPDATE_RECIPIENT_TO_ONBOARDING_PERSON_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        updateRolePersonOrganizationStatusErrorMessage: "",
        updateRolePersonOrganizationStatus: UpdateRolePersonOrganizationStatus.SUCCESS,
      }
    }

    case PersonOrganizations.REMOVE_MATCH_PERSON_ORGANIZATION_TRIGGER:
      return {
        ...state,
        loadingPersonOrganization: true,
        removeMatchStatus: RemoveMatchStatus.TRIGGERED,
      }

    case PersonOrganizations.REMOVE_MATCH_PERSON_ORGANIZATION_ERROR: {
      return {
        ...state,
        loadingPersonOrganization: false,
        removeMatchStatus: RemoveMatchStatus.ERROR,
      }
    }

    case PersonOrganizations.REMOVE_MATCH_PERSON_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        loadingPersonOrganization: false,
        removeMatchStatus: RemoveMatchStatus.SUCCESS,
      }
    }

    case PersonOrganizations.USER_ALREADY_EXISTS: {
      return {
        ...state,
        usernameAlreadyExistsErrorMsg: action.payload.message,
        editPersonOrganizationStatus: EditPersonOrganizationStatus.PRISTINE,
      }
    }

    case PersonOrganizations.USER_ALREADY_EXISTS_CLEAR: {
      return {
        ...state,
        usernameAlreadyExistsErrorMsg: "",
        editPersonOrganizationStatus: EditPersonOrganizationStatus.PRISTINE,
      }
    }

    case PersonOrganizations.CLEAR_PERSON_ORGANIZATIONS_EDIT_STATUS: {
      return {
        ...state,
        editPersonOrganizationStatus: EditPersonOrganizationStatus.PRISTINE,
      }
    }

    case PersonOrganizations.CLEAR_PERSON_ORGANIZATIONS:
      return INITIAL_STATE

    default:
      return state
  }
}
