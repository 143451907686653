import ActionType from "../../types/ActionType"
import { CreateProgramStatus, Program, ProgramRecipientDto } from "../../types/ProgramsType"
import ProgramsActions from "../actions/programs"

export interface ProgramsStore {
  createProgramStatus: CreateProgramStatus
  errorMessage: string
  loadingCreateProgram: boolean
  loadingDeleteProgram: boolean
  loadingSetDefaultProgram: boolean
  loadingProgramsRecipients: boolean
  loadingPrograms: boolean
  program: Program[]
  programs: Program[]
  programsRecipients: ProgramRecipientDto[]
}

const INITIAL_STATE: ProgramsStore = {
  createProgramStatus: CreateProgramStatus.PRISTINE,
  errorMessage: "",
  loadingCreateProgram: false,
  loadingDeleteProgram: false,
  loadingSetDefaultProgram: false,
  loadingProgramsRecipients: false,
  loadingPrograms: false,
  program: [],
  programs: [],
  programsRecipients: [],
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case ProgramsActions.FETCH_PROGRAMS_TRIGGER: {
      return {
        ...state,
        loadingPrograms: true,
      }
    }

    case ProgramsActions.FETCH_PROGRAMS_SUCCESS: {
      return {
        ...state,
        loadingPrograms: false,
        programs: action.payload,
      }
    }

    case ProgramsActions.FETCH_PROGRAMS_ERROR: {
      return {
        ...state,
        loadingPrograms: false,
        errorMessage: action.payload,
      }
    }

    case ProgramsActions.FETCH_PROGRAMS_RECIPIENTS_ERROR: {
      return {
        ...state,
        loadingProgramsRecipients: false,
        errorMessage: action.payload,
      }
    }

    case ProgramsActions.FETCH_PROGRAMS_RECIPIENTS_TRIGGER: {
      return {
        ...state,
        loadingProgramsRecipients: true,
      }
    }

    case ProgramsActions.FETCH_PROGRAMS_RECIPIENTS_SUCCESS: {
      return {
        ...state,
        loadingProgramsRecipients: false,
        programsRecipients: action.payload,
      }
    }

    case ProgramsActions.CREATE_PROGRAM_TRIGGER: {
      return {
        ...state,
        createProgramStatus: CreateProgramStatus.SUBMITTING,
        loadingCreateProgram: true,
      }
    }

    case ProgramsActions.CREATE_PROGRAM_SUCCESS: {
      return {
        ...state,
        createProgramStatus: CreateProgramStatus.SUCCESS,
        loadingCreateProgram: false,
        program: action.payload,
      }
    }

    case ProgramsActions.CREATE_PROGRAM_ERROR: {
      return {
        ...state,
        createProgramStatus: CreateProgramStatus.ERROR,
        loadingCreateProgram: false,
        errorMessage: action.payload,
      }
    }

    case ProgramsActions.DELETE_PROGRAM_TRIGGER: {
      return {
        ...state,
        loadingDeleteProgram: true,
      }
    }

    case ProgramsActions.DELETE_PROGRAM_SUCCESS: {
      return {
        ...state,
        loadingDeleteProgram: false,
        programs: state.programs.filter((x) => x.id !== action.payload),
      }
    }

    case ProgramsActions.DELETE_PROGRAM_ERROR: {
      return {
        ...state,
        createProgramStatus: CreateProgramStatus.ERROR,
        loadingDeleteProgram: false,
        errorMessage: action.payload,
      }
    }

    case ProgramsActions.SET_DEFAULT_PROGRAM_TRIGGER: {
      return {
        ...state,
        loadingSetDefaultProgram: true,
      }
    }

    case ProgramsActions.SET_DEFAULT_PROGRAM_SUCCESS: {
      return {
        ...state,
        loadingSetDefaultProgram: false,
        programs: action.payload,
      }
    }

    case ProgramsActions.SET_DEFAULT_PROGRAM_ERROR: {
      return {
        ...state,
        loadingSetDefaultProgram: false,
        errorMessage: action.payload,
      }
    }

    case ProgramsActions.CLEAR_PROGRAMS:
      return INITIAL_STATE

    default:
      return state
  }
}
