import dayjs from "dayjs"
import { Dispatch } from "redux"
import { AxiosResponse } from "axios"
import API from "../../Api"
import AuthHelper from "../../helpers/AuthHelper"
import { UNKNOWN_ERROR } from "../../constants/messages"
import { RoleType } from "../../constants/roles"
import { pick } from "lodash"
import { ProgramRecipientContactType, ProgramRecipientDto } from "../../types/ProgramsType"
import { OutreachQueueDTO } from "../../views/app/outreachQueues/types"
import Modals from "./modals"

interface ProgramsRecipientsCache {
  [key: string]: {
    timestamp: number
    data: ProgramRecipientDto[]
  }
}
const programsRecipientsCache: ProgramsRecipientsCache = {}

export interface OutreachQueueFilter {
  page: number
  organizationId: string | null
}

export interface AddPersonToOutreachQueue {
  outreachQueueId: string
  programRecipient: ProgramRecipientDto[]
  type: RoleType
}
export interface RemovePersonToOutreachQueue {
  outreachQueueId: string
  outreachQueuePersonId: string
  type: RoleType
}

export interface UpdateRecipient {
  outreachQueueId: string
  outreachQueuePersonId: string
  priorityOrder: number | null
  nextAvailability: string | null
}

export interface UpdatePriorityOrder {
  outreachQueueId: string
  outreachQueuePersonId: string
  priorityOrder: number
}

export interface PauseResumeRecipient {
  outreachQueueId: string
  outreachQueuePersonId: string
}

export interface OutreachQueueActionsInterface {
  createOutreachQueue: (payload: OutreachQueueDTO) => (dispatch: Dispatch) => void
  editOutreachQueue: (id: string, payload: OutreachQueueDTO) => (dispatch: Dispatch) => void
  deleteOutreachQueue: (id: string) => (dispatch: Dispatch) => void
  fetchOutreachQueue: (id: string) => (dispatch: Dispatch) => void
  fetchOutreachQueues: () => (dispatch: Dispatch) => void
  fetchOutreachQueuePerson: (id: string, type: RoleType) => (dispatch: Dispatch) => void
  filterOutreachQueues: (filter: OutreachQueueFilter) => (dispatch: Dispatch) => void
  clearOutreachQueueError: () => (dispatch: Dispatch) => void
  clearOutreachQueue: () => (dispatch: Dispatch) => void
  clearOutreachQueues: () => (dispatch: Dispatch) => void
  addPersonToQueue: (person: AddPersonToOutreachQueue) => (dispatch: Dispatch) => void
  removePersonToQueue: (person: RemovePersonToOutreachQueue) => (dispatch: Dispatch) => void
  removePersonFromQueueById: (persone: RemovePersonToOutreachQueue) => (dispatch: Dispatch) => void
  updateRecipient: (person: UpdateRecipient) => (dispatch: Dispatch) => void
  updatePriorityOrder: (person: UpdatePriorityOrder) => (dispatch: Dispatch) => void
  pauseResumeRecipient: (person: PauseResumeRecipient) => (dispatch: Dispatch) => void
  fetchProgramsRecipients: (organizationId: string, contactType?: ProgramRecipientContactType, includeStatus?: boolean) => (dispatch: Dispatch) => void
}

class OutreachQueueActions {
  public static ACTION_TYPES = {
    CLEAR_OUTREACH_QUEUE_TRIGGER: "CLEAR_OUTREACH_QUEUE_TRIGGER",
    CLEAR_OUTREACH_QUEUES_TRIGGER: "CLEAR_OUTREACH_QUEUES_TRIGGER",

    CLEAR_OUTREACH_QUEUE_ERROR_TRIGGER: "CLEAR_OUTREACH_QUEUE_ERROR_TRIGGER",

    CREATE_OUTREACH_QUEUE_TRIGGER: "CREATE_OUTREACH_QUEUE_TRIGGER",
    CREATE_OUTREACH_QUEUE_SUCCESS: "CREATE_OUTREACH_QUEUE_SUCCESS",
    CREATE_OUTREACH_QUEUE_ERROR: "CREATE_OUTREACH_QUEUE_ERROR",

    EDIT_OUTREACH_QUEUE_TRIGGER: "EDIT_OUTREACH_QUEUE_TRIGGER",
    EDIT_OUTREACH_QUEUE_SUCCESS: "EDIT_OUTREACH_QUEUE_SUCCESS",
    EDIT_OUTREACH_QUEUE_ERROR: "EDIT_OUTREACH_QUEUE_ERROR",

    DELETE_OUTREACH_QUEUE_TRIGGER: "DELETE_OUTREACH_QUEUE_TRIGGER",
    DELETE_OUTREACH_QUEUE_SUCCESS: "DELETE_OUTREACH_QUEUE_SUCCESS",
    DELETE_OUTREACH_QUEUE_ERROR: "DELETE_OUTREACH_QUEUE_ERROR",

    ARCHIVE_OUTREACH_QUEUE_TRIGGER: "ARCHIVE_OUTREACH_QUEUE_TRIGGER",
    ARCHIVE_OUTREACH_QUEUE_SUCCESS: "ARCHIVE_OUTREACH_QUEUE_SUCCESS",
    ARCHIVE_OUTREACH_QUEUE_ERROR: "ARCHIVE_OUTREACH_QUEUE_ERROR",

    FETCH_OUTREACH_QUEUE_TRIGGER: "FETCH_OUTREACH_QUEUE_TRIGGER",
    FETCH_OUTREACH_QUEUE_SUCCESS: "FETCH_OUTREACH_QUEUE_SUCCESS",
    FETCH_OUTREACH_QUEUE_ERROR: "FETCH_OUTREACH_QUEUE_ERROR",

    FETCH_OUTREACH_QUEUES_TRIGGER: "FETCH_OUTREACH_QUEUES_TRIGGER",
    FETCH_OUTREACH_QUEUES_SUCCESS: "FETCH_OUTREACH_QUEUES_SUCCESS",
    FETCH_OUTREACH_QUEUES_ERROR: "FETCH_OUTREACH_QUEUES_ERROR",

    FETCH_OUTREACH_QUEUE_PERSON_TRIGGER: "FETCH_OUTREACH_QUEUE_PERSON_TRIGGER",
    FETCH_OUTREACH_QUEUE_PERSON_SUCCESS: "FETCH_OUTREACH_QUEUE_PERSON_SUCCESS",
    FETCH_OUTREACH_QUEUE_PERSON_ERROR: "FETCH_OUTREACH_QUEUE_PERSON_ERROR",

    FILTER_OUTREACH_QUEUES_TRIGGER: "FILTER_OUTREACH_QUEUES_TRIGGER",
    FILTER_OUTREACH_QUEUES_SUCCESS: "FILTER_OUTREACH_QUEUES_SUCCESS",
    FILTER_OUTREACH_QUEUES_ERROR: "FILTER_OUTREACH_QUEUES_ERROR",

    ADD_PERSON_TO_OUTREACH_QUEUES_TRIGGER: "ADD_PERSON_TO_OUTREACH_QUEUES_TRIGGER",
    ADD_PERSON_TO_OUTREACH_QUEUES_SUCCESS: "ADD_PERSON_TO_OUTREACH_QUEUES_SUCCESS",
    ADD_PERSON_TO_OUTREACH_QUEUES_ERROR: "ADD_PERSON_TO_OUTREACH_QUEUES_ERROR",

    REMOVE_PERSON_TO_OUTREACH_QUEUES_TRIGGER: "REMOVE_PERSON_TO_OUTREACH_QUEUES_TRIGGER",
    REMOVE_PERSON_TO_OUTREACH_QUEUES_SUCCESS: "REMOVE_PERSON_TO_OUTREACH_QUEUES_SUCCESS",
    REMOVE_PERSON_TO_OUTREACH_QUEUES_ERROR: "REMOVE_PERSON_TO_OUTREACH_QUEUES_ERROR",

    UPDATE_PRIORITY_ORDER_OUTREACH_QUEUE_TRIGGER: "UPDATE_PRIORITY_ORDER_OUTREACH_QUEUE_TRIGGER",
    UPDATE_PRIORITY_ORDER_OUTREACH_QUEUE_SUCCESS: "UPDATE_PRIORITY_ORDER_OUTREACH_QUEUE_SUCCESS",
    UPDATE_PRIORITY_ORDER_OUTREACH_QUEUE_ERROR: "UPDATE_PRIORITY_ORDER_OUTREACH_QUEUE_ERROR",

    UPDATE_RECIPIENT_OUTREACH_QUEUE_TRIGGER: "UPDATE_RECIPIENT_OUTREACH_QUEUE_TRIGGER",
    UPDATE_RECIPIENT_OUTREACH_QUEUE_SUCCESS: "UPDATE_RECIPIENT_OUTREACH_QUEUE_SUCCESS",
    UPDATE_RECIPIENT_OUTREACH_QUEUE_ERROR: "UPDATE_RECIPIENT_OUTREACH_QUEUE_ERROR",

    PAUSE_RESUME_RECIPIENT_OUTREACH_QUEUE_TRIGGER: "PAUSE_RESUME_RECIPIENT_OUTREACH_QUEUE_TRIGGER",
    PAUSE_RESUME_RECIPIENT_OUTREACH_QUEUE_SUCCESS: "PAUSE_RESUME_RECIPIENT_OUTREACH_QUEUE_SUCCESS",
    PAUSE_RESUME_RECIPIENT_OUTREACH_QUEUE_ERROR: "PAUSE_RESUME_RECIPIENT_OUTREACH_QUEUE_ERROR",

    FETCH_PROGRAMS_RECIPIENTS_TRIGGER: "FETCH_PROGRAMS_RECIPIENTS_TRIGGER",
    FETCH_PROGRAMS_RECIPIENTS_SUCCESS: "FETCH_PROGRAMS_RECIPIENTS_SUCCESS",
    FETCH_PROGRAMS_RECIPIENTS_ERROR: "FETCH_PROGRAMS_RECIPIENTS_ERROR",
  }

  public static clearOutreachQueue = () => (dispatch: Dispatch) => {
    dispatch({
      type: OutreachQueueActions.ACTION_TYPES.CLEAR_OUTREACH_QUEUE_TRIGGER,
    })
  }

  public static clearOutreachQueues = () => (dispatch: Dispatch) => {
    dispatch({
      type: OutreachQueueActions.ACTION_TYPES.CLEAR_OUTREACH_QUEUES_TRIGGER,
    })
  }

  public static clearOutreachQueueError = () => (dispatch: Dispatch) => {
    dispatch({
      type: OutreachQueueActions.ACTION_TYPES.CLEAR_OUTREACH_QUEUE_ERROR_TRIGGER,
    })
  }
  public static createOutreachQueue = (payload: OutreachQueueDTO) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: OutreachQueueActions.ACTION_TYPES.CREATE_OUTREACH_QUEUE_TRIGGER,
      })
      const dto = pick(payload, ["name", "active", "organizationId"])
      const { data }: AxiosResponse = await API.post(
        `/v2/callListQueues`,
        {
          ...dto,
          callers: [],
          recipients: [],
        },
        { headers: AuthHelper.getAdminHeaders() },
      )
      dispatch({
        payload: data.data,
        type: OutreachQueueActions.ACTION_TYPES.CREATE_OUTREACH_QUEUE_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: response?.data?.error || UNKNOWN_ERROR,
        type: OutreachQueueActions.ACTION_TYPES.CREATE_OUTREACH_QUEUE_ERROR,
      })
    }
  }
  public static deleteOutreachQueue = (id: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: OutreachQueueActions.ACTION_TYPES.DELETE_OUTREACH_QUEUE_TRIGGER,
      })
      const { data }: AxiosResponse = await API.delete(`/v2/callListQueues/${id}`, { headers: AuthHelper.getAdminHeaders() })
      dispatch({
        payload: data.data,
        type: OutreachQueueActions.ACTION_TYPES.DELETE_OUTREACH_QUEUE_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: response?.data?.error || UNKNOWN_ERROR,
        type: OutreachQueueActions.ACTION_TYPES.DELETE_OUTREACH_QUEUE_ERROR,
      })
    }
  }
  public static editOutreachQueue = (id: string, payload: OutreachQueueDTO) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: OutreachQueueActions.ACTION_TYPES.EDIT_OUTREACH_QUEUE_TRIGGER,
      })
      const dto = pick(payload, ["name", "active"])
      const { data }: AxiosResponse = await API.put(`/v2/callListQueues/${id}`, dto, { headers: AuthHelper.getAdminHeaders() })
      dispatch({
        payload: data.data,
        type: OutreachQueueActions.ACTION_TYPES.EDIT_OUTREACH_QUEUE_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: response?.data?.error || UNKNOWN_ERROR,
        type: OutreachQueueActions.ACTION_TYPES.EDIT_OUTREACH_QUEUE_ERROR,
      })
    }
  }
  public static fetchOutreachQueue = (id: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUE_TRIGGER,
      })
      const { data }: AxiosResponse = await API.get(`/v2/callListQueues/${id}`, { headers: AuthHelper.getAdminHeaders() })
      dispatch({
        payload: data.data,
        type: OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUE_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: response?.data?.error || UNKNOWN_ERROR,
        type: OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUE_ERROR,
      })
    }
  }
  public static fetchOutreachQueues = () => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUES_TRIGGER,
      })
      const { data }: AxiosResponse = await API.get(`/v2/callListQueues/`, { headers: AuthHelper.getAdminHeaders() })
      dispatch({
        payload: data.data,
        type: OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUES_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: response?.data?.error || UNKNOWN_ERROR,
        type: OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUES_ERROR,
      })
    }
  }
  public static fetchOutreachQueuePerson = (id: string, type: RoleType) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUE_PERSON_TRIGGER,
      })
      const url = type ? `/v2/callListQueues/${id}/${type === RoleType.CALLER ? "callers" : "recipients"}` : `/v2/callListQueues/${id}`
      const {
        data: { data },
      }: AxiosResponse = await API.get(url, { headers: AuthHelper.getAdminHeaders() })
      dispatch({
        payload: {
          data,
          type,
        },
        type: OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUE_PERSON_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: response?.data?.error || UNKNOWN_ERROR,
        type: OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUE_PERSON_ERROR,
      })
    }
  }
  public static filterOutreachQueues =
    ({ organizationId, page }: OutreachQueueFilter) =>
    async (dispatch) => {
      try {
        dispatch({
          type: OutreachQueueActions.ACTION_TYPES.FILTER_OUTREACH_QUEUES_TRIGGER,
        })
        const url = organizationId ? `/v1/organizations/${organizationId}/callListQueues` : "/v2/callListQueues"
        const { data }: AxiosResponse = await API.get(url, {
          params: {
            page,
          },
          headers: AuthHelper.getAdminHeaders(),
        })
        dispatch({
          payload: data.data,
          type: OutreachQueueActions.ACTION_TYPES.FILTER_OUTREACH_QUEUES_SUCCESS,
        })
      } catch (err) {
        const { response }: any = err
        console.error(response)
        dispatch({
          payload: response?.data?.error || UNKNOWN_ERROR,
          type: OutreachQueueActions.ACTION_TYPES.FILTER_OUTREACH_QUEUES_ERROR,
        })
      }
    }
  public static addPersonToQueue =
    ({ outreachQueueId, programRecipient, type }: AddPersonToOutreachQueue) =>
    async (dispatch) => {
      try {
        dispatch({
          type: OutreachQueueActions.ACTION_TYPES.ADD_PERSON_TO_OUTREACH_QUEUES_TRIGGER,
        })

        for (const pr of programRecipient) {
          const url = type === RoleType.CALLER ? `/v2/callListQueues/${outreachQueueId}/callers` : `/v2/callListQueues/${outreachQueueId}/recipients`
          await API.post(
            url,
            {
              ...pr,
              id: pr.id.indexOf("@") > -1 ? pr.id.split("@")[0] : pr.id,
            },
            { headers: AuthHelper.getAdminHeaders() },
          )
        }

        dispatch({
          payload: {
            programRecipient,
            type,
            outreachQueueId,
          },
          type: OutreachQueueActions.ACTION_TYPES.ADD_PERSON_TO_OUTREACH_QUEUES_SUCCESS,
        })

        Modals.createModalSuccess(dispatch)
      } catch (err) {
        const { response }: any = err
        console.error(response)

        dispatch({
          payload: response?.data?.error || UNKNOWN_ERROR,
          type: OutreachQueueActions.ACTION_TYPES.ADD_PERSON_TO_OUTREACH_QUEUES_ERROR,
        })

        Modals.createModalError(dispatch, response?.data?.error || "Oops!... Looks like an error occurred!")
      }
    }
  public static removePersonToQueue =
    ({ outreachQueueId, outreachQueuePersonId, type }: RemovePersonToOutreachQueue) =>
    async (dispatch) => {
      try {
        dispatch({
          type: OutreachQueueActions.ACTION_TYPES.REMOVE_PERSON_TO_OUTREACH_QUEUES_TRIGGER,
        })
        const url = type === RoleType.CALLER ? `/v2/callListQueues/${outreachQueueId}/callers/${outreachQueuePersonId}` : `/v2/callListQueues/${outreachQueueId}/recipients/${outreachQueuePersonId}`
        const { data }: AxiosResponse = await API.delete(url, { headers: AuthHelper.getAdminHeaders() })
        dispatch({
          payload: data.data,
          type: OutreachQueueActions.ACTION_TYPES.REMOVE_PERSON_TO_OUTREACH_QUEUES_SUCCESS,
        })
      } catch (err) {
        const { response }: any = err
        console.error(response)
        dispatch({
          payload: response?.data?.error || UNKNOWN_ERROR,
          type: OutreachQueueActions.ACTION_TYPES.REMOVE_PERSON_TO_OUTREACH_QUEUES_ERROR,
        })
      }
    }
  public static removePersonFromQueueById =
    ({ outreachQueueId, outreachQueuePersonId, type }: RemovePersonToOutreachQueue) =>
    async (dispatch) => {
      try {
        dispatch({
          type: OutreachQueueActions.ACTION_TYPES.REMOVE_PERSON_TO_OUTREACH_QUEUES_TRIGGER,
        })
        const url =
          type === RoleType.CALLER
            ? `/v2/callListQueues/${outreachQueueId}/callers/${outreachQueuePersonId}/remove`
            : `/v2/callListQueues/${outreachQueueId}/recipients/${outreachQueuePersonId}/remove`
        const { data }: AxiosResponse = await API.delete(url, { headers: AuthHelper.getAdminHeaders() })
        dispatch({
          payload: data.data,
          type: OutreachQueueActions.ACTION_TYPES.REMOVE_PERSON_TO_OUTREACH_QUEUES_SUCCESS,
        })
      } catch (err) {
        const { response }: any = err
        console.error(response)
        dispatch({
          payload: response?.data?.error || UNKNOWN_ERROR,
          type: OutreachQueueActions.ACTION_TYPES.REMOVE_PERSON_TO_OUTREACH_QUEUES_ERROR,
        })
      }
    }
  public static updateRecipient =
    ({ outreachQueueId, outreachQueuePersonId, priorityOrder, nextAvailability }: UpdateRecipient) =>
    async (dispatch) => {
      try {
        dispatch({
          type: OutreachQueueActions.ACTION_TYPES.UPDATE_RECIPIENT_OUTREACH_QUEUE_TRIGGER,
        })
        const url = `/v2/callListQueues/${outreachQueueId}/recipients/${outreachQueuePersonId}`
        const { data }: AxiosResponse = await API.put(
          url,
          {
            priorityOrder,
            nextAvailability,
          },
          { headers: AuthHelper.getAdminHeaders() },
        )
        dispatch({
          payload: data.data,
          type: OutreachQueueActions.ACTION_TYPES.UPDATE_RECIPIENT_OUTREACH_QUEUE_SUCCESS,
        })
      } catch (err) {
        const { response }: any = err
        console.error(response)
        dispatch({
          payload: response?.data?.error || UNKNOWN_ERROR,
          type: OutreachQueueActions.ACTION_TYPES.UPDATE_RECIPIENT_OUTREACH_QUEUE_ERROR,
        })
      }
    }
  public static pauseResumeRecipient =
    ({ outreachQueueId, outreachQueuePersonId }: PauseResumeRecipient) =>
    async (dispatch) => {
      try {
        dispatch({
          type: OutreachQueueActions.ACTION_TYPES.PAUSE_RESUME_RECIPIENT_OUTREACH_QUEUE_TRIGGER,
        })
        const url = `/v2/callListQueues/${outreachQueueId}/recipients/${outreachQueuePersonId}/pauseResume`
        const { data }: AxiosResponse = await API.patch(url, {}, { headers: AuthHelper.getAdminHeaders() })
        dispatch({
          payload: data.data,
          type: OutreachQueueActions.ACTION_TYPES.PAUSE_RESUME_RECIPIENT_OUTREACH_QUEUE_SUCCESS,
        })
      } catch (err) {
        const { response }: any = err
        console.error(response)
        dispatch({
          payload: response?.data?.error || UNKNOWN_ERROR,
          type: OutreachQueueActions.ACTION_TYPES.PAUSE_RESUME_RECIPIENT_OUTREACH_QUEUE_ERROR,
        })
      }
    }
  public static updatePriorityOrder =
    ({ outreachQueueId, outreachQueuePersonId, priorityOrder }: UpdatePriorityOrder) =>
    async (dispatch) => {
      try {
        dispatch({
          type: OutreachQueueActions.ACTION_TYPES.UPDATE_PRIORITY_ORDER_OUTREACH_QUEUE_TRIGGER,
        })
        const url = `/v2/callListQueues/${outreachQueueId}/recipients/priorityOrders`
        const { data }: AxiosResponse = await API.post(
          url,
          {
            priorityOrder,
            callListQueueRecipientId: outreachQueuePersonId,
          },
          { headers: AuthHelper.getAdminHeaders() },
        )
        dispatch({
          payload: data.data,
          type: OutreachQueueActions.ACTION_TYPES.UPDATE_PRIORITY_ORDER_OUTREACH_QUEUE_SUCCESS,
        })
      } catch (err) {
        const { response }: any = err
        console.error(response)
        dispatch({
          payload: response?.data?.error || UNKNOWN_ERROR,
          type: OutreachQueueActions.ACTION_TYPES.UPDATE_PRIORITY_ORDER_OUTREACH_QUEUE_ERROR,
        })
      }
    }

  public static fetchProgramsRecipients = (organizationId: string, contactType?: ProgramRecipientContactType, includeStatus?: boolean) => async (dispatch) => {
    let res: AxiosResponse

    dispatch({
      type: OutreachQueueActions.ACTION_TYPES.FETCH_PROGRAMS_RECIPIENTS_SUCCESS,
    })

    try {
      const cacheKey = JSON.stringify({
        organizationId,
        contactType,
        includeStatus: includeStatus ?? false,
      })
      const isCached = programsRecipientsCache[cacheKey]
      if (isCached && isCached.timestamp > dayjs().subtract(30, "seconds").valueOf()) {
        dispatch({
          payload: isCached.data,
          type: OutreachQueueActions.ACTION_TYPES.FETCH_PROGRAMS_RECIPIENTS_SUCCESS,
        })
        return
      }
      res = await API.get("/v2/callListQueues/programsrecipients", {
        headers: AuthHelper.getAdminHeaders(),
        params: {
          organizationId,
          contactType,
          includeStatus: includeStatus ?? false,
        },
      })

      programsRecipientsCache[cacheKey] = {
        timestamp: dayjs().valueOf(),
        data: res.data.data.rows,
      }
      dispatch({
        payload: res.data.data.rows,
        type: OutreachQueueActions.ACTION_TYPES.FETCH_PROGRAMS_RECIPIENTS_SUCCESS,
      })
    } catch (err) {
      const { response }: any = err
      console.error(response)
      dispatch({
        payload: response?.data?.error || UNKNOWN_ERROR,
        type: OutreachQueueActions.ACTION_TYPES.FETCH_PROGRAMS_RECIPIENTS_ERROR,
      })
    }
  }
}

export default OutreachQueueActions
