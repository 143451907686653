// tslint:disable:no-any
import React, { useState } from "react"
import { Document, Page } from "react-pdf"
import { Card, Col, CustomInput } from "reactstrap"

import IntlMessages from "../../helpers/IntlMessages"
import tosFile from "../../assets/files/CallHubTermsOfService_v6.pdf"
import { ContainerCheckBox } from "../styles/Inputs"
import { CustomRow, StyledCardBody, StyledScrollContainer } from "../styles/Table"
import { NumberHook } from "../../types/HooksType"

interface Props {
  handleCheckedTos: (checked: boolean) => () => void
  isModal?: boolean
  isMobile: boolean
}

type HandlePagesFile = () => any
type HandleLoadDocument = () => (numPages: any) => void

const TosScrollBox: React.FC<Props> = ({ handleCheckedTos, isMobile, isModal }) => {
  const [numPages, setNumPages]: NumberHook = useState<number>(0)

  const onDocumentLoadSuccess: HandleLoadDocument =
    () =>
    ({ numPages: nextNumPages }) => {
      setNumPages(nextNumPages)
    }

  const handlePagesFile: HandlePagesFile = () => Array.from(Array(numPages), (_, index) => <Page key={`page_${index + 1}`} pageNumber={index + 1} width={isMobile ? 260 : 600} />)

  return (
    <Col data-testid='onboarding-tos-scrollbox-holder'>
      <CustomRow>
        <StyledScrollContainer>
          <Card className={isModal ? "detail-modal" : "onboarding"} data-testid='onboarding-tos-scrollbox-modal-card'>
            <StyledCardBody>
              <Document file={tosFile} options={{ workerSrc: "pdf.worker.js" }} onLoadSuccess={onDocumentLoadSuccess()} data-testid='onboarding-tos-scrollbox-modal-document'>
                {handlePagesFile()}
              </Document>
            </StyledCardBody>
          </Card>
        </StyledScrollContainer>
      </CustomRow>

      <CustomRow>
        <ContainerCheckBox data-testid='onboarding-tos-scrollbox-modal-checkbox-holder'>
          <CustomInput onChange={handleCheckedTos(true)} type='checkbox' id='checkbox-th' data-testid='onboarding-tos-scrollbox-modal-checkbox-accept' />
          <IntlMessages id='onboarding.accept-terms-of-service' />
        </ContainerCheckBox>
      </CustomRow>
    </Col>
  )
}

export default TosScrollBox
