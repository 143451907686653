import { QueryClient } from "@tanstack/react-query"
import axios from "axios"
import AuthHelper from "./helpers/AuthHelper"

const defaultUrl = "https://localhost:3000"
const axiosInstance = axios.create({
  baseURL: window._env_.REACT_APP_BASE_URL || defaultUrl,
})
axiosInstance.interceptors.request.use((config) => {
  const { organizationId } = AuthHelper.getUserAuth() || {}
  if (organizationId) {
    config.headers["X-Org-Id"] = organizationId
  }
  config.headers["X-Fe-Route"] = window.location.pathname
  return config
})
export const queryClient = new QueryClient()
export default axiosInstance
