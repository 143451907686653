// tslint:disable: max-line-length
/*
  Language Texts
  ==============

  Table of Contents
  -----------------

  01.General
  02.User
  03.Login
  04.Menu
  05.Pages
  06.Person
  07.Organization
  08.Campaign
  09.Onboarding
  10.Tooltip
  11.CallLogs
  12.Program
  13.Call Analytics
  14.My account
  15.Date Filter
  16.Person Detail
  17.Broadcast
  18.New Broadcast
  19.Broadcast Detail
  20.Surveys
  21.Organization Person Status
  22.Notes List
  23.Outreach Queue
*/

export default {
  /* 01.General */
  "general.anonymize-export": "Exportación Anonimizada",
  "general.back": "Volver",
  "general.clear-filters": "Limpiar Filtro",
  "general.cancel": "Cancelar",
  "general.close": "Cerrar",
  "general.contact-info": "¿Tiene preguntas sobre nuestro servicio o necesita ayuda con su cuenta?",
  "general.contact-subtitle": "Contáctenos",
  "general.contacts": "Contactos",
  "general.copyright": "Nimble.la © 2019 Todos los derechos reservados.",
  "general.download": "Descargar archivo",
  "general.download-complete": "Descarga completa",
  "general.error": "Error",
  "general.export": "Exportar",
  "general.create-report": "Crear Reporte",
  "general.export-call-analytics": "Exportar Call Analytics",
  "general.anonymous-export": "Exportar Anonimamente",
  "general.export-outreach": "Exportar Prospectos",
  "general.export-recipient": "Exportar Destinatarios",
  "general.export-caller": "Exportar Voluntarios",
  "general.export-inactive": "Exportar Inactivos",
  "general.mail": "",
  "general.verify": "Verifique",
  "general.next": "Siguiente",
  "general.processing": "Procesando",
  "general.submit": "Enviar",
  "general.editable-label.on-hover-message": "Haga clic en la etiqueta para editarla",
  "general.export-survey-disabled": "El botón está desactivado, ya que no cumple los requisitos necesarios para exportar",
  "general.permission.action-denied": "Acción denegada",
  "general.export-select-extra-fields": "Por favor, seleccione los campos adicionales que desea exportar",

  /* 02.User */
  "user.call-logs": "Registro de llamadas",
  "user.confirm-new-password": "Confirmar Nueva Contraseña",
  "user.confirm-password": "Confirmar contraseña",
  "user.create-descriptive-text": "Por favor, completa los campos para crear tu nueva contraseña",
  "user.create-password": "Crear nueva contraseña",
  "user.email": "Email",
  "user.forgot-password": "Se te olvidó tu contraseña",
  "user.forgot-password-question": "¿Olvidaste tu contraseña?",
  "user.if-not-member": "Si aún no es miembro, por favor ",
  "user.invalid-password": "Contraseña incorrecta",
  "user.new-password": "Nueva contraseña",
  "user.new-password-max": "Máximo 128 caracteres",
  "user.old-password": "Antigua Contraseña",
  "user.password": "Contraseña",
  "user.please-register": "Regístrese",
  "user.reset": "Recuperar contraseña",
  "user.reset-descriptive-text": "Por favor ingresa tu dirección de correo asociada con tu cuenta de Callhub",
  "user.reset-descriptive-mfa-text": "Por favor verifique su identidad Nombre de usuario y número de cuenta de Callhub.",
  "user.reset-password-button": "ENVIAR",
  "user.reset-password-send-message": "Te enviaremos un correo para que recuperes tu contrasña. Si no has recibido el correo, por favor revisa la carpeta de spam",
  "user.reset-password-complete-message-title": "¡Contraseña actualiza!",
  "user.reset-password-complete-message": "Puede proceder a iniciar sesión",
  "user.reset-password-mfa-message": "Primero, verifiquemos que la cuenta le pertenece y luego podremos restablecer su contraseña.",
  "user.logout": "Cerrar Sesión",
  "user.use-credentials": "Por favor, usá tus credenciales para ingresar.",
  "user.username": "Nombre de usuario",
  "user.switch-organization": "Cambiar organización",
  "user.forgot-password-phone": "Phone",
  "user.forgot-password-phone-digits": "Últimos 4 dígitos de tu número de teléfono",
  "user.mfa-select": "¿Verificar su identidad mediante SMS o recibir una llamada?",
  "user.mfa-verify": "Por favor ingrese su código de verificación",
  "user.password-change-success": "Contraseña actualizada exitosamente",
  "user.login-descriptive-mfa-text": "Verifique su identidad de Callhub",
  "user.login-mfa-phone-select": "Por favor seleccione un número y un método de contacto",
  "user.login-mfa-contact-select": "¿Cómo llegamos a usted?",
  "user.login-mfa-verify-error": "No se puede verificar su identidad de CallHub",
  "user.login-mfa-verify-retry": "¿Intentar otra vez?",
  "user.login-mfa-verify-support": "Necesita más ayuda, póngase en contacto con el soporte",

  /* 03.Login */
  "login.button": "INICIAR SESIÓN",
  "login.email-invalid": "Email inválido",
  "login.email-required": "Email requerido",
  "login.error-message": "Credenciales incorrectas",
  "login.password-confirm-required": "Confirmar contraseña requerida",
  "login.password-length": "La contraseña debe tener al menos 8 caracteres",
  "login.password-match": "Las contraseñas no coinciden",
  "login.password-required": "Contraseña requerida",
  "login.title": "Iniciar sesión",
  "login.welcome": "Bienvenido a Callhub",
  "login.username-required": "Nombre de usuario requerido",
  "login.select-organization": "Seleccione una organización para continuar",
  "login.select-organization-continue": "CONTINUAR",
  "login.select-organization-error": "Por favor, seleccione una organización para continuar",

  /* 04.Menu */
  "menu.account": "Perfil",
  "menu.app": "Inicio",
  "menu.campaigns": "Campañas",
  "menu.contacts": "Contactos",
  "menu.call-analytics": "Analytics",
  "menu.call-logs": "Llamadas",
  "menu.surveys": "Encuestas",
  "menu.outreach": "Cola de Prospectos",
  "menu.dashboard": "Inicio",
  "menu.notes": "Notas",
  "menu.broadcasts": "Broadcasts",
  "menu.edit-profile": "Editar",
  "menu.list": "Lista",
  "menu.my-account": "Mi cuenta",
  "menu.organization": "Organización",
  "menu.organizations": "Organizaciones",
  "menu.recipient": "Recipients",
  "menu.recipients": "Destinatarios",
  "menu.users": "Usuarios",
  "menu.caller": "Voluntarios",
  "menu.callers": "Voluntarios",
  "menu.welcome": "Bienvenido",

  /* 05.Pages */
  "pages.button-accept": "Aceptar",
  "pages.button-delete-outreach": "ELIMINAR PROSPECTO",
  "pages.button-delete-recipient": "ELIMINAR DESTINATARIO",
  "pages.button-convert-recipient": "CONVERTIR A DESTINATARIO",
  "pages.button-return-to-onboarding": "VOLVER A ONBOARDING",
  "pages.button-disable-profile": "DESACTIVAR PERFIL",
  "pages.button-enable-profile": "ACTIVAR PERFIL",
  "pages.button-delete-caller": "ELIMINAR VOLUNTARIO",
  "pages.button-next": "Próximo",
  "pages.button-back": "Anterior",
  "pages.create-success": "Creado exitosamente",
  "pages.edit-success": "Done",
  "pages.email-sent-success": "Si procede, enviaremos al usuario instrucciones para restablecer la contraseña.\n",
  "pages.email-sent-error": "Email inválido. Por favor, intente con otra dirección",
  "pages.error": "Error",
  "pages.error-403": "403",
  "pages.error-404": "404",
  "pages.error-500": "500",
  "pages.error-code": "Código de error",
  "pages.error-magic": "LA MAGIA ESTÁ EN LOS DETALLES",
  "pages.error-magic-indeed": "Si, de hecho lo está!",
  "pages.error-title": "Algo ha salido mal!",
  "pages.error-subtitle": "Página no encontrada",
  "pages.forbidden-subtitle": "Algo salió mal",
  "pages.go-back": "Volver",
  "pages.go-back-home": "Volver",
  "pages.match-success": "Emparejado exitosamente",
  "pages.add-person-to-queue-success": "Agregado a la cola exitosamente!",
  "pages.complete-survey-success": "Encuesta creada, haga clic en el botón de abajo para responder",
  "pages.password-change-success": "Contraseña actualizada exitosamente",

  /* 06.Person */
  "person.actions": "Acciones",
  "person.queues": "Colas",
  "person.active": "Si",
  "person.age": "Edad",
  "person.create": "Crear",
  "person.confirm-password": "Confirmar contraseña",
  "person-confirm-password-required": "Confirmar contraseña es requerido",
  "person.edit-pin-only-numbers": "Pin debe ser solo números",
  "person.edit-recipient-success-message": "Destinatario actualizado exitosamente",
  "person.edit-caller-success-message": "Voluntario actualizado exitosamente",
  "person.edit-success-message": "Actualizado correctamente",
  "person.edit-error-message": "Error! No se pudo editar",
  "person.edit-title": "Editar",
  "person.email": "Email",
  "person.empty-recipient": "No se encontraron destinatarios",
  "person.empty-recipients": "No se encontraron destinatarios",
  "person.empty-caller": "No se encontraron voluntarios",
  "person.empty-callers": "No se encontraron voluntarios",
  "person.empty-users": "No se encontraron usuarios",
  "person.error-blank-space": "Este campo no puede estar vacío",
  "person.error-detail": "La información del perfil no está disponible",
  "person.error-email-format": "Formato invalido",
  "person.error-email-required": "Email es requerido",
  "person.error-first-name-required": "Nombre es requerido",
  "person.error-first-name-max-length": "Máximo 255 caracteres",
  "person.error-last-name-required": "Apellido es requerido",
  "person.error-last-name-max-length": "Máximo 255 caracteres",
  "person.error-match-recipients.required": "Destinatario es requerido",
  "person.error-match-callers-required": "Voluntario es requerido",
  "person.error-min-pin-numbers": "El pin debe tener al menos 4 números",
  "person.error-password-digit": "La contraseña debe contener al menos un dígito",
  "person.error-password-lowercase": "La contraseña debe contener al menos una letra minúscula",
  "person.error-password-min": "La contraseña debe tener al menos 12 caracteres",
  "person.error-password-required": "Contraseña es requerida",
  "person.error-password-special-symbol": "La contraseña debe contener al menos un caracter especial",
  "person.error-password-uppercase": "La contraseña debe contener al menos una letra mayúscula",
  "person.error-phone-code-invalid": "El código de area de US debe empezar con 1",
  "person.error-phone-min": "Teléfono debe ser un número valido",
  "person.error-phone-invalid": "Teléfono invalido",
  "person.error-phone-required": "Teléfono es requerido",
  "person.error-pin-max": "Pin debe ser de 4 dígitos",
  "person.error-pin-required": "Pin es requerido",
  "person.error-preferred-name-required": "Nombre de preferencia es requerido",
  "person.error-preferred-name-max-length": "Máximo 255 caracteres",
  "person.error-profile": "Usuario no encontrado",
  "person.error-program-required": "Programa es requerido",
  "person.error-user-name-length": "Máximo 50 caracteres",
  "person.error-user-name-required": "Nombre de usuario es requerido",
  "person.error-user-name-spaces": "No se permiten espacios en blanco",
  "person.first-name": "Nombre",
  "person.id": "ID",
  "person.inactive": "No",
  "person.label-match-recipient": "Selecccione un destinatario",
  "person.label-match-caller": "Selecccione un voluntario",
  "person.last-name": "Apellido",
  "person.loading": "Cargando",
  "person.match-placeholder-recipient": "No hay destinatarios seleccionados",
  "person.match-placeholder-caller": "No hay voluntarios seleccionados",
  "person.match-recipients": "Seleccione un Destinatario",
  "person.match-callers": "Seleccione un Voluntario",
  "person.matched": "Emparejada",
  "person.matches": "Emparejados",
  "person.name": "Nombre",
  "person.notes": "Notas",
  "person.organization": "Organización",
  "person.password": "Contraseña",
  "person.password-match-error": "Las contraseñas no coinciden",
  "person.preferred-name": "Nombre Preferido",
  "person.phone": "Teléfono Primario",
  "person.phone-alternate": "Teléfono Alternativo",
  "person.pin": "Pin",
  "person.pin-generate": "Generar",
  "person.pin-only-numbers": "Pin debe ser solo números",
  "person.program": "Programa",
  "person.recipient-edit-title": "Editar Destinatario",
  "person.status": "Activo",
  "person.organization-status": "Estado",
  "person.organization-program": "Programa",
  "person.organization-role": "Papel",
  "person.organization-status.placeholder": "Seleccione un estado",
  "person.title-add-person-to-queue": "Agregar persona a la cola",
  "person.title-add-organization-admin": "Agregar Administrador de Organizacion",
  "person.title-add-program": "Agregar programa",
  "person.title-add-status": "Agregar Estado",
  "person.title-view-config": "Configuraciónes",
  "person.title-match-recipients": "Encuentra un Destinatario",
  "person.title-complete-survey": "Completar una encuesta",
  "person.title-match-callers": "Encuentra un Voluntario",
  "person.title-terms-of-service": "Términos de Servicio y Política de Uso Aceptable",
  "person.unmatched": "Sin Emparejar",
  "person.user-name": "Nombre de usuario",
  "person.external-person-id": "ID de usuario externo",
  "person.caller-edit-title": "Editar Voluntario",
  "person-org-complete-survey-form-select-label": "Seleccionar encuesta",
  "person-org-complete-survey-form-select-placeholder": "Cargando...",

  /* 07.Organization */
  "organization.is-external": "Externa",
  "organization.external-id": "ID Externo",
  "organization.external-id.max-length": "Al menos 2 caracteres",
  "organization.external-id.min-length": "255 caracteres como máximo",
  "organization.external-id.required": "Cuando el switch externo está activado, este campo es obligatorio",
  "organization.external-id.empty": "Cuando el switch externo está inactivado, este campo debe estar vacío",
  "organization.active": "Activa",
  "organization.active.yes": "Si",
  "organization.active.no": "No",
  "organization.add-outreach": "Agregar Destinatario Prospecto",
  "organization.add-recipient": "Agregar Destinatario",
  "organization.add-title": "Agregar Organización",
  "organization.add-caller": "Agregar Voluntario",
  "organization.address-city": "Ciudad",
  "organization.address-line-2": "Detalle dirección",
  "organization.address-line-2-optional": " (opcional)",
  "organization.address-line-2-placeholder": "Piso, Número puerta, otro.",
  "organization.address-state": "Estado",
  "organization.address-state-short": "Código de estado",
  "organization.address-street": "Calle",
  "organization.address-zipcode": "Código postal",
  "organization.contact-phone": "TELÉFONO DE LA ORGANIZACIÓN",
  "organization.contact-phone-form": "Teléfono de la organización",
  "organization.error-phone-required": "Telefono requerido",
  "organization.csv-instructions": "Puede ver las instrucciones completas ",
  "organization.csv-instructions-here": "aquí",
  "organization.download-file": "No tiene un archivo CSV?",
  "organization.download-first-instruction": "1) Descargue el CSV de CallHub",
  "organization.download-second-instruction": "2) Agregue la información de Destinatrios y Voluntarios de forma que coincida con la plantilla",
  "organization.download-third-instruction": "3) Cargue su archivo utilizando el botón más abajo",
  "organization.edit": "Editar",
  "organization.edit-city-required": "Ciudad requerida",
  "organization.edit-email-required": "Email requerido",
  "organization.edit-email-valid": "Email inválida",
  "organization.edit-name-max-length": "Máximo 255 caracteres",
  "organization.edit-name-required": "Nombre de organización requerido",
  "organization.edit-primary-contact-max-length": "Máximo 255 caracteres",
  "organization.edit-primary-contact-required": "Contacto primario requerido",
  "organization.edit-program-required": "Programa requerido",
  "organization.edit-state-required": "Estado requerido",
  "organization.edit-state-short-required": "Código de estado requerido",
  "organization.edit-street-required": "Calle requerida",
  "organization.edit-callhub-line": "Linea CallHub requerido",
  "organization.edit-address-street-max-length": "Máximo 50 caracteres",
  "organization.edit-zipcode-as-much-numbers": "Código postal máximo 5 números",
  "organization.edit-zipcode-at-least-numbers": "Código postal mínimo 5 números",
  "organization.edit-zipcode-only-numbers": "Código postal debe ser solo números",
  "organization.edit-zipcode-required": "Código postal requerido",
  "organization.email": "Email",
  "organization.email-detail": "EMAIL",
  "organization.email-invitations-sent": "Invitaciones enviadas",
  "organization.email-invite": "Invitar voluntarios",
  "organization.email-send-invitations": "Enviar invitaciones",
  "organization.email-sending-invitations": "Enviando",
  "organization.error-blank-space": "Este campo no puede estar vacío",
  "organization.error-list": "No se han encontrado Organizaciones.",
  "organization.error-message": "La información de la organización no esta disponible.",
  "organization.error-profile": "Organización no encontrada",
  "organization.file-processing": "Procesando",
  "organization.file-selected": "Archivo seleccionado:",
  "organization.id": "ID",
  "organization.inactive": "Inactivo",
  "organization.instructive-video": "Video instructivo",
  "organization.join-date": "FECHA EN QUE SE UNIÓ",
  "organization.list-create-button": "Agregar organización",
  "organization.loading": "Cargando",
  "organization.location-detail": "UBICACIÓN",
  "organization.name": "Nombre de la organización",
  "organization.no-outreach": "No hay prospectos disponibles",
  "organization.no-recipient": "No hay destinatarios disponibles",
  "organization.no-caller": "No hay voluntarios disponibles",
  "organization.no-inactive": "No hay resultados para inactivos",
  "organization.phone-number": "LINEA DE CALLHUB",
  "organization.phone-number-form": "Línea de CallHub",
  "organization.point-of-contact": "Punto de contacto",
  "organization.point-of-contact-detail": "PUNTO DE CONTACTO",
  "organization.primary-contact": "Contacto primario",
  "organization.program-name": "Nombre",
  "organization.program-delete": "Remover programa",
  "organization.program-set-default": "Asignar programa por defecto",
  "organization.program-default": "Programa por defecto",
  "organization.status-name": "Nombre del estado",
  "organization.status-description": "Descripción del estado",
  "organization.programs": "Programa(s)",
  "organization.recipients": "Destinatarios",
  "organization.outreaches": "Prospecto de usuario",
  "organization.status": "Estado",
  "organization.select-file": "Seleccione un archivo",
  "organization.total-recipient": "TOTAL DE DESTINATARIOS",
  "organization.total-users": "TOTAL DE USUARIOS",
  "organization.total-caller": "TOTAL DE VOLUNTARIOS",
  "organization.upload-csv": "Cargar archivo CSV",
  "organization.upload-outreach": "Cargar Prospectos",
  "organization.upload-recipient": "Cargar Destinatarios",
  "organization.upload-caller": "Cargar Voluntarios",
  "organization.callers": "Voluntarios",
  "organization.status-set-default": "Asignar estado por defecto",
  "organization.status-default": "Estado por defecto",
  "organization.status-delete": "Remover estado",
  "organization.status-set-default-locked": "Estado predeterminado del programa",

  /* 08.Campaign */
  "campaign.clear-button": "Limpiar",
  "campaign.form-tag": "Ingrese los IDs de los Voluntarios",
  "campaign.send-button": "Enviar",
  "campaign.success-message": "Emails enviados exitosamente!",
  "campaign.title": "Email de bienvenida a los Voluntarios",

  /* 09.Onboarding */
  "onboarding.accept-terms-of-service": "Acepto los Términos de Servicio y Política de Uso Aceptable de CallHub",
  "onboarding.call-hub": "CallHub",
  "onboarding.create-new-password": "Por favor, cree una nueva contraseña para comenzar a usar su cuenta",
  "onboarding-caregivers-text": "Protegiendo la seguridad y salud de nuestra población vulnerable y cuidadores.",
  "onboarding.download-csv": "Descargar CSV",
  "onboarding.download-instructions": "Instrucciones de descarga",
  "onboarding-download-first-instruction": "1) Descargue el CSV de CallHub",
  "onboarding-download-second-instruction": "2) Agregue la información de Destinatrios y Voluntarios de forma que coincida con la plantilla",
  "onboarding-download-third-instruction": "3) Por favor, cargue el CSV completo en la próxima página",
  "onboarding.file-selected": "Archivo Seleccionado: ",
  "onboarding.invitation": "Fué invitado a unirse a Call Hub!",
  "onboarding-no-file-selected": "Archivo no seleccionado. Desea continuar sin cargar un CSV?",
  "onboarding.password-error": "Algo ha salido mal, intente nuevamente",
  "onboarding.password-length": "Debe ser al menos de 8 caracteres",
  "onboarding.password-not-match": "Las contraseñas no coinciden",
  "onboarding.password-success": "Contraseña actualizada correctamente",
  "onboarding.processing-csv": "Procesando",
  "onboarding.step": "Paso",
  "onboarding.success": "Éxito!",
  "onboarding.select-file": "Seleccione un archivo",
  "onboarding.select-file-alternative": "Por favor, selecciona tu archivo CSV para actualizarlo en caso que tengas uno",
  "onboarding.terms-of-service": "Términos de Servicio y Política de Uso Aceptable",
  "onboarding.watch-the-video": "Vea el video para averiguar cómo descargar un CSV y agregarle su información",

  /* 10.Tooltips */
  "tooltip-add-organization": "Crear una nueva organización",
  "tooltip-add-organization-admin": "Agregar administrador",
  "tooltip-duration-filter-calllogs": "Buscar una duración mayor al numero introducido",
  "tooltip-add-program-organization": "Agregar programa",
  "tooltip-add-status-organization": "Agregar estado",
  "tooltip-view-config-organization": "Configuraciónes",
  "tooltip-create-user": "Cree un nuevo destinatario o voluntario",
  "tooltip-help-terms-of-service": "Por favor, lea el archivo y acepte los Términos de Servicio y Política de Uso Aceptable para poder continuar",
  "tooltip-edit-organization": "Editar información de la organización",
  "tooltip-send-survey": "Completar una encuesta",
  "tooltip-edit-caller": "Editar información del voluntario",
  "tooltip-edit-outreach": "Editar información del destinatario prospecto",
  "tooltip-edit-recipient": "Editar información del destinatario",
  "tooltip-export-call-logs": "Exportar todos los registros de llamadas",
  "tooltip-export-call-analytics": "Exportar todos los registros analytics",
  "tooltip-export-call-analytics.one": "Exportar CallStats 1",
  "tooltip-export-call-analytics.two": "Exportar CallStats 2",
  "tooltip-export-call-analytics.three": "Exportar CallStats 3",
  "tooltip-export-caller": "Exportar todos los voluntarios",
  "tooltip-export-recipient": "Exportar todos los destinatarios",
  "tooltip-person-match": "Crear un nuevo emparejamiento",
  "tooltip-upload-csv": "Cree múltiples voluntarios o destinatarios con un archivo CSV",
  "tooltip-invite-callers": "Invitar voluntarios por email",
  "tooltip-delete-orgAdmin": "Los administradores de una organización no pueden ser eliminados",
  "tooltip-add-new-note": "Crear nueva nota al usuario",
  "tooltip-opt-out-sms-disabled": "Envíe la palabra STOP para excluirse y la palabra START para volver a participar",
  "tooltip-sortable-column": "Haga clic para cambiar la columna y la dirección de la clasificación",

  /* 11.CallLogs */
  "call-logs.clear-filters": "Limpiar Filtro",
  "call-logs.date-time": "DÍA Y HORA",
  "call-logs.invalid-date-time": "Formato esperado: mm/dd/yyyy",
  "call-logs.duration": "DURACIÓN",
  "call-logs.filter-by-organizations": "Filtrar por Organización",
  "call-logs.filter-by-programs": "Filtrar por Programa",
  "call-logs.filter-by-types": "Filtrar por Tipo",
  "call-logs.from": "DE",
  "call-logs.loading": "Cargando",
  "call-logs.not-found": "No se han encontrado registros de llamadas",
  "call-logs.notes": "Notas",
  "call-logs.organization": "ORGANIZACIÓN",
  "call-logs.status": "ESTADO",
  "call-logs.to": "PARA",
  "call-logs.caller-survey": "ENCUESTA",
  "call-logs.outcome": "RESULTADO",

  /* 12.Program */
  "program.error-name-max-length": "Máximo 50 caracteres",
  "program.error-name-min-length": "Mínimo 3 caracteres, caracteres vazios no son permitidos",
  "program.error-name-required": "Nombre es requerido",

  /* 13.Call Analytics */
  "call-analytics.clear-filters": "Limpiar Filtro",
  "call-analytics.date-time": "DÍA Y HORA",
  "call-analytics.filter-by-programs": "Filtrar por Programas",
  "call-analytics.filter-by-organizations": "Filtrar por Organización",
  "call-analytics.loading": "Cargando",
  "call-analytics.not-found": "No se han encontrado registros Analytics",
  "call-analytics.notes": "Notas",
  "call-analytics.export.button": "Exportar",
  "call-analytics.report-one": "CALLSTATS 1",
  "call-analytics.report-one.description":
    "Resume datos de alto nivel que muestran fácilmente la escala y el alcance del programa. Las organizaciones suelen utilizar este informe para poner al día a sus líderes con puntos de datos clave de alto nivel, como el número total de personas que reciben llamadas y el número total de minutos de llamadas.",
  "call-analytics.report-two": "CALLSTATS 2",
  "call-analytics.report-two.description":
    "Resume los datos de las llamadas, incluida la duración total de las llamadas realizadas a cada destinatario. Las organizaciones suelen utilizar este informe para obtener una visión de alto nivel sobre el número y la duración de las conexiones que se producen entre cada partido.",
  "call-analytics.report-three": "CALLSTATS 3",
  "call-analytics.report-three.description":
    "El informe más detallado. Resume el total de llamadas de cada llamante y de cada destinatario. Puede ordenarse por tipo (llamante o destinatario) para reunir información específica de un vistazo, incluyendo la hora de la llamada, el total de llamadas realizadas, la fecha de la llamada más reciente y las coincidencias actuales.",
  "call-analytics.report-four": "CALLSTATS 4",
  "call-analytics.tabs.first-title": "Tipos de Reportes",
  "call-analytics.tabs.second-title": "Reportes Generados",

  /* 14.My account */
  "my-account.password-expired.notification-message": "Debe actualizar su contraseña! (Debe ser actualizada cada 90 días)",
  "my-account.password-expired.notification-title": "NOTIFICACIÓN",
  "my-account.password-requirement.confirm-password-must-match": "Las contraseñas deben coincidir",
  "my-account.password-requirement.contains-user-info": "Su contraseña no debe contener partes de su nombre completo o usuario",
  "my-account.password-requirement.has-lowercase": "Al menos una letra minúscula",
  "my-account.password-requirement.has-minimum-length": "Longitud mínima de 12 caracteres",
  "my-account.password-requirement.has-number": "Al menos un número (0-9)",
  "my-account.password-requirement.has-special-symbol": "Al menos un caracter especial (!@#$%^&*)",
  "my-account.password-requirement.has-uppercase": "Al menos una letra mayúscula",
  "my-account.password-requirement.invalid-characters": "Caracteres no válidos",
  "my-account.password-requirement.not-match-all-reqs": "Su contraseña no cumple con todos los requisitos",
  "my-account.password-requirement.title": "Su contraseña debe contener:",
  "my-account.password.title": "Contraseña",
  "my-account.password.update-message": "Su contraseña debe actualizarse cada 90 días",
  "my-account.password.update-password-submit": "Actualizar contraseña",
  "my-account.title": "Mi Cuenta",

  /* 15.Date Filter */
  "date-filter.title": "Preestablecidos",
  "date-filter.last_24_hours": "Últimas 24 Horas",
  "date-filter.last_7_days": "Últimos 7 Días",
  "date-filter.last_30_days": "Últimos 30 Días",
  "date-filter.last_60_days": "Últimos 60 Días",
  "date-filter.last_90_days": "Últimos 90 Días",

  /* 16. Person Notes */
  "note-person.note": "Nota",
  "note-person.author": "Autor",
  "note-person.createdAt": "Fecha creación",
  "note-person.title-add-note-person": "Agregar nueva nota al usuario",
  "note-person.title-add-note-person-survey": "Agregar nueva nota a la encuesta",
  "note-person.error-note-max-length": "Máximo 500 caracteres",
  "note-person.error-note-required": "Note es requerida",

  /* 17. Person Detail */
  "person-detail.gender": "Género",
  "person-detail.date-of-birth": "Fecha de nacimiento",
  "person-detail.zipcode": "Código postal",
  "person-detail.zipcode-error-only-digits": "El código postal debe ser solo números",
  "person-detail.zipcode-error-length": "El código postal debe tener 5 dígitos",
  "person-detail.phone-call-behavior": "Comportamiento de Llamadas",
  "person-detail.preferred-time-for-call": "Hora Preferida para Llamar",
  "person-detail.preferred-method-of-communication": "Método de comunicación preferido",
  "person-detail.hobbies": "Aficiones",
  "person-detail.hobbies-error-max-length": "Máximo 1000 caracteres",
  "person-detail.preferred-language": "Idioma preferido",
  "person-detail.phone-type-mobile": "Móvil",
  "person-detail.phone-type-landline": "Teléfono Fijo",
  "person-detail.phone-type": "Tipo de Teléfono",
  "person-detail.phone-type-invalid": "Tipo de teléfono inválido",
  "person-detail.preferred-method-communication": "Método preferido de comunicación",
  "person-detail.opted-out": "Elegido por no participar",
  "person-detail.survey-opted-out": "Encuesta rechazada",

  /* 18. Broadcast */
  "broadcast.title": "Difusiones",
  "broadcast.name": "Asunto",
  "broadcast.sent_to_count": "# Enviado a",
  "broadcast.organization": "Organización",
  "broadcast.programs_recipients": "Programas/receptores",
  "broadcast.upload-csv": "Cargar Receptores desde CSV",
  "broadcast.message_type": "Tipo de mensaje",
  "broadcast.replies_count": "Respuestas",
  "broadcast.status": "Estado",
  "broadcast.date": "Fecha",
  "broadcast.date_timezone": "Zona horaria",
  "broadcast.filter.types.label": "Filtrar por Tipos",
  "broadcast.filter.status.label": "Filtrar por Estado",
  "broadcast.list-create-button": "Nueva Difusión",
  "broadcast.list-create-button-title": "Crear una nueva emisión",
  "broadcast.list-create-button-title-disabled": "Seleccione una organización",
  "broadcast.error-list": "No se han encontrado difusiones",
  "broadcast.options.edit": "Editar",
  "broadcast.options.details": "Detalles",
  "broadcast.options.delete": "Borrar",
  "broadcast.options.archive": "Archivar",
  "broadcast.options.duplicate": "Duplicar",
  "broadcast.confirmation.confirm.btn": "Confirmar",
  "broadcast.confirmation.cancel.btn": "Cancelar",
  "broadcast.confirmation.archive.title": "Archivar transmisión",
  "broadcast.confirmation.archive.message": "¿Está seguro de que desea archivar esta transmisión y toda la información asociada?",
  "broadcast.confirmation.delete.title": "Borrar transmisión",
  "broadcast.confirmation.delete.message": "¿Está seguro de que desea eliminar esta transmisión y toda la información asociada?",

  /* 18. New Broadcast */
  "broadcast.new.title": "Nueva Difusión",
  "broadcast.new.section_1.title": "Creación de una nueva Difusión",
  "broadcast.new.section_1.paragraph_1":
    "La difusión es una gran manera de enviar un mensaje masivo a múltiples destinatarios por una multitud de razones, como recordatorios de llamadas, alertas y anuncios importantes.",
  "broadcast.new.section_1.paragraph_2":
    "Actualmente, puedes grabar una nota de voz para luego ser enviada a los destinaraios, escribir un mensaje de texto para ser enviado al número personal o enviar correos electrónicos.",
  "broadcast.new.section_2.title": "Instrucciones",
  "broadcast.new.section_2.paragraph_1": "1. Elija el tipo de mensaje que desea transmitir (Voz  / Email / SMS ).",
  "broadcast.new.section_2.paragraph_2":
    "2. Seleccione sus destinatarios eligiendo la organización en el menú desplegable y luego seleccionando los destinatarios que forman parte de esa organización.",
  "broadcast.new.section_2.paragraph_3": "3. Cree su mensaje grabando un mensaje de voz, escribiendo un mensaje de texto o creando un correo electronico.",
  "broadcast.new.section_2.paragraph_4": '4. Presione "Enviar Difusion" para entrega inmediata o "Guardar el proyecto" para tener la oportunidad de actualizar tu difusion más tarde.',
  "broadcast.new.section_2.note": "Nota: Siempre puede guardar su información editar y/o publicar más tarde.",
  "broadcast.new.section_3.title": "Seleccione los destinatarios",
  "broadcast.new.section_4.title": "Seleccione el tipo de mensaje",
  "broadcast.new.section_5.title": "Crear Mensaje",
  "broadcast.new.section_5.subtitle": "Asunto",
  "broadcast.new.section_5.select_placeholder": "Introduzca el texto...",
  "broadcast.new.section_5.textarea_placeholder": "Introduzca el mensaje aquí...",
  "broadcast.new.section_5.message_prefix": "Prefijo del mensaje",
  "broadcast.new.section_5.message": "Contenido del mensaje",
  "broadcast.new.section_6.title": "Mensaje de programación",
  "broadcast.new.section_6.subtitle_1": "Fecha",
  "broadcast.new.section_6.scheduled_placeholder": "Seleccione o escriba...",
  "broadcast.new.section_6.subtitle_2": "Tiempo",
  "broadcast.new.section_6.subtitle_3": "Zona horaria",
  "broadcast.new.section_6.send_it_now": "Enviar ahora",
  "broadcast.new.section_7.title": "Gestionar las respuestas",
  "broadcast.new.section_7.subtitle_1": "Permitir respuestas?",
  "broadcast.new.section_7.subtitle_2": "Enviar alertas para *",
  "broadcast.new.section_7.subtitle_3": "Email *",
  "broadcast.new.section_7.subtitle_3.placeholder": "Introduzca el email...",
  "broadcast.new.section_7.placeholder": "Seleccione...",
  "broadcast.new.create.programs_recipients.add": "Adicionar",
  "broadcast.new.create.programs_recipients.clear": "Limpiar",
  "broadcast.new.buttons.send": "Enviar Difusión",
  "broadcast.new.buttons.save": "Guardar el Proyecto",
  "broadcast.new.buttons.save_exit": "Guardar el Proyecto y Salida",
  "broadcast.new.buttons.save_exit.ephemeral-disabled.tooltip": "No se puede guardar un proyecto con destinatarios uploadeados",
  "broadcast.new.create.success": "Difusión salvada con éxito",
  "broadcast.new.delete.success": "Transmisión eliminada con éxito",
  "broadcast.new.sent.success": "Difusión enviada",
  "broadcast.new.archive.success": "Transmisión archivada con éxito",
  "broadcast.new.create.organization.id.required.validation": "Se requiere organización",
  "broadcast.new.create.type.required.validation": "Seleccione un tipo de emisión",
  "broadcast.new.create.content.sms.length.validation": "El contenido no puede tener más de 320 caracteres.",
  "broadcast.new.create.content.length.validation": "El contenido no puede tener más de 4000 caracteres.",
  "broadcast.new.create.content.required.validation": "Es necesario grabar un mensaje de voz antes de enviar la emisión",
  "broadcast.new.create.title.length.validation": "El titul no puede tener más de 50 caractere",
  "broadcast.new.create.title.required.validation": "Se requiere títul",
  "broadcast.new.create.programs_recipients.length.validation": "Seleccione y agregue al menos un programa o destinatario",
  "broadcast.new.create.ephemeral_recipients.length.validation": "Upload un archivo CSV o seleccione al menos un destinatario",
  "broadcast.new.create.programs_recipients.invalid": "No se encontraron receptores de contacto válidos para esta organización.",

  /* 19. Broadcast Detail */
  "broadcast.detail.organization.title": "Nombre de la Organización",
  "broadcast.detail.message_type.title": "Tipo de mensaje",
  "broadcast.detail.message.title": "Mensaje",
  "broadcast.detail.program.title": "Programa",
  "broadcast.detail.date.title": "Fecha de envío",
  "broadcast.detail.time.title": "Tiempo enviado",
  "broadcast.detail.recipient.title": "Total de los destinatarios enviados",
  "broadcast.detail.tab.recipient.title": "Receptores",
  "broadcast.detail.tab.response.title": "Respuestas",
  "broadcast.detail.table.recipient.col_1": "Nombre",
  "broadcast.detail.table.recipient.col_2": "Estado del mensaje",
  "broadcast.detail.table.recipient.contact": "Contacto",
  "broadcast.detail.table.recipient.col_2_value": "NO",
  "broadcast.detail.table.response.col_1": "Nombre",
  "broadcast.detail.table.response.col_2": "Respuesta",
  "broadcast.detail.table.response.col_3": "Fecha de respuesta",
  "broadcast.detail.table.error-list": "No se encontraron receptores de transmisión",

  /* 20. Surveys */
  "survey.survey": "Encuesta",
  "survey.title": "Encuestas",
  "survey.survey-results.title": "Resultados de la encuesta",
  "survey.author": "Autor",
  "survey.createdAt": "Fecha creación",
  "survey.btn.submit": "Enviar",
  "survey.btn.propagate": "Propagar",
  "survey.btn.evaluation": "Sólo evaluación",
  "survey.caller-survey.date-time": "Fecha",
  "survey.caller-survey.change-of-condition": "CAMBIO DE CONDICIÓN",
  "survey.caller-survey.change-of-condition.input-placeholder": "Seleccione...",
  "survey.caller-survey.additional-comments": "Comentarios adicionales",
  "survey.caller-survey.additional-comments.input-placeholder": "Filtrar comentarios",
  "survey.caller-survey.admin-notes": "Notas administrativas",
  "survey.caller-survey.admin-notes.input-placeholder": "Filtrar notas",
  "survey.caller-survey.completed": "Completado",
  "survey.caller-survey.not-found": "No se han encontrado encuestas",
  "survey.caller-survey.invalid-date-time": "Formato esperado: mm/dd/yyyy",
  "survey.caller-survey.clear-filters": "Limpiar filtro",
  "survey.caller-survey.filter-by-organizations": "Filtrar por Organización",
  "survey.caller-survey.filter-by-program": "Filtrar por Programa",
  "survey.survey-type.survey": "ENCUESTA",
  "survey.survey-type.survey.input-placeholder": "Seleccione...",
  "survey.survey-type.conduct-survey": "Realizar encuesta de {name}",
  "survey.survey-type.review-survey": "Revisar {name}",
  "survey.survey-type.disabled-conduct-survey-tooltip": "La encuesta sólo puede ser respondida por personas de la misma organización",
  "survey.survey-type.disabled-conduct-follow-up-survey-tooltip": "La encuesta estará disponible después: {availableDate}",
  "survey.survey-type.view-survey": "Ver resultados de {name}",
  "survey.survey-type.respondent": "ENCUESTADO",
  "survey.survey-type.recipient": "ASUNTO",

  /* 21.Organization Person Status */
  "organization-person-status.error-name-max-length": "Máximo 50 caracteres",
  "organization-person-status.error-name-min-length": "Minimo 3 caracteres, caracteres vazios no son permitidos",
  "organization-person-status.error-description-max-length": "Maximo 200 caracteres",
  "organization-person-status.error-name-required": "Nombre obligatorio",

  /* 22.Notes List */
  "notes.list.header.filter-by-organizations": "Filtrar por Organización",
  "notes.list.table-header.timestamp": "FECHA",
  "notes.list.table-header.from": "DESDE",
  "notes.list.table-header.to": "PARA",
  "notes.list.table-header.program": "PROGRAMA",
  "notes.list.table-header.notes": "NOTAS",
  "notes.list.empty-table": "No se han encontrado notas",
  "notes.list.table-header.filters.program.placeholder": "Introduzca el programa",
  "notes.list.clear-filters": "Limpiar filtro",
  "notes.list.download": "Exportar",

  /* 23.Outreach Queue */
  "outreach-queue.table.header_01": "Lista de llamadas",
  "outreach-queue.table.header_02": "Destinatarios",
  "outreach-queue.table.header_03": "Llamador",
  "outreach-queue.table.header_04": "Última llamada",
  "outreach-queue.table.header_05": "Activo",
  "outreach-queue.table.empty": "Empiece creando su primera cola de difusión",
  "outreach-queue.table.last-call-conducted.never": "No se han realizado llamadas utilizando esta cola",
  "outreach-queue.btn.create": "Crear cola",
  "outreach-queue.btn.create.tooltip": "Click para crear una nueva cola",
  "outreach-queue.btn.tooltip.export": "Exportar cola",
  "outreach-queue.btn.tooltip.edit": "Editar cola",
  "outreach-queue.btn.tooltip.delete": "Borrar cola",
  "outreach-queue.btn.tooltip.delete-self-signup": "Self Signup es una cola especial que no se puede borrar",
  "outreach-queue.btn.tooltip.list-end": "Oops, no hay más entradas",
  "outreach-queue.modal.recipients": "Asignado a la cola",
  "outreach-queue.modal.callers": "Personas que llaman en cola",
  "outreach-queue.modal.create": "Crear",
  "outreach-queue.modal.edit": "Editar",
  "outreach-queue.modal.add": "Agregar",
  "outreach-queue.modal.header.self-signup": "Self Signupes una cola especial que no se puede cambiar de nombre",
  "outreach-queue.modal.delete": "Borrar",
  "outreach-queue.modal.form.name": "Escriba el nombre de la cola de difusión",
  "outreach-queue.modal.delete.message": "¿Está seguro de que desea eliminar la cola de difusión?",
  "outreach-queue.modal.delete.button-accept": "Aceptar",
  "outreach-queue.modal.delete.button-cancel": "Cancelar",
  "outreach-queue.modal.person-list.header_01": "Orden",
  "outreach-queue.modal.person-list.header_02": "Nombre",
  "outreach-queue.modal.person-list.header_03": "Última Llamada Activa",
  "outreach-queue.modal.person-list.header_04": "Siguiente Disponibilidad",
  "outreach-queue.modal.person-list.edit-btn": "Editar siguiente disponibilidad",
  "outreach-queue.modal.person-list.delete-btn": "Eliminar persona",
  "outreach-queue.modal.person-list.pause-btn": "Pausar la disponibilidad de los usuarios en la cola durante 1 día",
  "outreach-queue.modal.person-list.move-top-btn": "Mover al principio de la cola",
  "outreach-queue.modal.person-list.play-btn": "Reanudar la disponibilidad de los usuarios en la cola",
  "outreach-queue.modal.person-list-dropdown": "Seleccione un individuo o grupo",
  "outreach-queue.modal.person-list-button-save": "Actualizar",
  "outreach-queue.modal.person-list-button-cancel": "Cancelar",
  "outreach-queue.modal.person-list-tooltip-next-availability": "Próxima disponibilidad",
  "outreach-queue.selected-call-logs.title": "Registros de llamadas de {name} Cola",

  /* 24.Administrative */
  "administrative.admin-panel.title": "Panel de administración",
  "administrative.organization-feature.title": "Features por Organización",
  "administrative.organization-feature.create.title": "Crear Feature por Organización",
  "administrative.organization-feature.organization-select.label": "Seleccione una organización",
  "administrative.organization-feature.feature-select.label": "Seleccione una característica",
  "administrative.outreach-queue.title": "Outreach Queue",
  "administrative.organization-list.title": "Organizacións",
  "administrative.organization-detail.title": "Detalles de la organización",

  /* 25.Self Signup */
  "self-signup.accept-terms-of-service": "Acepto los Términos de Servicio y Política de Uso Aceptable de DHI",
  "self-signup.third-step.title1": "¿Cuál es su estilo de comunicación?",
  "self-signup.third-step.sub-title1": "¿Cuál de las siguientes opciones describe sus preferencias a la hora de llamar por teléfono?",
  "self-signup.third-step.sub-title2": "¿En qué idioma se siente cómodo en una llamada social?",
  "self-signup.third-step.sub-title3": "¿A cuántas personas le gustaría llamar regularmente?",
  "self-signup.third-step.sub-title4": "A pesar de los imprevistos de la vida, ¿puede comprometerse a participar como comunicante durante al menos tres meses?",
  "self-signup.fourth-step.title1": "Recomendar a un amigo",
  "self-signup.fourth-step.sub-title1":
    "¿Conoce a alguien más a quien le gustaría participar en este programa? Si es así, con su consentimiento, introduzca los detalles y nos pondremos en contacto con ellos.",
  "self-signup.fiftieth-step.title1": "Ya casi está!",
  "self-signup.fiftieth-step.sub-title1": "Para confirmar su identidad, por favor resuelve el captcha de abajo.",
  "self-signup.sixtieth-step.title1": "¡INSCRIPCIÓN COMPLETA! 🎊",
}
