import ActionType from "../../types/ActionType"
import Settings from "../actions/settings"
import { defaultLocale, localeOptions } from "../../constants/defaultValues"

export interface SettingsStore {
  locale: string | null
}

const INIT_STATE: SettingsStore = {
  locale: localStorage.getItem("currentLanguage") && localeOptions.filter((x) => x.id === localStorage.getItem("currentLanguage")).length > 0 ? localStorage.getItem("currentLanguage") : defaultLocale,
}

export default (state = INIT_STATE, action: ActionType) => {
  switch (action.type) {
    case Settings.CHANGE_LOCALE:
      return { ...state, locale: action.payload }

    default:
      return { ...state }
  }
}
