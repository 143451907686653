import { ExportStatusEnum } from "../../helpers/ExportHelper"
import ActionType from "../../types/ActionType"
import { ExportedCallerSurveys } from "../../types/CallerSurveysType"
import { Pager, PagerInitialValue } from "../../types/PagerType"
import { CallerSurveyAnswerResponseDTO, SurveyAnswerResponseDTO } from "../../types/SurveyType"
import CallerSurveysActions from "../actions/surveysTypes"

export interface SurveysTypesStore {
  allSurveysByType: SurveyAnswerResponseDTO[]
  actionRequiredSurveysByType: SurveyAnswerResponseDTO[]
  exportSurveyStatus: ExportStatusEnum
  surveysToExport: ExportedCallerSurveys | null
  errorMessage: string
  loadingSurveys: boolean
  allPage: Pager
  actionRequiredPage: Pager
  dateFrom: string
  dateTo: string
  callerSurvey?: CallerSurveyAnswerResponseDTO
  callerSurveyResponded?: boolean
}

const INITIAL_STATE: SurveysTypesStore = {
  allSurveysByType: [],
  actionRequiredSurveysByType: [],
  exportSurveyStatus: ExportStatusEnum.NOT_EXPORTED,
  surveysToExport: null,
  errorMessage: "",
  loadingSurveys: false,
  allPage: PagerInitialValue,
  actionRequiredPage: PagerInitialValue,
  dateFrom: "",
  dateTo: "",
  callerSurvey: undefined,
  callerSurveyResponded: false,
}

export default (state = INITIAL_STATE, action: ActionType): SurveysTypesStore => {
  switch (action.type) {
    case CallerSurveysActions.FETCH_ALL_SURVEY_BY_TYPE_TRIGGER: {
      return {
        ...state,
        loadingSurveys: true,
      }
    }

    case CallerSurveysActions.FETCH_ALL_SURVEY_BY_TYPE_SUCCESS: {
      return {
        ...state,
        loadingSurveys: false,
        allSurveysByType: action.payload.data,
        allPage: action.payload.pager,
      }
    }

    case CallerSurveysActions.FETCH_ALL_SURVEY_BY_TYPE_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        allSurveysByType: [],
        allPage: PagerInitialValue,
        loadingSurveys: false,
      }
    }

    case CallerSurveysActions.FETCH_ACTION_REQUIRED_SURVEYS_TRIGGER: {
      return {
        ...state,
        loadingSurveys: true,
      }
    }

    case CallerSurveysActions.FETCH_ACTION_REQUIRED_SURVEYS_SUCCESS: {
      return {
        ...state,
        loadingSurveys: false,
        actionRequiredSurveysByType: action.payload.data,
        actionRequiredPage: action.payload.pager,
      }
    }

    case CallerSurveysActions.FETCH_ACTION_REQUIRED_SURVEYS_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        actionRequiredSurveysByType: [],
        actionRequiredPage: PagerInitialValue,
        loadingSurveys: false,
      }
    }

    case CallerSurveysActions.MARK_SURVEY_COMPLETED_TRIGGER: {
      return {
        ...state,
        loadingSurveys: true,
      }
    }

    case CallerSurveysActions.MARK_SURVEY_COMPLETED_SUCCESS: {
      return {
        ...state,
        loadingSurveys: false,
      }
    }

    case CallerSurveysActions.MARK_SURVEY_COMPLETED_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        loadingSurveys: false,
      }
    }

    case CallerSurveysActions.EXPORT_SURVEYS_TRIGGER: {
      return {
        ...state,
        surveysToExport: null,
        exportSurveyStatus: ExportStatusEnum.TRIGGERED,
      }
    }

    case CallerSurveysActions.EXPORT_SURVEYS_SUCCESS: {
      return {
        ...state,
        surveysToExport: action.payload,
        exportSurveyStatus: ExportStatusEnum.DOWNLOAD_COMPLETED,
      }
    }

    case CallerSurveysActions.EXPORT_SURVEYS_ERROR: {
      return {
        ...state,
        surveysToExport: null,
        exportSurveyStatus: ExportStatusEnum.ERROR,
      }
    }

    case CallerSurveysActions.EXPORT_SURVEYS_CLEAR: {
      return {
        ...state,
        surveysToExport: null,
        exportSurveyStatus: ExportStatusEnum.NOT_EXPORTED,
      }
    }

    case CallerSurveysActions.POSTPONE_SURVEY_TRIGGER: {
      return {
        ...state,
        loadingSurveys: true,
      }
    }

    case CallerSurveysActions.POSTPONE_SURVEY_SUCCESS: {
      return {
        ...state,
        loadingSurveys: false,
        allSurveysByType: action.payload.data,
      }
    }

    case CallerSurveysActions.POSTPONE_SURVEY_ERROR: {
      return {
        ...state,
        loadingSurveys: false,
        errorMessage: action.payload,
      }
    }

    case CallerSurveysActions.FETCH_ONE_SURVEY_TRIGGER: {
      return {
        ...state,
        loadingSurveys: true,
      }
    }

    case CallerSurveysActions.FETCH_ONE_SURVEY_SUCCESS: {
      return {
        ...state,
        loadingSurveys: false,
        callerSurvey: action.payload.data,
      }
    }

    case CallerSurveysActions.FETCH_ONE_SURVEY_ERROR: {
      return {
        ...state,
        loadingSurveys: false,
        errorMessage: action.payload,
      }
    }

    case CallerSurveysActions.RESPOND_CALLER_SURVEY_TRIGGER: {
      return {
        ...state,
        loadingSurveys: true,
      }
    }

    case CallerSurveysActions.RESPOND_CALLER_SURVEY_SUCCESS: {
      return {
        ...state,
        loadingSurveys: false,
        callerSurvey: action.payload.data,
        callerSurveyResponded: true,
      }
    }

    case CallerSurveysActions.RESPOND_CALLER_SURVEY_ERROR: {
      return {
        ...state,
        loadingSurveys: false,
        errorMessage: action.payload,
        callerSurveyResponded: false,
      }
    }

    default: {
      return state
    }
  }
}
