// tslint:disable: no-any
import ActionType from "../../types/ActionType"
import BroadcastActions from "../actions/broadcasts"
import { AudioRecorded } from "../../hooks/useAudioRecorder"
import { BroadcastDto } from "../../views/app/broadcasts/broadcast.types"
import { BroadcastResponseActionType, BroadcastStatusEnum, BroadcastTypesEnum } from "../../constants/broadcast"
import { ProgramRecipientDto } from "../../types/ProgramsType"

export interface Failed {
  row: any
  index?: number
  message: string
}

export interface BroadcastsStore {
  error: string | null
  broadcastSuccessMessage: string | null
  loadingCreateBroadcast: boolean
  broadcastResponseAction: BroadcastResponseActionType | null
  loadingEditBroadcast: boolean
  loadingBroadcasts: boolean
  loadingBroadcast: boolean
  loadingDeleteBroadcast: boolean
  loadingArchiveBroadcast: boolean
  loadingUploadAudio: boolean
  loadingDeleteAudio: boolean
  broadcasts: BroadcastDto[]
  broadcast: BroadcastDto | null
  audio: AudioRecorded | null
  totalPages: number
  loadingBroadcastProgramsRecipients: boolean
  broadcastProgramsRecipients: ProgramRecipientDto[]
}

const INITIAL_STATE: BroadcastsStore = {
  error: null,
  broadcastSuccessMessage: null,
  broadcast: null,
  broadcasts: [],
  broadcastResponseAction: null,
  loadingCreateBroadcast: false,
  loadingEditBroadcast: false,
  loadingDeleteBroadcast: false,
  loadingArchiveBroadcast: false,
  loadingBroadcastProgramsRecipients: false,
  loadingBroadcasts: false,
  loadingBroadcast: false,
  loadingUploadAudio: false,
  loadingDeleteAudio: false,
  audio: null,
  totalPages: 1,
  broadcastProgramsRecipients: [],
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case BroadcastActions.CREATE_BROADCAST_TRIGGER: {
      return {
        ...state,
        loadingCreateBroadcast: true,
        error: null,
        broadcastSuccessMessage: null,
      }
    }

    case BroadcastActions.CREATE_BROADCAST_SUCCESS: {
      const broadcast = action.payload.data
      return {
        ...state,
        loadingCreateBroadcast: false,
        broadcastResponseAction: action.payload.action,
        broadcast: action.payload.data,
        error: null,
        broadcastSuccessMessage: broadcast.status === BroadcastStatusEnum.DRAFT ? "broadcast.new.create.success" : "broadcast.new.sent.success",
      }
    }

    case BroadcastActions.CREATE_BROADCAST_ERROR: {
      return {
        ...state,
        error: action.payload.message,
        broadcastResponseAction: action.payload.action,
        loadingCreateBroadcast: false,
        broadcastSuccessMessage: null,
        broadcast: action.payload.data,
      }
    }

    case BroadcastActions.EDIT_BROADCAST_TRIGGER: {
      return {
        ...state,
        loadingEditBroadcast: true,
        error: null,
        broadcastSuccessMessage: null,
      }
    }

    case BroadcastActions.EDIT_BROADCAST_SUCCESS: {
      const broadcast = action.payload.data
      return {
        ...state,
        loadingEditBroadcast: false,
        broadcastResponseAction: action.payload.action,
        broadcast,
        audio: broadcast.type === BroadcastTypesEnum.VOICE ? broadcast.content : null,
        broadcastSuccessMessage: broadcast.status === BroadcastStatusEnum.DRAFT ? "broadcast.new.create.success" : "broadcast.new.sent.success",
      }
    }

    case BroadcastActions.EDIT_BROADCAST_ERROR: {
      return {
        ...state,
        error: action.payload.message,
        broadcastResponseAction: action.payload.action,
        loadingEditBroadcast: false,
        broadcastSuccessMessage: null,
        broadcast: action.payload.data,
      }
    }

    case BroadcastActions.DELETE_BROADCAST_TRIGGER: {
      return {
        ...state,
        loadingDeleteBroadcast: true,
        error: null,
        broadcastSuccessMessage: null,
      }
    }

    case BroadcastActions.DELETE_BROADCAST_SUCCESS: {
      return {
        ...state,
        loadingDeleteBroadcast: false,
        broadcastSuccessMessage: "broadcast.new.delete.success",
      }
    }

    case BroadcastActions.DELETE_BROADCAST_ERROR: {
      return {
        ...state,
        error: action.payload.message,
        loadingDeleteBroadcast: false,
        broadcastSuccessMessage: null,
      }
    }

    case BroadcastActions.ARCHIVE_BROADCAST_TRIGGER: {
      return {
        ...state,
        loadingArchiveBroadcast: true,
        error: null,
        broadcastSuccessMessage: null,
      }
    }

    case BroadcastActions.ARCHIVE_BROADCAST_SUCCESS: {
      return {
        ...state,
        loadingArchiveBroadcast: false,
        error: null,
        broadcastSuccessMessage: "broadcast.new.archive.success",
      }
    }

    case BroadcastActions.ARCHIVE_BROADCAST_ERROR: {
      return {
        ...state,
        error: action.payload.message,
        loadingArchiveBroadcast: false,
        broadcastSuccessMessage: null,
      }
    }

    case BroadcastActions.DUPLICATE_BROADCAST_TRIGGER: {
      return {
        ...state,
        error: null,
        loadingBroadcasts: true,
      }
    }

    case BroadcastActions.DUPLICATE_BROADCAST_SUCCESS: {
      return {
        ...state,
        error: null,
        broadcast: action.payload,
        audio: action.payload.type === BroadcastTypesEnum.VOICE ? action.payload.content : null,
        loadingBroadcasts: false,
      }
    }

    case BroadcastActions.DUPLICATE_BROADCAST_ERROR: {
      return {
        ...state,
        error: action.payload.message,
        loadingBroadcasts: false,
        broadcast: null,
      }
    }

    case BroadcastActions.FETCH_BROADCAST_TRIGGER: {
      return {
        ...state,
        loadingBroadcast: true,
      }
    }

    case BroadcastActions.FETCH_BROADCAST_SUCCESS: {
      const broadcast = action.payload
      return {
        ...state,
        loadingBroadcast: false,
        broadcast: action.payload,
        audio: broadcast.type === BroadcastTypesEnum.VOICE ? broadcast.content : null,
      }
    }

    case BroadcastActions.FETCH_BROADCAST_ERROR: {
      return {
        ...state,
        error: action.payload,
        loadingBroadcast: false,
      }
    }

    case BroadcastActions.FETCH_BROADCASTS_TRIGGER: {
      return {
        ...state,
        loadingBroadcasts: true,
        // clear previous broadcast, prevent back button from showing previous broadcast
        error: null,
        broadcast: null,
        audio: null,
        broadcastResponseAction: null,
        broadcastSuccessMessage: null,
      }
    }

    case BroadcastActions.FETCH_BROADCASTS_SUCCESS: {
      return {
        ...state,
        loadingBroadcasts: false,
        broadcasts: action.payload.broadcasts,
        totalPages: action.payload.pager.totalPages,
      }
    }

    case BroadcastActions.FETCH_BROADCASTS_ERROR: {
      return {
        ...state,
        error: action.payload,
        loadingBroadcasts: false,
      }
    }

    case BroadcastActions.FETCH_BROADCAST_PROGRAMS_RECIPIENTS_TRIGGER: {
      return {
        ...state,
        loadingBroadcastProgramsRecipients: true,
        broadcastProgramsRecipients: [],
      }
    }

    case BroadcastActions.FETCH_BROADCAST_PROGRAMS_RECIPIENTS_SUCCESS: {
      return {
        ...state,
        loadingBroadcastProgramsRecipients: false,
        broadcastProgramsRecipients: action.payload ?? [],
      }
    }

    case BroadcastActions.FETCH_BROADCAST_PROGRAMS_RECIPIENTS_ERROR: {
      return {
        ...state,
        loadingBroadcastProgramsRecipients: false,
        error: action.payload,
      }
    }

    case BroadcastActions.CLEAR_ERROR: {
      return {
        ...state,
        error: null,
        broadcastResponseAction: null,
        broadcastSuccessMessage: null,
      }
    }

    case BroadcastActions.CLEAR_BROADCAST_TRIGGER: {
      return {
        ...state,
        broadcast: null,
        broadcastResponseAction: null,
        broadcastSuccessMessage: null,
      }
    }

    case BroadcastActions.CLEAR_BROADCAST_MESSAGES_TRIGGER: {
      return {
        ...state,
        broadcastResponseAction: null,
        broadcastSuccessMessage: null,
      }
    }

    case BroadcastActions.UPLOAD_AUDIO_BLOB_TRIGGER: {
      return {
        ...state,
        loadingUploadAudio: true,
        error: null,
      }
    }

    case BroadcastActions.UPLOAD_AUDIO_BLOB_SUCCESS: {
      return {
        ...state,
        loadingUploadAudio: false,
        audio: action.payload,
        broadcast: state.broadcast
          ? {
              ...state.broadcast,
              content: action.payload,
            }
          : null,
        error: null,
      }
    }

    case BroadcastActions.UPLOAD_AUDIO_BLOB_ERROR: {
      return {
        ...state,
        loadingUploadAudio: false,
        error: action.payload.message,
      }
    }

    case BroadcastActions.DELETE_AUDIO_TRIGGER: {
      return {
        ...state,
        loadingDeleteAudio: true,
        error: null,
      }
    }

    case BroadcastActions.DELETE_AUDIO_SUCCESS: {
      return {
        ...state,
        loadingDeleteAudio: false,
        audio: null,
        broadcast: state.broadcast
          ? {
              ...state.broadcast,
              content: "",
            }
          : null,
        error: null,
      }
    }

    case BroadcastActions.DELETE_AUDIO_ERROR: {
      return {
        ...state,
        loadingDeleteAudio: false,
        error: action.payload.message,
      }
    }

    default:
      return state
  }
}
