import React, { Suspense } from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import ViewOrgAdminOnboarding from "../views/app/onboarding"

const ViewPrivate = React.lazy(() => import("./PrivateRouter") as Promise<{ default: React.ComponentType<any> }>)
const ViewPublic = React.lazy(() => import("./PublicRouter") as Promise<{ default: React.ComponentType<any> }>)
const ViewAdminSurveyOld = React.lazy(() => import("../views/public/survey") as Promise<{ default: React.ComponentType<any> }>)
const ViewError = React.lazy(() => import("../views/errorView") as Promise<{ default: React.ComponentType<any> }>)
const ViewNotFoundError = React.lazy(() => import("../views/notFoundView") as Promise<{ default: React.ComponentType<any> }>)
const ViewForbidden = React.lazy(() => import("../views/forbiddenView") as Promise<{ default: React.ComponentType<any> }>)

const Routes: React.FC = () => (
  <Suspense fallback={<div className='loading' />}>
    <BrowserRouter>
      <Switch>
        <Route path={`/app/organizations/:organizationId/onboarding`} render={(props) => <ViewOrgAdminOnboarding {...props} />} />
        <Route path='/app' render={(routeProps) => <ViewPrivate {...routeProps} />} />
        <Route path='/public' render={(routeProps) => <ViewPublic {...routeProps} />} />
        <Route path='/admin/survey/:token' render={(routeProps) => <ViewAdminSurveyOld {...routeProps} />} />
        <Route path='/forbidden' render={(routeProps) => <ViewForbidden {...routeProps} />} />
        <Route path='/unknown-error' render={(routeProps) => <ViewError {...routeProps} />} />
        <Route path='/error' render={(routeProps) => <ViewNotFoundError {...routeProps} />} />
        <Redirect to='/public' />
      </Switch>
    </BrowserRouter>
  </Suspense>
)

export default Routes
