export enum UserProfileNameEnum {
  SUPER_ADMIN = "SUPER_ADMIN",
  ORG_ADMIN = "ORG_ADMIN",
  ORG_USER = "ORG_USER",
  USER = "USER",
  NOT_PROFILE = "NOT_PROFILE",
}

export enum UserProfileTypeEnum {
  NOT_LOGGABLE = "NOT_LOGGABLE",
  LOGGABLE = "LOGGABLE",
}

export const validProfiles: string[] = [UserProfileNameEnum.SUPER_ADMIN, UserProfileNameEnum.ORG_ADMIN, UserProfileNameEnum.ORG_USER, UserProfileNameEnum.USER]
export const validLoginProfiles: string[] = [UserProfileNameEnum.SUPER_ADMIN, UserProfileNameEnum.ORG_ADMIN, UserProfileNameEnum.ORG_USER]

export const outsideAppViews: string[] = ["register", "login", "reset"]

export const tokenWithoutProfileViews: string[] = ["forbidden", "invitation", "register"]

export const userViews: string[] = ["user"]

export const adminViews: string[] = ["app", "user", "dashboard", "detail", "call-logs", "contacts"]
export const orgUserViews: string[] = ["app", "user", "dashboard", "detail", "call-logs", "contacts"]

export const superAdminViews: string[] = ["app", "user", "dashboard", "contacts", "detail", "call-logs", "organizations"]
