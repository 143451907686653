import ActionType from "../../types/ActionType"
import CampaignsActions from "../actions/campaigns"

export enum WelcomeStatus {
  NOT_SENT = 0,
  SENDING,
  SENT,
  ERROR,
}

export interface CampaignsStore {
  errorMessage: string
  sendWelcomeEmailStatus: WelcomeStatus
}

const INITIAL_STATE: CampaignsStore = {
  errorMessage: "",
  sendWelcomeEmailStatus: WelcomeStatus.NOT_SENT,
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case CampaignsActions.SEND_CALLERS_WELCOME_TRIGGER: {
      return {
        ...state,
        sendWelcomeEmailStatus: WelcomeStatus.SENDING,
      }
    }

    case CampaignsActions.SEND_CALLERS_WELCOME_SUCESS: {
      return {
        ...state,
        ids: action.payload,
        sendWelcomeEmailStatus: WelcomeStatus.SENT,
      }
    }

    case CampaignsActions.SEND_CALLERS_WELCOME_ERROR: {
      return {
        ...state,
        errorMessage: action.payload || "An error ocurred while sending the emails",
        sendWelcomeEmailStatus: WelcomeStatus.ERROR,
      }
    }

    case CampaignsActions.CLEAR_CAMPAIGNS: {
      return INITIAL_STATE
    }

    case CampaignsActions.CLEAR_EMAIL_INVITATION_MODAL: {
      return {
        ...state,
        sendWelcomeEmailStatus: WelcomeStatus.NOT_SENT,
      }
    }

    default:
      return state
  }
}
