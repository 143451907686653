import { createIntl, IntlShape } from "react-intl"
import enLang from "./locales/en_US"
import esLang from "./locales/es_ES"

interface LocaleInfo {
  [en: string]: IntlShape
  es: IntlShape
}

const AppLocale: LocaleInfo = {
  en: createIntl({ locale: "en", messages: enLang }),
  es: createIntl({ locale: "es", messages: esLang }),
}

export const getIntlInstance = (): IntlShape => {
  const defaultLang = "en"
  const language = navigator.language.split("-")[0]
  return AppLocale[language] ?? AppLocale[defaultLang]
}

export default AppLocale
