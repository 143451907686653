import { AxiosResponse } from "axios"
import { Dispatch } from "redux"

import API from "../../Api"
import AuthHelper from "../../helpers/AuthHelper"

type ClearCampaigns = () => void
type ClearEmailInvitationModal = () => (disaptch: Dispatch) => void
type SendCallersWelcomeEmail = (ids: string[]) => void

export interface CampaignsActions {
  clearCampaigns: ClearCampaigns
  clearEmailInvitationModal: ClearEmailInvitationModal
  sendCallersWelcomeEmail: SendCallersWelcomeEmail
}

class Campaigns {
  public static CLEAR_CAMPAIGNS = "CLEAR_CAMPAIGNS"
  public static CLEAR_EMAIL_INVITATION_MODAL = "CLEAR_EMAIL_INVITATION_MODAL"

  public static SEND_CALLERS_WELCOME_TRIGGER = "SEND_CALLERS_WELCOME_TRIGGER"
  public static SEND_CALLERS_WELCOME_SUCESS = "SEND_CALLERS_WELCOME_SUCESS"
  public static SEND_CALLERS_WELCOME_ERROR = "SEND_CALLERS_WELCOME_ERROR"

  public static clearCampaigns: ClearCampaigns = () => (dispatch: Dispatch) => {
    dispatch({
      type: Campaigns.CLEAR_CAMPAIGNS,
    })
  }

  public static sendCallersWelcomeEmail: SendCallersWelcomeEmail = (ids) => async (dispatch: Dispatch) => {
    dispatch({
      type: Campaigns.SEND_CALLERS_WELCOME_TRIGGER,
    })

    let res: AxiosResponse

    try {
      res = await API.post("/v2/personorganizations/welcome", { ids }, { headers: AuthHelper.getAdminHeaders() })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: Campaigns.SEND_CALLERS_WELCOME_ERROR,
      })

      return
    }

    dispatch({
      payload: res.data.data,
      type: Campaigns.SEND_CALLERS_WELCOME_SUCESS,
    })
  }

  public static clearEmailInvitationModal: ClearEmailInvitationModal = () => (dispatch: Dispatch) => {
    dispatch({
      type: Campaigns.CLEAR_EMAIL_INVITATION_MODAL,
    })
  }
}

export default Campaigns
