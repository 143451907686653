// tslint:disable: no-any
import React from "react"
import { Button } from "reactstrap"
import styled from "styled-components"
import { mutedGray, primaryPink, red } from "../../assets/colors"
import { device } from "../../utils/device"

const AddButton: React.FC<any> = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin-bottom: 20;
  color: ${mutedGray};
  cursor: pointer;
`

const FormButton: React.FC<any> = styled(Button)`
  margin: 5px;
`

const DetailButtons: React.FC<any> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media ${device.laptop} {
    width: initial;
  }

  .person-match-button {
    border: 1px solid ${primaryPink};
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    margin: 0 2% 0 1%;
    color: ${primaryPink};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin-right: 5px;

    :hover {
      opacity: 0.5;
      transition: 0.2s;
      cursor: pointer;
    }
  }
`

const CornerButton: React.FC<any> = styled.div`
  display: flex;
  justify-content: flex-end;
`

const DownloadButton: React.FC<any> = styled(Button)`
  min-width: 100px;
  height: 100px;
  border-radius: 5px;
  margin: auto;
`

const RemoveButton: React.FC<any> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${red};
  border: 1px solid ${red};
  max-width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 20px;

  :hover {
    opacity: 0.5;
    transition: 0.2s;
    cursor: pointer;
  }
`

const ButtonHeaderContainer: React.FC<any> = styled.div`
  margin: 0 6px;
`

const HeaderFiltersButton: React.FC<any> = styled(Button)`
  :disabled {
    color: ${mutedGray} !important;
    border-color: ${mutedGray} !important;
    background-color: transparent;
  }
`

const ButtonsContainer: React.FC<any> = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export { AddButton, ButtonHeaderContainer, ButtonsContainer, HeaderFiltersButton, CornerButton, DetailButtons, DownloadButton, FormButton, RemoveButton }
