// tslint:disable: object-shorthand-properties-first
import ActionType from "../../types/ActionType"
import Menu from "../actions/menu"

import { defaultMenuType, defaultMenuMobileType, subHiddenBreakpoint, menuHiddenBreakpoint } from "../../constants/defaultValues"

export interface MenuStore {
  containerClassnames: string
  subHiddenBreakpoint: number
  menuHiddenBreakpoint: number
  menuClickCount: number
  selectedMenuHasSubItems: boolean
}

const width: number = window.innerWidth

/*
  if you use menu-sub-hidden as default menu type,
  set value of selectedMenuHasSubItems variable to false
*/
const INITIAL_STATE: MenuStore = {
  containerClassnames: width >= 768 ? defaultMenuType : defaultMenuMobileType,
  menuClickCount: 0,
  menuHiddenBreakpoint,
  selectedMenuHasSubItems: defaultMenuType === "menu-default",
  subHiddenBreakpoint,
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case Menu.MENU_CHANGE_HAS_SUB_ITEM_STATUS:
      return Object.assign({}, state, {
        selectedMenuHasSubItems: action.payload,
      })

    case Menu.MENU_SET_CLASSNAMES:
      return Object.assign({}, state, {
        containerClassnames: action.payload.containerClassnames,
        menuClickCount: action.payload.menuClickCount,
      })

    case Menu.MENU_CLICK_MOBILE_MENU:
      return Object.assign({}, state, {
        containerClassnames: action.payload.containerClassnames,
        menuClickCount: action.payload.menuClickCount,
      })

    case Menu.MENU_CONTAINER_ADD_CLASSNAME:
      return Object.assign({}, state, {
        containerClassnames: action.payload,
      })

    case Menu.MENU_CHANGE_DEFAULT_CLASSES:
      return Object.assign({}, state, {
        containerClassnames: action.payload,
      })

    default:
      return { ...state }
  }
}
