import { AxiosResponse } from "axios"
import { Dispatch } from "redux"

import API from "../../Api"
import AuthHelper from "../../helpers/AuthHelper"

type FetchSurveysPerson = (personOrganizationRoleProgramId: string, page?: number) => (dispatch: Dispatch) => void

export interface SurveyPersonActions {
  fetchSurveysPerson: FetchSurveysPerson
}

class SurveysPerson {
  public static FETCH_SURVEYS_PERSON_TRIGGER = "FETCH_SURVEYS_PERSON_TRIGGER"
  public static FETCH_SURVEYS_PERSON_SUCCESS = "FETCH_SURVEYS_PERSON_SUCCESS"
  public static FETCH_SURVEYS_PERSON_ERROR = "FETCH_SURVEYS_PERSON_ERROR"

  public static fetchSurveysPerson: FetchSurveysPerson = (personOrganizationRoleProgramId, page) => async (dispatch) => {
    let res: AxiosResponse

    dispatch({
      type: SurveysPerson.FETCH_SURVEYS_PERSON_TRIGGER,
    })

    try {
      res = await API.get("/v2/callerSurveys/recipientanswers", {
        headers: AuthHelper.getAdminHeaders(),
        params: {
          personOrganizationRoleProgramId: personOrganizationRoleProgramId || "",
          page: page || 0,
        },
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: SurveysPerson.FETCH_SURVEYS_PERSON_ERROR,
      })
      return
    }

    dispatch({
      payload: res.data.data,
      type: SurveysPerson.FETCH_SURVEYS_PERSON_SUCCESS,
    })
  }
}

export default SurveysPerson
