// tslint:disable:typedef no-any
import * as Yup from "yup"
import { Dispatch, SetStateAction } from "react"
import PasswordUtils from "../utils/PasswordUtils"

export interface OnBoardingNewPassword {
  newPassword: string
  confirmPassword: string
}

interface ValidationItems {
  fullName: string
  userName: string
  setPasswordConfirmed: Dispatch<SetStateAction<boolean>>
}

const OnBoardingNewPasswordSchema: Yup.ObjectSchema<OnBoardingNewPassword> = Yup.object().shape({
  newPassword: Yup.string()
    .required("login.password-required")
    .max(128, "user.new-password-max")
    .test("nonPrintingCharacters", "my-account.password-requirement.invalid-characters", (value) => PasswordUtils.checkNonPrintingCharacters(value))
    .matches(/[a-z]/, "person.error-password-lowercase")
    .matches(/[A-Z]/, "person.error-password-uppercase")
    .matches(/[0-9]/, "person.error-password-digit")
    .matches(/[!@#$%^&*()_+/.,\\[\]{}]/, "person.error-password-special-symbol")
    .min(12, "person.error-password-min"),
  confirmPassword: Yup.string()
    .required("login.password-required")
    .test("passwordMustMatch", "my-account.password-requirement.confirm-password-must-match", function (value: string) {
      const parent: OnBoardingNewPassword = this.parent
      const context: any = this.options.context
      const validationItems: ValidationItems = context.context
      const confirmStatus: boolean = !!parent.newPassword && !!value && value === parent.newPassword
      validationItems.setPasswordConfirmed(confirmStatus)
      return confirmStatus
    }),
})

export default OnBoardingNewPasswordSchema
