import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { ConnectedRouter } from "connected-react-router"
import { QueryClientProvider } from "@tanstack/react-query"
import { Provider } from "react-redux"
import { pdfjs } from "react-pdf"

import App from "./App"
import { queryClient } from "./Api"
import * as serviceWorker from "./serviceWorker"
import { configureStore } from "./redux/store"
import { history } from "./redux/reducers"
// do not reorder these imports
import "react-circular-progressbar/dist/styles.css"
import "react-perfect-scrollbar/dist/css/styles.css"
import "react-big-calendar/lib/css/react-big-calendar.css"
import "react-table/react-table.css"
import "react-toastify/dist/ReactToastify.css"
import "./assets/css/vendor/bootstrap.min.css"
import "./assets/css/sass/themes/gogo.light.scss"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={configureStore({})}>
      <Suspense fallback={<div className='loading' />}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Suspense>
    </Provider>
  </QueryClientProvider>,

  document.getElementById("root"),
)

serviceWorker.unregister()
