import { AxiosResponse } from "axios"
import { Dispatch } from "redux"

import { pickBy } from "lodash"
import API from "../../Api"
import AuthHelper from "../../helpers/AuthHelper"
import { CreateNotePerson as CreatePersonNoteParams, NotesPersonFilter } from "../../types/NotePersonType"
import Modals from "./modals"

type ClearNotesPerson = () => (dispatch: Dispatch) => void
type CreateNotePerson = (values: CreatePersonNoteParams) => (dispatch: Dispatch) => void
type FetchNotesPerson = (personOrganizationRoleProgramId: string, page?: number) => (dispatch: Dispatch) => void
type FetchAllNotesPerson = (filter: NotesPersonFilter) => (dispatch: Dispatch) => void
type DownloadNotesPerson = (filter: NotesPersonFilter) => (dispatch: Dispatch) => void
type ClearDownloadNotesPerson = () => (dispatch: Dispatch) => void

export interface NotesPersonActions {
  clearNotesPerson: ClearNotesPerson
  createNotePerson: CreateNotePerson
  fetchNotesPerson: FetchNotesPerson
  fetchAllNotesPerson: FetchAllNotesPerson
  downloadNotesPerson: DownloadNotesPerson
  clearDownloadNotesPerson: ClearDownloadNotesPerson
}

class NotesPerson {
  public static FETCH_NOTES_PERSON_TRIGGER = "FETCH_NOTES_PERSON_TRIGGER"
  public static FETCH_NOTES_PERSON_SUCCESS = "FETCH_NOTES_PERSON_SUCCESS"
  public static FETCH_NOTES_PERSON_ERROR = "FETCH_NOTES_PERSON_ERROR"

  public static FETCH_ALL_NOTES_PERSON_TRIGGER = "FETCH_ALL_NOTES_PERSON_TRIGGER"
  public static FETCH_ALL_NOTES_PERSON_SUCCESS = "FETCH_ALL_NOTES_PERSON_SUCCESS"
  public static FETCH_ALL_NOTES_PERSON_ERROR = "FETCH_ALL_NOTES_PERSON_ERROR"

  public static DOWNLOAD_ALL_NOTES_PERSON_TRIGGER = "DOWNLOAD_ALL_NOTES_PERSON_TRIGGER"
  public static DOWNLOAD_ALL_NOTES_PERSON_SUCCESS = "DOWNLOAD_ALL_NOTES_PERSON_SUCCESS"
  public static DOWNLOAD_ALL_NOTES_PERSON_ERROR = "DOWNLOAD_ALL_NOTES_PERSON_ERROR"
  public static CLEAR_DOWNLOAD_ALL_NOTES_PERSON = "CLEAR_DOWNLOAD_ALL_NOTES_PERSON"

  public static CREATE_NOTES_PERSON_TRIGGER = "CREATE_NOTES_PERSON_TRIGGER"
  public static CREATE_NOTES_PERSON_SUCCESS = "CREATE_NOTES_PERSON_SUCCESS"
  public static CREATE_NOTES_PERSON_ERROR = "CREATE_NOTES_PERSON_ERROR"

  public static CLEAR_NOTES_PERSON = "CLEAR_NOTES_PERSON"

  public static fetchNotesPerson: FetchNotesPerson = (personOrganizationRoleProgramId, page) => async (dispatch) => {
    try {
      dispatch({
        type: NotesPerson.FETCH_NOTES_PERSON_TRIGGER,
      })
      const res = await API.get(`/v2/personorganizations/${personOrganizationRoleProgramId}/notes`, {
        headers: AuthHelper.getAdminHeaders(),
        params: {
          page: page || 0,
        },
      })
      dispatch({
        payload: res.data.data,
        type: NotesPerson.FETCH_NOTES_PERSON_SUCCESS,
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: NotesPerson.FETCH_NOTES_PERSON_ERROR,
      })
      return
    }
  }

  public static fetchAllNotesPerson: FetchAllNotesPerson =
    ({ dateFrom, dateTo, from, note, organizationId, programId, page, to }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: NotesPerson.FETCH_ALL_NOTES_PERSON_TRIGGER,
        })
        const endpoint = organizationId ? `/v1/organizations/${organizationId}/notes` : "/v2/notesperson"
        const params: any = {
          page: page ?? 1,
          fromDate: dateFrom,
          toDate: dateTo,
          fromPerson: from,
          toPerson: to,
          toPersonProgramId: programId,
          note,
        }
        const cleanedParams = pickBy(params, (v) => v !== undefined && v !== "")
        const res = await API.get(endpoint, {
          headers: AuthHelper.getAdminHeaders(),
          params: cleanedParams,
        })

        dispatch({
          payload: res.data.data,
          type: NotesPerson.FETCH_ALL_NOTES_PERSON_SUCCESS,
        })
      } catch (err) {
        dispatch({
          payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
          type: NotesPerson.FETCH_ALL_NOTES_PERSON_ERROR,
        })
      }
    }

  public static downloadNotesPerson: DownloadNotesPerson =
    ({ dateFrom, dateTo, from, note, organizationId, to }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: NotesPerson.DOWNLOAD_ALL_NOTES_PERSON_TRIGGER,
        })
        const endpoint = organizationId ? `/v1/organizations/${organizationId}/notes/csv` : "/v2/notesperson/csv"
        const params: any = {
          fromDate: dateFrom,
          toDate: dateTo,
          fromPerson: from,
          toPerson: to,
          note,
        }
        const cleanedParams = pickBy(params, (v) => v !== undefined && v !== "")
        const res = await API.get(endpoint, {
          headers: AuthHelper.getAdminHeaders(),
          params: cleanedParams,
        })

        dispatch({
          payload: res.data,
          type: NotesPerson.DOWNLOAD_ALL_NOTES_PERSON_SUCCESS,
        })
      } catch (err) {
        dispatch({
          payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
          type: NotesPerson.DOWNLOAD_ALL_NOTES_PERSON_ERROR,
        })
      }
    }

  public static createNotePerson: CreateNotePerson = (values) => async (dispatch) => {
    let res: AxiosResponse

    dispatch({
      type: NotesPerson.CREATE_NOTES_PERSON_TRIGGER,
    })

    Modals.createModalTrigger(dispatch)

    try {
      res = await API.post("/v2/notesperson", values, { headers: AuthHelper.getAdminHeaders() })

      if (res.data.data) {
        Modals.createModalSuccess(dispatch)
        dispatch({
          payload: res.data.data.notesPerson,
          type: NotesPerson.CREATE_NOTES_PERSON_SUCCESS,
        })
      }
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: NotesPerson.CREATE_NOTES_PERSON_ERROR,
      })

      Modals.createModalError(dispatch, err.response?.data?.message)
      return
    }
  }

  public static clearDownloadNotesPerson: ClearNotesPerson = () => (dispatch: Dispatch) => {
    dispatch({
      type: NotesPerson.CLEAR_DOWNLOAD_ALL_NOTES_PERSON,
    })
  }

  public static clearNotesPerson: ClearNotesPerson = () => (dispatch: Dispatch) => {
    dispatch({
      type: NotesPerson.CLEAR_NOTES_PERSON,
    })
  }
}

export default NotesPerson
