// tslint:disable: no-any
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react"
import withSizes from "react-sizes"
import { compose } from "redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { Col, Input, Row } from "reactstrap"

import CsvDownload from "../../../components/common/Csv"
import CsvResults from "../../../components/common/CsvResults"
import Icon from "../../../components/common/Icon"
import IntlMessages from "../../../helpers/IntlMessages"
import OnBoardingNewPasswordForm, { ActionWrapperType } from "./OnBoardingNewPasswordForm"
import TosScrollBox from "../../../components/common/TosScrollBox"
import AuthHelper, { UserAuth } from "../../../helpers/AuthHelper"
import withAuth, { AuthProps } from "../../../redux/hocs/withAuth"
import withOrganization, { OrganizationProps } from "../../../redux/hocs/withOrganizations"
import { BooleanHook, FileHook, NumberHook } from "../../../types/HooksType"
import { CreatePasswordStatus } from "../../../redux/reducers/authReducer"
import { CsvStatus } from "../../../redux/reducers/organizationsReducer"
import { getOrganizationDetailUrl } from "../../../helpers/NavigationHelper"
import { greenSuccess, redError } from "../../../assets/colors"
import { Separator } from "../../../components/common/CustomBootstrap"
import { CustomTooltip, fileNameStyle, ProgressBar, ProgressBarContainer, ProgressCol, StyledButton } from "../../../components/styles/Onboarding"
import { mapSizesToProps } from "../../../utils/device"

type HandleChecked = (checked: boolean) => () => void
type HandleHelpTooltip = () => void
type HandleInputValue = (confirm?: boolean) => (e: React.ChangeEvent<any>) => void
type HandleStep = () => void
type Props = AuthProps & OrganizationProps & RouteComponentProps & { isMobile: boolean }
type RenderStep = (step: number) => React.ReactNode
type ValidationActionHook = [ValidationActionType | undefined, Dispatch<SetStateAction<ValidationActionType | undefined>>]
type ValidationActionType = () => () => any

const Onboarding: React.FC<Props> = ({ createPassword, csvResults, csvStatus, csvErrorMessage, createPasswordStatus, history, isMobile, uploadCSV, updateSignup, updateTosAccepted }) => {
  const { organizationId } = AuthHelper.getUserAuth()
  const [currentStep, setCurrentStep]: NumberHook = useState<number>(1)
  const [csvFile, setCsvFile]: FileHook = useState(null)
  const [helpTooltipOpen, setHelpTooltipOpen]: BooleanHook = useState<boolean>(false)
  const [passwordConfirmed, setPasswordConfirmed]: BooleanHook = useState<boolean>(false)
  const [previousError, setPreviousError]: BooleanHook = useState<boolean>(false)
  const [checkedTos, setCheckedTos]: BooleanHook = useState<boolean>(false)
  const [validationAction, setValidationAction]: ValidationActionHook = useState<ValidationActionType>()

  useEffect(() => {
    if (createPasswordStatus === CreatePasswordStatus.ERROR) {
      setPreviousError(true)
    }
  }, [createPasswordStatus])

  if (!organizationId) {
    AuthHelper.clearStorage()
    return (
      <Redirect
        to={{
          pathname: "/public/login",
          state: { from: location.pathname },
        }}
      />
    )
  }

  const setValidationActionWrapper: ActionWrapperType = (action) => {
    if (!validationAction) {
      setValidationAction(() => () => action())
    }
  }

  const stepForward: HandleStep = () => {
    const userAuth: UserAuth = AuthHelper.getUserAuth()

    if (currentStep === 1 && createPasswordStatus !== CreatePasswordStatus.SUCCESS) {
      const { userId }: UserAuth = AuthHelper.getUserAuth()
      if (validationAction) {
        validationAction()
      }
      userId && updateTosAccepted(userId, true)
    }

    if (currentStep === 3) {
      updateSignup()

      if (csvFile) uploadCSV(csvFile, userAuth.organizationId || "")

      setCurrentStep(currentStep + 1)
    }

    if (currentStep < 4 && createPasswordStatus === CreatePasswordStatus.SUCCESS) {
      setCurrentStep(currentStep + 1)
    }

    if (currentStep === 4) {
      const { organizationId }: UserAuth = AuthHelper.getUserAuth()
      history.push(getOrganizationDetailUrl(organizationId || ""))
    }
  }

  const stepBackward: HandleStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1)
    if (currentStep === 3) setCsvFile(null)
  }

  const handleInputFile: HandleInputValue = () => () => {
    document.getElementById("hiddenFileInput")?.click()
  }

  const uploadFile: HandleInputValue = () => (event) => {
    event.preventDefault()
    const file: any = event.target.files[0]
    setCsvFile(file)
  }

  const stepOne: () => React.ReactNode = () => {
    const handleHelpTooltip: HandleHelpTooltip = () => setHelpTooltipOpen(!helpTooltipOpen)

    const handleCheckedTos: HandleChecked = () => () => setCheckedTos(!checkedTos)

    const changePasswordTitle: React.ReactNode = (
      <Fragment>
        <div className='callhub-title' data-testid='onboarding-step-one-title-invitation'>
          <IntlMessages id='onboarding.invitation' />
        </div>
        <h6 data-testid='onboarding-step-one-title-create-new-password'>
          <IntlMessages id='onboarding.create-new-password' />
        </h6>
      </Fragment>
    )

    const changePasswordErrorTitle: React.ReactNode = (
      <div className='callhub-title' data-testid='onboarding-step-one-title-create-new-password-error'>
        <IntlMessages id='onboarding.password-error' />
      </div>
    )

    if (createPasswordStatus === CreatePasswordStatus.SUCCESS) {
      return (
        <Fragment>
          <Col xs='9' className='first-step'>
            <div className='callhub-title' data-testid='onboarding-step-one-title-create-new-password-success'>
              <IntlMessages id='onboarding.password-success' />
            </div>
          </Col>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <Col xs='9' className='first-step' data-testid='onboarding-step-one-col-1'>
          {createPasswordStatus !== CreatePasswordStatus.ERROR && !previousError ? changePasswordTitle : changePasswordErrorTitle}
        </Col>

        <Col xs='6' className='first-step' data-testid='onboarding-step-one-col-2'>
          <OnBoardingNewPasswordForm setValidationAction={setValidationActionWrapper} setPasswordConfirmed={setPasswordConfirmed} createAdminPassword={createPassword} />
        </Col>

        <Col xs='9' className='first-step'>
          <Separator className='mb-3 mt-3' />
        </Col>

        <div className='callhub-subtitle' data-testid='onboarding-step-one-subtitle-holder'>
          <IntlMessages id='onboarding.terms-of-service' />
          <CustomTooltip toggle={handleHelpTooltip} target='help-tos-tooltip' isOpen={helpTooltipOpen} placement={isMobile ? "bottom" : "right"} data-testid='onboarding-subtitle-tooltip'>
            <IntlMessages id='tooltip-help-terms-of-service' />
          </CustomTooltip>
          <div id='help-tos-tooltip' style={{ marginLeft: "5px" }}>
            <Icon icon='simple-icon-question' size='20' />
          </div>
        </div>

        <TosScrollBox handleCheckedTos={handleCheckedTos} isMobile={isMobile} />
      </Fragment>
    )
  }

  const stepTwo: () => React.ReactNode = () => (
    <Col xs='9' className='second-step' data-testid='onboarding-step-two-holder'>
      <h6 data-testid='onboarding-step-two-title'>
        <IntlMessages id='onboarding.watch-the-video' />
      </h6>
      <iframe
        title='video-tutorial'
        width='80%'
        height='300px'
        frameBorder={0}
        src={window._env_.REACT_APP_CSV_VIDEO_URL}
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        data-testid='onboarding-step-two-iframe'
      />
      <div className='download-button-container' data-testid='onboarding-step-two-download-button-container'>
        <CsvDownload />
        <div className='csv-instructions' data-testid='onboarding-step-two-csv-instructions'>
          <h6 style={{ textAlign: "center" }} data-testid='onboarding-step-two-csv-instructions-title'>
            <IntlMessages id='organization.csv-instructions' />
            <a href='http://joincallhub.com/faq' target='_blank' rel='noreferrer noopener' data-testid='onboarding-step-two-faq-link'>
              <b className='csv-instructions-link'>
                <IntlMessages id='organization.csv-instructions-here' />
              </b>
            </a>
          </h6>
        </div>
      </div>
    </Col>
  )

  const stepThree: () => React.ReactNode = () => {
    const selectFileText: React.ReactNode = csvFile ? <IntlMessages id='onboarding.file-selected' /> : <IntlMessages id='onboarding.select-file-alternative' />

    return (
      <Col xs='9' className='third-step' data-testid='onboarding-step-three-holder'>
        <h6 data-testid='onboarding-step-three-file-selected-title'>{selectFileText}</h6>
        <div className='upload-container'>
          <StyledButton className='upload-file-button' data-testid='onboarding-step-three-upload-file-button' disabled={csvFile ? true : false} onClick={handleInputFile()} color='primary'>
            <Icon icon={csvFile ? "iconsminds-file" : "simple-icon-paper-clip"} color='white' size='30' />
            {!csvFile && <IntlMessages id='onboarding.select-file' />}
          </StyledButton>
          <Input id='hiddenFileInput' style={{ display: "none" }} type='file' onChange={uploadFile()} data-testid='onboarding-step-three-upload-file-input' />
          <h6 data-testid='onboarding-step-three-file-name-title' style={fileNameStyle}>
            {csvFile ? csvFile.name : null}
          </h6>
        </div>
      </Col>
    )
  }

  const stepFour: () => React.ReactNode = () => {
    const successMessage: string = csvResults ? `Successfully processed ${csvResults.success} of ${csvResults.totalProcessed}` : ""
    const failedMessage: string = csvResults ? `${csvResults.failed?.length || 0} row(s) failed to be processed` : ""
    const icon: string = csvErrorMessage ? "simple-icon-close" : "simple-icon-check"
    const color: string = csvErrorMessage ? redError : greenSuccess

    const noFileComponent: React.ReactNode = (
      <Row>
        <Col xs='9' className='fourth-step'>
          <h1 data-testid='onboarding-step-four-title'>
            <IntlMessages id='onboarding-no-file-selected' />
          </h1>
        </Col>
      </Row>
    )

    const processingComponent: React.ReactNode = (
      <Row>
        <Col xs='9' className='fourth-step'>
          <Icon icon='simple-icon-hourglass' color='gray' size='30' />
          <h1 data-testid='onboarding-step-four-holder-processing-csv'>
            <IntlMessages id='onboarding.processing-csv' />
          </h1>
        </Col>
      </Row>
    )

    const resultsComponent: React.ReactNode = (
      <Row>
        <Col xs='9' className='fourth-step'>
          {csvResults && (
            <h1 data-testid='onboarding-step-four-success-message'>
              <Icon icon={icon} color={color} size='30' />
              {successMessage}
            </h1>
          )}
          <br />

          {csvResults && csvResults.failed.length > 0 && (
            <Fragment>
              <h1 data-testid='onboarding-step-four-failed-message'>
                <Icon icon='simple-icon-close' color={redError} size='30' />
                {failedMessage}
                <br />
              </h1>

              <CsvResults failed={csvResults.failed || []} />
            </Fragment>
          )}
          <br />
        </Col>
      </Row>
    )

    if (!csvFile) return noFileComponent
    if (csvStatus === CsvStatus.PROCESSING) return processingComponent
    return resultsComponent
  }

  const renderStep: RenderStep = (step: number) => {
    switch (step) {
      case 1:
        return stepOne()
      case 2:
        return stepTwo()
      case 3:
        return stepThree()
      case 4:
        return stepFour()
      default:
        return null
    }
  }

  const nextDisabled: boolean = createPasswordStatus !== CreatePasswordStatus.SUCCESS ? createPasswordStatus === CreatePasswordStatus.UPDATING || !passwordConfirmed || !checkedTos : false

  return (
    <Fragment>
      <ProgressCol step={currentStep} xs='12' data-testid='onboarding-progress'>
        <Col xs='9' className='stepper'>
          <ProgressBarContainer>
            <h4 data-testid='onboarding-progress-message'>
              <b>
                <IntlMessages id='onboarding.step' /> {currentStep} of 4
              </b>
            </h4>

            <ProgressBar data-testid='onboarding-progress-bar' max={4} value={currentStep} />
          </ProgressBarContainer>
        </Col>

        {renderStep(currentStep)}

        <Col xs='9' className='step-buttons-container'>
          {currentStep > 1 && (
            <StyledButton color='outline-primary' onClick={stepBackward} data-testid='onboarding-step-go-back'>
              <IntlMessages id='pages.go-back' />
            </StyledButton>
          )}

          {currentStep <= 4 && (
            <StyledButton disabled={nextDisabled} color='primary' onClick={stepForward} data-testid='onboarding-step-go-forward'>
              {currentStep === 4 ? "Finish" : "Next"}
            </StyledButton>
          )}
        </Col>
      </ProgressCol>
    </Fragment>
  )
}

export default compose(withAuth, withOrganization, withSizes(mapSizesToProps))(Onboarding)
