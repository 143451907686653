// tslint:disable: no-any
import ActionType from "../../types/ActionType"
import OrganizationActions, { CreateInitialValuesOrganization } from "../actions/organizations"
import { Organization, OrganizationCustomField } from "../../types/OrganizationType"

export enum StatusModalEdit {
  INCOMPLETE = 0,
  UPDATING,
  SUCCESS,
  ERROR,
}

export interface Failed {
  row: any
  index?: number
  message: string
}

export interface CsvResults {
  failed: Failed[]
  success: number
  totalProcessed: number
  error?: string
}

export enum CsvStatus {
  INCOMPLETE = 0,
  PROCESSING,
  PROCESSED,
  ERROR,
}

export enum StatusFirstStepCreate {
  INCOMPLETE = 0,
  CREATING,
  SUCCESS,
  ERROR,
}

export enum StatusSecondStepCreate {
  INCOMPLETE = 0,
  CREATING,
  SUCCESS,
  ERROR,
}

export interface OrganizationsStore {
  csvErrorMessage: string | null
  csvResults: CsvResults | null
  csvStatus: CsvStatus
  errorCreateMessage: string
  errorEditMessage: string
  errorMessage: string
  loadingOrganization: boolean
  loadingOrganizations: boolean
  organization: Organization | null
  organizations: Organization[]
  organizationToCreate: CreateInitialValuesOrganization | null
  statusModalEdit: StatusModalEdit
  statusFirstStep: StatusFirstStepCreate
  statusSecondStep: StatusSecondStepCreate
  submitButtonFirstStep: boolean
  submitButtonSecondStep: boolean
  disabledButton: boolean
  newOrganizationId: string
  totalPages: number
  totalInactive: number
  totalOutreaches: number
  totalRecipients: number
  totalCallers: number
  totalUsers: number | null
  customFields: OrganizationCustomField[]
}

const INITIAL_STATE: OrganizationsStore = {
  csvErrorMessage: null,
  csvResults: null,
  csvStatus: CsvStatus.INCOMPLETE,
  errorCreateMessage: "",
  errorEditMessage: "",
  errorMessage: "",
  loadingOrganization: false,
  loadingOrganizations: false,
  organization: null,
  organizations: [],
  organizationToCreate: null,
  statusModalEdit: StatusModalEdit.INCOMPLETE,
  statusFirstStep: StatusFirstStepCreate.INCOMPLETE,
  statusSecondStep: StatusSecondStepCreate.INCOMPLETE,
  submitButtonFirstStep: false,
  submitButtonSecondStep: false,
  disabledButton: true,
  newOrganizationId: "",
  totalPages: 1,
  totalInactive: 0,
  totalOutreaches: 0,
  totalRecipients: 0,
  totalCallers: 0,
  totalUsers: null,
  customFields: [],
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case OrganizationActions.FETCH_ORGANIZATIONS_TRIGGER: {
      return {
        ...state,
        loadingOrganizations: true,
      }
    }

    case OrganizationActions.FETCH_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        loadingOrganizations: false,
        organizations: action.payload.organizations,
        totalPages: action.payload.pager.totalPages,
      }
    }

    case OrganizationActions.FETCH_ORGANIZATIONS_ERROR: {
      return {
        ...state,
        error: action.payload,
        loadingOrganizations: false,
      }
    }

    case OrganizationActions.FETCH_ORGANIZATION_TRIGGER: {
      return {
        ...state,
        loadingOrganization: true,
      }
    }

    case OrganizationActions.FETCH_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        loadingOrganization: false,
        organization: action.payload.organization,
        totalInactive: action.payload.totalInactive ?? 0,
        totalOutreaches: action.payload.totalOutreachs ?? 0,
        totalRecipients: action.payload.totalRecipients ?? 0,
        totalCallers: action.payload.totalCallers ?? 0,
        totalUsers: (action.payload.totalOutreachs ?? 0) + (action.payload.totalRecipients ?? 0) + (action.payload.totalCallers ?? 0) + (action.payload.totalInactive ?? 0),
        customFields: action.payload.organization.customFields ?? [],
      }
    }

    case OrganizationActions.FETCH_ORGANIZATION_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        loadingOrganization: false,
      }
    }

    case OrganizationActions.CREATE_ORGANIZATION_TRIGGER: {
      return {
        ...state,
        statusFirstStep: StatusFirstStepCreate.CREATING,
        submitButtonFirstStep: true,
      }
    }

    case OrganizationActions.CREATE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        statusFirstStep: StatusFirstStepCreate.SUCCESS,
        newOrganizationId: action.payload.id,
        statusSubmitButton1: true,
      }
    }

    case OrganizationActions.CREATE_ORGANIZATION_ERROR: {
      return {
        ...state,
        errorCreateMessage: action.payload,
        statusFirstStep: StatusFirstStepCreate.ERROR,
      }
    }

    case OrganizationActions.CREATE_ORGANIZATION_CLEAR: {
      return {
        ...state,
        statusFirstStep: StatusFirstStepCreate.INCOMPLETE,
      }
    }

    case OrganizationActions.CREATE_MANAGER_TRIGGER: {
      return {
        ...state,
        statusSecondStep: StatusSecondStepCreate.CREATING,
        submitButtonSecondStep: true,
      }
    }

    case OrganizationActions.CREATE_MANAGER_SUCCESS: {
      return {
        ...state,
        statusSecondStep: StatusSecondStepCreate.SUCCESS,
        submitButtonSecondStep: true,
      }
    }

    case OrganizationActions.CREATE_MANAGER_ERROR: {
      return {
        ...state,
        errorCreateMessage: action.payload,
        statusSecondStep: StatusSecondStepCreate.ERROR,
      }
    }

    case OrganizationActions.EDIT_ORGANIZATION_TRIGGER: {
      return {
        ...state,
        statusModalEdit: StatusModalEdit.UPDATING,
      }
    }

    case OrganizationActions.EDIT_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        statusModalEdit: StatusModalEdit.SUCCESS,
        organization: action.payload,
      }
    }

    case OrganizationActions.EDIT_ORGANIZATION_ERROR: {
      return {
        ...state,
        errorEditMessage: action.payload,
        statusModalEdit: StatusModalEdit.ERROR,
      }
    }

    case OrganizationActions.SET_ORGANIZATION_TO_CREATE: {
      return {
        ...state,
        organizationToCreate: action.payload,
      }
    }

    case OrganizationActions.UPDATE_ORGANIZATION_SIGNUP: {
      return { ...state }
    }

    case OrganizationActions.UPDATE_ORGANIZATION_USER_COUNTER: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case OrganizationActions.UPLOAD_CSV_SUCCESS: {
      return {
        ...state,
        csvResults: action.payload,
        csvStatus: CsvStatus.PROCESSED,
        totalOutreaches: action.payload.totalOutreachs ?? 0,
        totalRecipients: action.payload.totalRecipients,
        totalCallers: action.payload.totalCallers,
        totalUsers: (action.payload.totalOutreachs ?? 0) + (action.payload.totalRecipients ?? 0) + (action.payload.totalCallers ?? 0) + (action.payload.totalInactive ?? 0),
      }
    }

    case OrganizationActions.UPLOAD_CSV_PROCESSING: {
      return {
        ...state,
        csvStatus: CsvStatus.PROCESSING,
      }
    }

    case OrganizationActions.UPLOAD_CSV_ERROR: {
      return {
        ...state,
        csvErrorMessage: action.payload,
        csvStatus: CsvStatus.ERROR,
      }
    }

    case OrganizationActions.UPLOAD_CSV_CLEAR: {
      return {
        ...state,
        csvStatus: CsvStatus.INCOMPLETE,
      }
    }

    case OrganizationActions.CLEAR_EDIT_ORGANIZATION: {
      return {
        ...state,
        statusModalEdit: StatusModalEdit.INCOMPLETE,
      }
    }

    case OrganizationActions.CLEAR_ORGANIZATION:
      return INITIAL_STATE

    default:
      return state
  }
}
