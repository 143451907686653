import ActionType from "../../types/ActionType"
import { CreateProgramStatus } from "../../types/ProgramsType"
import OrganizationPersonStatusActions from "../actions/organizationPersonStatus"
import { CreateOrganizationPersonStatus, CreateOrganizationPersonStatusStatus, OrganizationPersonStatus } from "../../types/PersonType"

export interface OrganizationPersonStatusesStore {
  errorMessage: string
  createOrganizationPersonStatusesStatus: CreateOrganizationPersonStatusStatus
  loadingCreateOrganizationPersonStatus: boolean
  loadingOrganizationPersonStatus: boolean
  organizationPersonStatus: OrganizationPersonStatus | null
  organizationPersonStatuses: OrganizationPersonStatus[]
  loadingDeleteOrganizationPersonStatus: boolean
  loadingSetDefaultOrganizationPersonStatus: boolean
}

const INITIAL_STATE: OrganizationPersonStatusesStore = {
  errorMessage: "",
  createOrganizationPersonStatusesStatus: CreateOrganizationPersonStatusStatus.PRISTINE,
  loadingCreateOrganizationPersonStatus: false,
  loadingOrganizationPersonStatus: false,
  organizationPersonStatus: null,
  organizationPersonStatuses: [],
  loadingDeleteOrganizationPersonStatus: false,
  loadingSetDefaultOrganizationPersonStatus: false,
}

export default (state = INITIAL_STATE, action: ActionType): OrganizationPersonStatusesStore => {
  switch (action.type) {
    case OrganizationPersonStatusActions.FETCH_ORGANIZATION_PERSON_STATUS_TRIGGER: {
      return {
        ...state,
        loadingOrganizationPersonStatus: true,
      }
    }

    case OrganizationPersonStatusActions.FETCH_ORGANIZATION_PERSON_STATUS_SUCCESS: {
      return {
        ...state,
        loadingOrganizationPersonStatus: false,
        organizationPersonStatuses: action.payload,
      }
    }

    case OrganizationPersonStatusActions.FETCH_ORGANIZATION_PERSON_STATUS_ERROR: {
      return {
        ...state,
        loadingOrganizationPersonStatus: false,
        errorMessage: action.payload,
      }
    }

    case OrganizationPersonStatusActions.CREATE_ORGANIZATION_PERSON_STATUS_TRIGGER: {
      return {
        ...state,
        createOrganizationPersonStatusesStatus: CreateOrganizationPersonStatusStatus.SUBMITTING,
        loadingCreateOrganizationPersonStatus: true,
      }
    }

    case OrganizationPersonStatusActions.CREATE_ORGANIZATION_PERSON_STATUS_SUCCESS: {
      return {
        ...state,
        createOrganizationPersonStatusesStatus: CreateOrganizationPersonStatusStatus.SUCCESS,
        loadingCreateOrganizationPersonStatus: false,
        organizationPersonStatus: action.payload,
      }
    }

    case OrganizationPersonStatusActions.CREATE_ORGANIZATION_PERSON_STATUS_ERROR: {
      return {
        ...state,
        createOrganizationPersonStatusesStatus: CreateOrganizationPersonStatusStatus.ERROR,
        loadingCreateOrganizationPersonStatus: false,
        errorMessage: action.payload,
      }
    }

    case OrganizationPersonStatusActions.DELETE_ORGANIZATION_PERSON_STATUS_TRIGGER: {
      return {
        ...state,
        loadingDeleteOrganizationPersonStatus: true,
      }
    }

    case OrganizationPersonStatusActions.DELETE_ORGANIZATION_PERSON_STATUS_SUCCESS: {
      return {
        ...state,
        loadingDeleteOrganizationPersonStatus: false,
        organizationPersonStatuses: state.organizationPersonStatuses.filter((x) => x.id !== action.payload),
      }
    }

    case OrganizationPersonStatusActions.DELETE_ORGANIZATION_PERSON_STATUS_ERROR: {
      return {
        ...state,
        createOrganizationPersonStatusesStatus: CreateOrganizationPersonStatusStatus.ERROR,
        loadingDeleteOrganizationPersonStatus: false,
        errorMessage: action.payload,
      }
    }

    case OrganizationPersonStatusActions.SET_DEFAULT_ORGANIZATION_PERSON_STATUS_TRIGGER: {
      return {
        ...state,
        loadingSetDefaultOrganizationPersonStatus: true,
      }
    }

    case OrganizationPersonStatusActions.SET_DEFAULT_ORGANIZATION_PERSON_STATUS_SUCCESS: {
      return {
        ...state,
        loadingSetDefaultOrganizationPersonStatus: false,
        organizationPersonStatuses: action.payload,
      }
    }

    case OrganizationPersonStatusActions.SET_DEFAULT_ORGANIZATION_PERSON_STATUS_ERROR: {
      return {
        ...state,
        loadingSetDefaultOrganizationPersonStatus: false,
        errorMessage: action.payload,
      }
    }

    case OrganizationPersonStatusActions.CLEAR_ORGANIZATION_PERSON_STATUS:
      return INITIAL_STATE

    default:
      return state
  }
}
