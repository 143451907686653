// tslint:disable no-any
import { adminViews, orgUserViews, outsideAppViews, UserProfileNameEnum, superAdminViews, tokenWithoutProfileViews, userViews, validProfiles } from "../constants/views"

type GetOrganizationDetailUrl = (id: string) => any
type GetPermission = (profile: string, view: string, token?: string) => boolean
type GetViews = (profile?: string, token?: string) => string[]

export const getOrganizationDetailUrl: GetOrganizationDetailUrl = (id) => ({
  pathname: `/app/organizations/${id}`,
  search: "?tab=recipient",
})

export const getViews: GetViews = (profile, token) => {
  if (token) {
    if (!profile) return tokenWithoutProfileViews
    if (profile && validProfiles.includes(profile)) {
      switch (profile) {
        case UserProfileNameEnum.SUPER_ADMIN:
          return superAdminViews
        case UserProfileNameEnum.ORG_ADMIN:
          return adminViews
        case UserProfileNameEnum.ORG_USER:
          return orgUserViews
        case UserProfileNameEnum.USER:
          return userViews
      }
    }

    if (profile && !validProfiles.includes(profile)) return tokenWithoutProfileViews
  }

  return outsideAppViews
}

export const getPermission: GetPermission = (profile, view, token) => getViews(profile, token).includes(view)

export const openInNewTab = (url: string) => {
  const link: HTMLAnchorElement = document.createElement("a")
  link.href = url
  link.setAttribute("target", "_blank")
  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}
