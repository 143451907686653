import { Dispatch } from "redux"

import API from "../../Api"
import AuthHelper from "../../helpers/AuthHelper"

export interface SurveysActions {
  fetchSurveys: (types: string[]) => (dispatch: Dispatch) => void
  fetchSurveyQuestions: (types: string[]) => (dispatch: Dispatch) => void
  fetchSurveyQuestionsBySurveyType: (type: string, organizationId?: string) => (dispatch: Dispatch) => void
}

class Surveys {
  public static FETCH_SURVEYS_TRIGGER = "FETCH_SURVEYS_TRIGGER"
  public static FETCH_SURVEYS_SUCCESS = "FETCH_SURVEYS_SUCCESS"
  public static FETCH_SURVEYS_ERROR = "FETCH_SURVEYS_ERROR"
  public static FETCH_SURVEYS_QUESTIONS_TRIGGER = "FETCH_SURVEYS_QUESTIONS_TRIGGER"
  public static FETCH_SURVEYS_QUESTIONS_SUCCESS = "FETCH_SURVEYS_QUESTIONS_SUCCESS"
  public static FETCH_SURVEYS_QUESTIONS_ERROR = "FETCH_SURVEYS_QUESTIONS_ERROR"
  public static FETCH_SURVEYS_QUESTIONS_BY_SURVEY_TYPE_TRIGGER = "FETCH_SURVEYS_QUESTIONS_TRIGGER"
  public static FETCH_SURVEYS_QUESTIONS_BY_SURVEY_TYPE_SUCCESS = "FETCH_SURVEYS_QUESTIONS_SUCCESS"
  public static FETCH_SURVEYS_QUESTIONS_BY_SURVEY_TYPE_ERROR = "FETCH_SURVEYS_QUESTIONS_ERROR"

  public static fetchSurveys = (types: string[]) => async (dispatch) => {
    dispatch({
      type: Surveys.FETCH_SURVEYS_TRIGGER,
    })

    try {
      const {
        data: { data },
        status,
      } = await API.get("/v2/surveys", {
        params: {
          types,
        },
        headers: AuthHelper.getAdminHeaders(),
      })

      if (status) {
        dispatch({
          payload: data,
          type: Surveys.FETCH_SURVEYS_SUCCESS,
        })
        return
      }
    } catch (err) {
      dispatch({
        payload: "Oops!... Looks like an error occurred!",
        type: Surveys.FETCH_SURVEYS_ERROR,
      })

      return
    }
  }
  public static fetchSurveyQuestions = (types: string[]) => async (dispatch) => {
    dispatch({
      type: Surveys.FETCH_SURVEYS_QUESTIONS_TRIGGER,
    })

    try {
      const {
        data: { data },
        status,
      } = await API.get("/v2/surveys/questions", {
        params: {
          types,
        },
        headers: AuthHelper.getAdminHeaders(),
      })

      if (status) {
        dispatch({
          payload: data,
          type: Surveys.FETCH_SURVEYS_QUESTIONS_SUCCESS,
        })
        return
      }
    } catch (err) {
      dispatch({
        payload: "Oops!... Looks like an error occurred!",
        type: Surveys.FETCH_SURVEYS_QUESTIONS_ERROR,
      })

      return
    }
  }
  public static fetchSurveyQuestionsBySurveyType = (type: string, organizationId?: string) => async (dispatch) => {
    dispatch({
      type: Surveys.FETCH_SURVEYS_QUESTIONS_BY_SURVEY_TYPE_TRIGGER,
    })

    try {
      const {
        data: { data },
        status,
      } = await API.get(`/v2/surveys/${type}/questions`, {
        headers: AuthHelper.getAdminHeaders(),
        ...(organizationId
          ? {
              params: {
                organizationId,
              },
            }
          : {}),
      })

      if (status) {
        dispatch({
          payload: data,
          type: Surveys.FETCH_SURVEYS_QUESTIONS_BY_SURVEY_TYPE_SUCCESS,
        })
        return
      }
    } catch (err) {
      dispatch({
        payload: "Oops!... Looks like an error occurred!",
        type: Surveys.FETCH_SURVEYS_QUESTIONS_BY_SURVEY_TYPE_ERROR,
      })

      return
    }
  }
}

export default Surveys
