import { AxiosResponse } from "axios"
import { Dispatch } from "redux"

import API from "../../Api"

type FetchOrganizationLegalInfo = (shortName: string) => (dispatch: Dispatch) => void

export interface OrganizationExternalInfosActions {
  fetchOrganizationLegalInfo: FetchOrganizationLegalInfo
}

class OrganizationExternalInfos {
  public static FETCH_ORGANIZATION_EXTERNAL_INFO_TRIGGER = "FETCH_ORGANIZATION_EXTERNAL_INFO_TRIGGER"
  public static FETCH_ORGANIZATION_EXTERNAL_INFO_SUCCESS = "FETCH_ORGANIZATION_EXTERNAL_INFO_SUCCESS"
  public static FETCH_ORGANIZATION_EXTERNAL_INFO_ERROR = "FETCH_ORGANIZATION_EXTERNAL_INFO_ERROR"

  public static fetchOrganizationLegalInfo: FetchOrganizationLegalInfo = (shortName) => async (dispatch) => {
    let res: AxiosResponse

    dispatch({
      type: OrganizationExternalInfos.FETCH_ORGANIZATION_EXTERNAL_INFO_TRIGGER,
    })
    try {
      res = await API.get(`/v2/public/organizations/${shortName}`)

      dispatch({
        payload: res.data.data,
        type: OrganizationExternalInfos.FETCH_ORGANIZATION_EXTERNAL_INFO_SUCCESS,
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: OrganizationExternalInfos.FETCH_ORGANIZATION_EXTERNAL_INFO_ERROR,
      })
    }
  }
}

export default OrganizationExternalInfos
