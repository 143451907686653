// tslint:disable: no-any
import React from "react"
import DatePicker from "react-datepicker"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import styled from "styled-components"
import { FormGroup, Input, InputProps } from "reactstrap"

import { device } from "../../utils/device"
import { baseColor, baseLightBlue, blackOpacityThirty, primaryBlue, semiMutedGray, white } from "../../assets/colors"

import "react-datepicker/dist/react-datepicker.css"
import "../../assets/css/sass/datepicker.style.scss"
import FormSelectField from "../common/FormSelectField"

const FormLabel: React.FC<any> = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-top: 10px;
  overflow: hidden;
  max-height: 19px;
`

const FieldError: React.FC = styled.div`
  color: red;
`

const LoginErrorMessage: React.FC = styled.div`
  min-width: 70px;
  color: red;
  font-weight: bold;
  padding-top: 10px;
  width: 100%;
  text-align: center;

  @media ${device.mobileL} {
    min-width: 70px;
    color: red;
    font-weight: bold;
    padding: 10px;
    width: 100%;
    text-align: right;
  }
`

const StyledPhoneInput: React.FC = styled.div`
  && {
    width: 100%;
    padding: 15px;
    margin-top: 1rem;
    background: ${white};
    border-radius: 2px;
    -webkit-box-shadow: 0px 13px 27px -10px ${blackOpacityThirty} !important;
    -moz-box-shadow: 0px 13px 27px -10px ${blackOpacityThirty} !important;
    box-shadow: 0px 13px 27px -10px ${blackOpacityThirty} !important;

    .form-control {
      height: 20px;
      border-bottom: 1px solid ${semiMutedGray};
      border-top: none;
      border-left: none;
      border-right: none;
      text-align: center;

      :focus {
        outline: none;
        border-bottom: 1px solid ${baseLightBlue};
      }
    }
  }
`

const StyledPhonePreferred: React.FC = styled.div`
  && {
    width: 100%;
    background: transparent !important;
    border-radius: 2px;
    -webkit-box-shadow: 0px 13px 27px -10px ${blackOpacityThirty} !important;
    -moz-box-shadow: 0px 13px 27px -10px ${blackOpacityThirty} !important;
    box-shadow: 0px 13px 27px -10px ${blackOpacityThirty} !important;

    .form-control {
      height: 20px;
      border-bottom: 1px solid ${semiMutedGray};
      border-top: none;
      border-left: none;
      border-right: none;
      text-align: center;

      :focus {
        outline: none;
        border-bottom: 1px solid ${baseLightBlue};
        background: none;
      }
    }
  }
`

const NumberInput: React.FC = styled.div`
  background: ${white};
  width: 55px;
  height: 55px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 10px 20px -10px ${blackOpacityThirty} !important;
  -moz-box-shadow: 0px 10px 20px -10px ${blackOpacityThirty} !important;
  box-shadow: 0px 10px 20px -10px ${blackOpacityThirty} !important;

  input {
    width: 100%;
    height: 100%;
    text-align: center;
    border: 0;
  }
`

const ContainerCheckBox: React.FC<any> = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  align-items: center;
`

const FormCheckBox: React.FC<any> = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 12px;
  align-items: center;
`

const CustomFormGroup: React.FC<any> = styled(FormGroup)`
  height: 90px;
`

const TitleInput: React.FC<{ className?: string }> = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-top: 4px;
`

const TitleInputFilter: React.FC = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-left: 4px;
`

const StyledInput: React.FC<InputProps> = styled(Input)`
  :focus {
    border-color: ${primaryBlue} !important;
  }

  height: 38px;
  background: transparent;
  padding: 0.2rem;
  padding-left: 10px;
`

const ArrowIcon: React.FC<any> = styled.div`
  margin-left: 4px;
`

const SelectContainer: React.FC = styled.div`
  flex-grow: 0.5;
  margin-left: 1rem;
  cursor: pointer;

  .select__control {
    border: 0;
  }

  .select__value-container,
  .select__indicator-separator {
    display: none;
  }

  .select__indicator {
    cursor: pointer;
  }
`

const StyledSelect: React.FC<any> = styled(Select)`
  :focus {
    border-color: ${primaryBlue} !important;
  }
`

const StyledAsyncSelect: React.FC<any> = styled(AsyncSelect)`
  :focus {
    border-color: ${primaryBlue} !important;
  }
`

const StyledDatePicker: React.FC<any> = styled(DatePicker)`
  height: 38px;
  background: transparent;
  padding: 0.2rem;
  padding-left: 10px;
  margin: 5px 0 6px 0;
  min-width: 176px;
`

const InplaceInputLink: React.FC<any> = styled.span`
  color: ${baseColor};

  :hover {
    cursor: pointer;
    color: ${baseColor};
    text-decoration: underline;
  }
`

export {
  ArrowIcon,
  ContainerCheckBox,
  CustomFormGroup,
  FieldError,
  FormCheckBox,
  FormLabel,
  InplaceInputLink,
  LoginErrorMessage,
  NumberInput,
  SelectContainer,
  StyledAsyncSelect,
  StyledDatePicker,
  StyledInput,
  StyledPhoneInput,
  StyledPhonePreferred,
  StyledSelect,
  TitleInput,
  TitleInputFilter,
}
