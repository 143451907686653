import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import { getIntlInstance } from "../lang"

const InjectMassage: React.FC<any> = (props: any) => <FormattedMessage {...props} />

export const getTranslation = (id: string): string => {
  return getIntlInstance().formatMessage({ id })
}

export default injectIntl(InjectMassage)
