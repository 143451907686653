// menu-default, menu-sub-hidden, menu-hidden
export const defaultMenuType = "menu-default"
export const defaultMenuMobileType = "menu-default menu-mobile"
export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768
export const defaultLocale = "en"
export const EXPIRATION_TIME_90_MINUTES_IN_MS: number = 1000 * 60 * 90

interface Option {
  id: string
  name: string
  direction: string
}

export const localeOptions: Option[] = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },
]

export const searchPath = "/app/pages/search"
export const servicePath = "https://api.coloredstrategies.com"
export const isMultiColorActive = true
export const defaultColor = "light.blue"
export const defaultDirection = "ltr"
export const isDarkSwitchActive = true
export const themeColorStorageKey = "__theme_color"
export const themeRadiusStorageKey = "__theme_radius"
export const isDemo = false
