// tslint:disable: no-any object-literal-sort-keys
import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"

import Organization, { OrganizationsActions } from "../actions/organizations"
import { GlobalStore } from "../reducers"
import { OrganizationsStore } from "../reducers/organizationsReducer"

type WithOrganizationType = (ComposedComponent: React.ComponentType<any>) => React.ComponentType<OrganizationsStore & OrganizationsActions & any>

type MapStateToProps = (state: GlobalStore) => OrganizationsStore

const withOrganization: WithOrganizationType = (ComposedComponent: React.ComponentType<any>) => {
  class WithOrganization extends React.Component<any & OrganizationsStore & OrganizationsActions> {
    public render(): React.ReactNode {
      return <ComposedComponent {...this.props} />
    }
  }

  const mapStateToProps: MapStateToProps = ({ organizations }: GlobalStore) => ({
    ...organizations,
  })

  const mapDispatchToProps: OrganizationsActions & { push: any } = {
    push,
    clearCsvStatus: Organization.clearCsvStatus,
    createOrganization: Organization.createOrganization,
    editOrganization: Organization.editOrganization,
    clearEditOrganization: Organization.clearEditOrganization,
    clearOrganization: Organization.clearOrganization,
    fetchOrganization: Organization.fetchOrganization,
    fetchOrganizations: Organization.fetchOrganizations,
    setOrganizationToCreate: Organization.setOrganizationToCreate,
    uploadCSV: Organization.uploadCSV,
    updateSignup: Organization.updateSignup,
    updateUserCounter: Organization.updateUserCounter,
    clearUserCounter: Organization.clearUserCounter,
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithOrganization)
}

export type OrganizationProps = OrganizationsActions & OrganizationsStore & { push: any }
export default withOrganization
