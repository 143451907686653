import { Dispatch } from "redux"

type CreateModalClear = () => (dispatch: Dispatch) => void
type CreateModalError = (dispatch: Dispatch, errorMessage?: string) => void
type SetModalError = (errorMessage?: string) => (dispatch: Dispatch) => void
type CreateModalSuccess = (dispatch: Dispatch) => void
type CreateModalTrigger = (dispatch: Dispatch) => void

export interface ModalActions {
  createModalClear: CreateModalClear
  createModalError: CreateModalError
  createModalSuccess: CreateModalSuccess
  createModalTrigger: CreateModalTrigger
  setModalError: SetModalError
}

class Modals {
  public static CREATE_MODAL_TRIGGER = "CREATE_MODAL_TRIGGER"
  public static CREATE_MODAL_ERROR = "CREATE_MODAL_ERROR"
  public static CREATE_MODAL_SUCCESS = "CREATE_MODAL_SUCCESS"
  public static CREATE_MODAL_RELOAD = "CREATE_MODAL_RELOAD"
  public static CREATE_MODAL_CLEAR = "CREATE_MODAL_CLEAR"

  public static createModalTrigger: CreateModalTrigger = (dispatch: Dispatch) => {
    dispatch({
      type: Modals.CREATE_MODAL_TRIGGER,
    })
  }

  public static createModalSuccess: CreateModalSuccess = (dispatch: Dispatch) => {
    dispatch({
      type: Modals.CREATE_MODAL_SUCCESS,
    })
  }

  public static createModalError: CreateModalError = (dispatch: Dispatch, errorMessage) => {
    dispatch({
      type: Modals.CREATE_MODAL_ERROR,
      payload: errorMessage,
    })
  }

  public static setModalError: SetModalError = (errorMessage) => (dispatch: Dispatch) => {
    dispatch({
      type: Modals.CREATE_MODAL_ERROR,
      payload: errorMessage,
    })
  }

  public static createModalClear: CreateModalClear = () => (dispatch: Dispatch) => {
    dispatch({
      type: Modals.CREATE_MODAL_CLEAR,
    })
  }
}

export default Modals
