import ActionType from "../../types/ActionType"
import SurveyPersonActions from "../actions/surveysPerson"
import { SurveyAnswered } from "../../types/SurveyType"

export interface SurveysPersonStore {
  errorMessage: string
  loadingSurveysPerson: boolean
  surveysPerson: SurveyAnswered[]
  totalPagesSurveysPerson: number
}

const INITIAL_STATE: SurveysPersonStore = {
  errorMessage: "",
  loadingSurveysPerson: true,
  surveysPerson: [],
  totalPagesSurveysPerson: 1,
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case SurveyPersonActions.FETCH_SURVEYS_PERSON_TRIGGER: {
      return {
        ...state,
        loadingSurveysPerson: true,
      }
    }

    case SurveyPersonActions.FETCH_SURVEYS_PERSON_SUCCESS: {
      return {
        ...state,
        loadingSurveysPerson: false,
        totalPagesSurveysPerson: action.payload.pager?.totalPages || 0,
        surveysPerson: action.payload,
      }
    }

    case SurveyPersonActions.FETCH_SURVEYS_PERSON_ERROR: {
      return {
        ...state,
        loadingSurveysPerson: false,
        errorMessage: action.payload,
      }
    }

    default:
      return state
  }
}
