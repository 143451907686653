import { Role } from "../../types/PersonType"
import ActionType from "../../types/ActionType"
import RolesActions from "../actions/roles"

export interface RolesStore {
  roles: Role[]
  errorMessage: string
  isLoading: boolean
}

const INITIAL_STATE: RolesStore = {
  roles: [],
  errorMessage: "",
  isLoading: false,
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case RolesActions.FETCH_ROLES_TRIGGER: {
      return {
        isLoading: true,
      }
    }

    case RolesActions.FETCH_ROLES_SUCCESS: {
      return {
        roles: action.payload,
        isLoading: false,
      }
    }

    case RolesActions.FETCH_ROLES_ERROR: {
      return {
        roles: null,
        errorMessage: action.payload,
        isLoading: false,
      }
    }

    default:
      return { ...state }
  }
}
