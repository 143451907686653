// tslint:disable: object-literal-sort-keys no-any
import { connectRouter, LocationChangeAction, RouterState } from "connected-react-router"
import { createBrowserHistory, History } from "history"
import { combineReducers, Reducer } from "redux"

import Auth from "../actions/auth"
import authUser, { AuthStore } from "./authReducer"
import broadcasts, { BroadcastsStore } from "./broadcastsReducer"
import callAnalytics, { CallAnalyticsStore } from "./callAnalyticsReducer"
import callLogs, { CallLogsStore } from "./callLogsReducer"
import campaigns, { CampaignsStore } from "./campaignsReducer"
import menu, { MenuStore } from "./menuReducer"
import modal, { ModalStore } from "./modalReducer"
import notesPerson, { NotesPersonStore } from "./notesPersonReducer"
import organizationPersonStatuses, { OrganizationPersonStatusesStore } from "./organizationPersonStatusesReducer"
import organizations, { OrganizationsStore } from "./organizationsReducer"
import organizationExternalInfos, { OrganizationExternalInfosStore } from "./organizationExternalInfosReducer"
import outreachQueues, { OutreachQueuesStore } from "./outreachQueuesReducer"
import pager, { PagerStore } from "./pagerReducer"
import personDetails, { PersonDetailsStore } from "./personDetailsReducer"
import personOrganizations, { PersonOrganizationsStore } from "./personOrganizationReducer"
import programs, { ProgramsStore } from "./programsReducer"
import personOrganizationSurveys, { PersonOrganizationSurveysStore } from "./personOrganizationSurveysReducer"
import roles, { RolesStore } from "./rolesReducer"
import settings, { SettingsStore } from "./settingsReducer"
import surveysPerson, { SurveysPersonStore } from "./surveysPersonReducer"
import surveys, { SurveysStore } from "./surveysReducer"
import callerSurveys, { SurveysTypesStore } from "./surveysTypesReducer"

export interface GlobalStore {
  authUser: AuthStore
  broadcasts: BroadcastsStore
  callAnalytics: CallAnalyticsStore
  callLogs: CallLogsStore
  callerSurveys: SurveysTypesStore
  campaigns: CampaignsStore
  menu: MenuStore
  modal: ModalStore
  notesPerson: NotesPersonStore
  organizationExternalInfos: OrganizationExternalInfosStore
  organizations: OrganizationsStore
  pager: PagerStore
  personDetails: PersonDetailsStore
  personOrganizations: PersonOrganizationsStore
  personOrganizationSurveys: PersonOrganizationSurveysStore
  programs: ProgramsStore
  roles: RolesStore
  router: Reducer<RouterState, LocationChangeAction>
  settings: SettingsStore
  surveysPerson: SurveysPersonStore
  surveys: SurveysStore
  organizationPersonStatuses: OrganizationPersonStatusesStore
  outreachQueues: OutreachQueuesStore
}

export const history: History = createBrowserHistory()

const appReducer: Reducer<any, any> = combineReducers({
  authUser,
  broadcasts,
  callAnalytics,
  callLogs,
  callerSurveys,
  campaigns,
  menu,
  modal,
  notesPerson,
  surveysPerson,
  organizations,
  organizationExternalInfos,
  pager,
  personOrganizations,
  personOrganizationSurveys,
  programs,
  personDetails,
  roles,
  settings,
  surveys,
  organizationPersonStatuses,
  outreachQueues,
  router: connectRouter(history),
})

const rootReducer: Reducer = (state, action) => {
  if (action.type === Auth.LOGOUT_ADMIN) {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer
