// tslint:disable: no-any
import React, { Dispatch, SetStateAction, useEffect } from "react"
import { Col, Form, FormGroup, Row } from "reactstrap"
import { ErrorMessage, FormikProps, validateYupSchema, withFormik, yupToFormErrors } from "formik"
import { injectIntl, WrappedComponentProps } from "react-intl"

import IntlMessages from "../../../helpers/IntlMessages"
import OnBoardingNewPasswordSchema from "../../../validations/OnBoardingNewPasswordSchema"
import PasswordField from "../../../components/common/PasswordField"
import { FieldError } from "../../../components/styles/Inputs"
import AuthHelper from "../../../helpers/AuthHelper"

export type ActionWrapperType = (action: () => void) => void
interface ActionProps {
  setPasswordConfirmed: Dispatch<SetStateAction<boolean>>
  setValidationAction: ActionWrapperType
  createAdminPassword: (password: string) => void
}
interface NewPassword {
  newPassword: string
  confirmPassword: string
}
type DisplayError = (fieldname: string) => React.ReactNode
type FormWrapperProps = FormikProps<NewPassword> & WrappedComponentProps & ActionProps

const OnBoardingNewPasswordFrom: React.FC<FormWrapperProps> = ({ handleSubmit, intl }) => {
  const setPlaceholder: (placeholderId: string) => string = (placeholderId) => intl.formatMessage({ id: placeholderId })

  const displayError: DisplayError = (fieldName) => (
    <FieldError>
      <ErrorMessage name={fieldName} component='div' data-testid={`onboarding-new-password-error-${fieldName}`}>
        {(message) => <IntlMessages id={message} />}
      </ErrorMessage>
    </FieldError>
  )

  return (
    <Form onSubmit={handleSubmit} data-testid='onboarding-new-password-form'>
      <Row>
        <Col xs='12'>
          <FormGroup>
            <PasswordField data-testid='onboarding-new-password-input-password' name='newPassword' isInFormik={true} placeholder={setPlaceholder("user.new-password")} />
            {displayError("newPassword")}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          <FormGroup>
            <PasswordField name='confirmPassword' data-testid='onboarding-new-password-input-confirm' isInFormik={true} placeholder={setPlaceholder("user.confirm-password")} />
            {displayError("confirmPassword")}
          </FormGroup>
        </Col>
      </Row>
    </Form>
  )
}

const NewPasswordFormWrapper: React.FC<FormWrapperProps> = ({ setValidationAction, submitForm, ...props }) => {
  useEffect(() => {
    setValidationAction(submitForm)
  })

  return <OnBoardingNewPasswordFrom submitForm={submitForm} setValidationAction={setValidationAction} {...props} />
}

const NewPasswordEnhancedForm: any = withFormik<ActionProps, NewPassword>({
  mapPropsToValues: () => ({ newPassword: "", confirmPassword: "" }),
  validate: (values, { setPasswordConfirmed }) => {
    try {
      validateYupSchema<NewPassword>(values, OnBoardingNewPasswordSchema, true, {
        context: {
          setPasswordConfirmed,
          fullName: AuthHelper.getUserAuth().name || "",
          userName: AuthHelper.getUserAuth().userName || "",
        },
      })
    } catch (e) {
      return yupToFormErrors(e)
    }
  },
  handleSubmit: (values, { props }) => {
    props.createAdminPassword(values.newPassword)
  },
})(injectIntl(NewPasswordFormWrapper))

export default NewPasswordEnhancedForm
