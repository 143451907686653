// tslint:disable: no-any
import React from "react"
import styled from "styled-components"

interface Props {
  id?: string | number
  className?: string
  icon: string
  title?: string
  value?: string
  size?: string | number
  color?: string
  style?: any

  onClick?: (event?: MouseEvent) => void
}

const Icon: React.FC<Props> = ({ icon, value, size, color, style, onClick }) => (
  <IconContainer style={{ color, width: size, ...(style ? style : {}) }} className={icon} {...(onClick ? { onClick } : {})}>
    {value}
  </IconContainer>
)

export default Icon

const IconContainer: React.FC<any> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ style }) => (style ? style.width : "25")}px;
  color: ${({ style }) => style && style.color};
`
