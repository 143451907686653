import React from "react"
import styled from "styled-components"
import { Failed } from "../../redux/reducers/organizationsReducer"

import { greySuccess, lightGrey } from "../../assets/colors"

interface Props {
  failed: Failed[]
}

const CsvResults: React.FC<Props> = ({ failed }) => (
  <table data-testid='csv-results-table-holder'>
    <Head>
      <th>Row</th>
      <th>Mesage</th>
    </Head>
    <tbody data-testid='csv-results-table-body'>
      {failed.map(({ index, message }) => (
        <Row key={index}>
          <td>{index}</td>
          <td>{message}</td>
        </Row>
      ))}
    </tbody>
  </table>
)

export default CsvResults

const Head: React.FC = styled.thead`
  border-bottom: 1px solid ${greySuccess};

  th {
    padding: 4px 8px 4px 0;
  }
`

const Row: React.FC = styled.tr`
  border-bottom: 1px solid ${lightGrey};
`
