import { parsePhoneNumber } from "libphonenumber-js"

export default class PhoneUtils {
  public static validatePhone = (value: string): boolean => {
    try {
      return value ? parsePhoneNumber(value, "US").isValid() : true
    } catch (e) {
      return false
    }
  }

  public static parseToInternational = (value: string | null | undefined): string => {
    if (!value) {
      return ""
    }
    const valueToParse: string = value.charAt(0) === "+" ? value : `+${value}`
    return parsePhoneNumber(valueToParse).formatInternational()
  }

  public static formatPhoneNumber = (phoneNumberString: string | number): string => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "")
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3]
    }
    // format this phone number 13105043446 to +1 (310) 504-3446
    const match2 = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)
    if (match2) {
      return `+${match2[1]} (${match2[2]}) ${match2[3]}-${match2[4]}`
    }
    return phoneNumberString.toString()
  }
}
