import { AxiosResponse } from "axios"

import { isEmpty } from "lodash"
import { Dispatch } from "redux"
import API from "../../Api"
import AuthHelper from "../../helpers/AuthHelper"
import { CreateProgram as CreateProgramParams, Program, ProgramRecipientContactType } from "../../types/ProgramsType"
import Modals from "./modals"

type ClearPrograms = () => (dispatch: Dispatch) => void
type CreateProgram = (values: CreateProgramParams) => (dispatch: Dispatch) => void
type DeleteProgram = (id: string) => (dispatch: Dispatch) => void
type FetchPrograms = (organizationId?: string | null) => (dispatch: Dispatch) => void
type FetchProgramsRecipients = (organizationId: string, contactType?: ProgramRecipientContactType) => (dispatch: Dispatch) => void
type SetDefaultProgram = (organization: string, programId: string) => (dispatch: Dispatch) => void

export interface ProgramsActions {
  clearPrograms: ClearPrograms
  createProgram: CreateProgram
  deleteProgram: DeleteProgram
  fetchPrograms: FetchPrograms
  setDefaultProgram: SetDefaultProgram
  fetchProgramsRecipients: FetchProgramsRecipients
}

class Programs {
  public static FETCH_PROGRAMS_TRIGGER = "FETCH_PROGRAMS_TRIGGER"
  public static FETCH_PROGRAMS_SUCCESS = "FETCH_PROGRAMS_SUCCESS"
  public static FETCH_PROGRAMS_ERROR = "FETCH_PROGRAMS_ERROR"

  public static FETCH_PROGRAMS_RECIPIENTS_TRIGGER = "FETCH_PROGRAMS_RECIPIENTS_TRIGGER"
  public static FETCH_PROGRAMS_RECIPIENTS_SUCCESS = "FETCH_PROGRAMS_RECIPIENTS_SUCCESS"
  public static FETCH_PROGRAMS_RECIPIENTS_ERROR = "FETCH_PROGRAMS_RECIPIENTS_ERROR"

  public static CREATE_PROGRAM_TRIGGER = "CREATE_PROGRAM_TRIGGER"
  public static CREATE_PROGRAM_SUCCESS = "CREATE_PROGRAM_SUCCESS"
  public static CREATE_PROGRAM_ERROR = "CREATE_PROGRAM_ERROR"

  public static SET_DEFAULT_PROGRAM_TRIGGER = "SET_DEFAULT_PROGRAM_TRIGGER"
  public static SET_DEFAULT_PROGRAM_SUCCESS = "SET_DEFAULT_PROGRAM_SUCCESS"
  public static SET_DEFAULT_PROGRAM_ERROR = "SET_DEFAULT_PROGRAM_ERROR"

  public static DELETE_PROGRAM_TRIGGER = "DELETE_PROGRAM_TRIGGER"
  public static DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS"
  public static DELETE_PROGRAM_ERROR = "DELETE_PROGRAM_ERROR"

  public static CLEAR_PROGRAMS = "CLEAR_PROGRAMS"

  public static fetchPrograms: FetchPrograms = (organizationId) => async (dispatch) => {
    try {
      dispatch({
        type: Programs.FETCH_PROGRAMS_TRIGGER,
      })
      const res = await API.get("/v2/programs", {
        headers: AuthHelper.getAdminHeaders(),
        params: { organizationId: organizationId ?? "" },
      })
      let programList: Program[] = res.data.data.programs
      if (isEmpty(programList)) {
        programList = [
          {
            id: "",
            name: "No programs were found for this organization",
            phone: "",
            organizationId: organizationId ?? "",
            isDefault: false,
          },
        ]
      }
      dispatch({
        payload: programList,
        type: Programs.FETCH_PROGRAMS_SUCCESS,
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: Programs.FETCH_PROGRAMS_ERROR,
      })
    }
  }

  public static fetchProgramsRecipients: FetchProgramsRecipients = (organizationId, contactType) => async (dispatch) => {
    let res: AxiosResponse

    dispatch({
      type: Programs.FETCH_PROGRAMS_RECIPIENTS_TRIGGER,
    })

    try {
      res = await API.get("/v2/programs/recipients", {
        headers: AuthHelper.getAdminHeaders(),
        params: {
          organizationId,
          contactType,
        },
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: Programs.FETCH_PROGRAMS_RECIPIENTS_ERROR,
      })

      return
    }

    dispatch({
      payload: res.data.data.rows,
      type: Programs.FETCH_PROGRAMS_RECIPIENTS_SUCCESS,
    })
  }

  public static createProgram: CreateProgram = (values) => async (dispatch) => {
    let res: AxiosResponse

    dispatch({
      type: Programs.CREATE_PROGRAM_TRIGGER,
    })

    Modals.createModalTrigger(dispatch)

    try {
      res = await API.post("/v2/programs", values, { headers: AuthHelper.getAdminHeaders() })

      if (res.data.data) {
        Modals.createModalSuccess(dispatch)
        dispatch({
          payload: res.data.data.program,
          type: Programs.CREATE_PROGRAM_SUCCESS,
        })
      }
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: Programs.CREATE_PROGRAM_ERROR,
      })
      Modals.createModalError(dispatch, err.response?.data?.message)

      return
    }
  }

  public static deleteProgram: DeleteProgram = (id) => async (dispatch) => {
    try {
      dispatch({
        type: Programs.DELETE_PROGRAM_TRIGGER,
      })
      const res = await API.delete(`/v2/programs/${id}`, { headers: AuthHelper.getAdminHeaders() })

      if (res.data.data) {
        dispatch({
          payload: id,
          type: Programs.DELETE_PROGRAM_SUCCESS,
        })
      }
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: Programs.DELETE_PROGRAM_ERROR,
      })
      Modals.createModalError(dispatch, err.response?.data?.message ?? "Oops! Looks like an error occurred!")
      return
    }
  }

  public static setDefaultProgram: SetDefaultProgram = (organizationId, programId) => async (dispatch) => {
    try {
      dispatch({
        type: Programs.SET_DEFAULT_PROGRAM_TRIGGER,
      })
      const res = await API.put(`/v1/organizations/${organizationId}/default-programs/${programId}`, {}, { headers: AuthHelper.getAdminHeaders() })

      const programList = res.data.data.programs

      if (res.data.data) {
        dispatch({
          payload: programList,
          type: Programs.SET_DEFAULT_PROGRAM_SUCCESS,
        })
      }
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: Programs.SET_DEFAULT_PROGRAM_ERROR,
      })
      Modals.createModalError(dispatch, err.response?.data?.message ?? "Oops! Looks like an error occurred!")
      return
    }
  }

  public static clearPrograms: ClearPrograms = () => (dispatch: Dispatch) => {
    dispatch({
      type: Programs.CLEAR_PROGRAMS,
    })
  }
}

export default Programs
