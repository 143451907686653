// tslint:disable: no-any
import thunk from "redux-thunk"
import createSagaMiddleware, { SagaMiddleware } from "redux-saga"
// eslint-disable-next-line camelcase
import jwt_decode, { JwtPayload } from "jwt-decode"
import { routerMiddleware } from "connected-react-router"
import { applyMiddleware, compose, createStore, Middleware, Store } from "redux"

import Auth from "./actions/auth"
import createRootReducer, { history } from "./reducers"
import AuthHelper from "../helpers/AuthHelper"

const reduxDevToolsExtensionCompose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

const composeEnhancers: any = (window._env_.NODE_ENV === "development" && reduxDevToolsExtensionCompose ? reduxDevToolsExtensionCompose({ trace: true, traceLimit: 50 }) : null) || compose

const tokenValidatorMiddleware: Middleware = (store) => (next) => (action) => {
  const token: string | null = AuthHelper.getToken()
  if (token) {
    const decoded: JwtPayload = jwt_decode(token)
    if (decoded.exp && decoded.exp < Date.now() / 1000) {
      store.dispatch(Auth.logout(history))
      window.location.reload()
      return
    }
  }
  next(action)
}
const routeMiddleware: Middleware = routerMiddleware(history)
const sagaMiddleware: SagaMiddleware = createSagaMiddleware()
const middlewares: Middleware[] = [routeMiddleware, sagaMiddleware, tokenValidatorMiddleware, thunk]

export function configureStore(initialState: any): Store {
  const store: Store = createStore(createRootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)))

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      const nextRootReducer: any = require("./reducers")
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
