import { Dispatch } from "redux"

import API from "../../Api"
import AuthHelper from "../../helpers/AuthHelper"
import { Role } from "../../types/PersonType"

type FetchRoles = () => (dispatch: Dispatch) => void

export interface RolesActions {
  fetchRoles: FetchRoles
}

class Roles {
  public static FETCH_ROLES_TRIGGER = "FETCH_ROLES_TRIGGER"
  public static FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS"
  public static FETCH_ROLES_ERROR = "FETCH_ROLES_ERROR"

  public static fetchRoles: FetchRoles = () => async (dispatch) => {
    try {
      dispatch({ type: Roles.FETCH_ROLES_TRIGGER })
      const res = await API.get("/v2/roles", { headers: AuthHelper.getAdminHeaders() })

      if (res.data.data) {
        const roles: Role[] = res.data.data.roles.map((role: Role) => role.name)

        AuthHelper.setRoles(JSON.stringify(roles))

        dispatch({
          payload: res.data.data.roles,
          type: Roles.FETCH_ROLES_SUCCESS,
        })
      }

      return
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: Roles.FETCH_ROLES_ERROR,
      })

      return
    }
  }
}

export default Roles
