// tslint:disable no-any
import React from "react"
import dayjs from "dayjs"
import { isEmpty } from "lodash"

type GetDateTime = (date: Date) => React.ReactNode
type GetTime = (time: number) => string

class TextHelper {
  public static resizeText(text = "", maxLength = 40): string {
    if (!text) return ""
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text
  }

  public static getFontSize(minFont: string, maxFont: string, minWidth: string, maxWidth: string): string {
    return `${minFont}px + (${maxFont} - ${minFont}) * ((100vw - ${minWidth}px) / (${maxWidth} - ${minWidth}))`
  }

  public static getDate: GetDateTime = (date) => {
    const customDate: string = dayjs(date).format("MMMM DD, YYYY")

    return (
      <div>
        <strong>{`${customDate} `}</strong>
      </div>
    )
  }

  public static getDateTime: GetDateTime = (date) => {
    const customDate: string = dayjs(date).format("MMMM DD, YYYY")
    const customHour: string = dayjs(date).format("hh:mm A")

    return (
      <div>
        <strong>{`${customDate} `}</strong>
        {customHour}
      </div>
    )
  }

  public static getFormattedTime: GetTime = (timeInSeconds) => {
    const hours: number = Math.floor(timeInSeconds / 3600)
    const minutes: number = Math.floor((timeInSeconds - hours * 3600) / 60)
    const seconds: number = timeInSeconds - hours * 3600 - minutes * 60

    const displayHours: string = hours < 10 ? `0${hours}` : hours.toString()
    const displayMinutes: string = minutes < 10 ? `0${minutes}` : minutes.toString()
    const displaySeconds: string = seconds < 10 ? `0${seconds}` : seconds.toString()

    return `${displayHours}:${displayMinutes}:${displaySeconds}`
  }

  public static capitalize = (text: string) => `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`

  public static onlyLetters = (event: React.ChangeEvent<any>, setFieldValue: any, values: string, name: string) => {
    const regExString = /^[a-zA-Z\s]*$/
    const fieldString: string = event?.currentTarget?.value.match(regExString) ? event.currentTarget.value : values
    setFieldValue(name, fieldString)
  }

  public static getStringDefaultValue = (str?: string | null, defaultValue: string | null = null): string | null | undefined => {
    if (!str) {
      return defaultValue
    }
    if (isEmpty(str.trim())) {
      return defaultValue
    }
    return str.trim()
  }
}

export default TextHelper
