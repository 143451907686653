// tslint:disable: no-any
import { AxiosResponse } from "axios"
import { Dispatch } from "redux"

import API from "../../Api"
import AuthHelper, { UserAuth } from "../../helpers/AuthHelper"
import { AddressType, Organization, OrganizationProgram } from "../../types/OrganizationType"
import { getManagerForBody, getOrganizationForBody } from "../../helpers/FormatHelper"
import { RoleCounterKey, RoleType } from "../../constants/roles"
import { OrganizationsStore } from "../reducers/organizationsReducer"

type UpdateUserCounterParams = {
  roleType: string
  totalOutreaches: number
  totalRecipients: number
  totalCallers: number
  calculateTotalCallBack: (currentValue: number) => number
}
type ClearCsvStatus = () => (dispatch: Dispatch) => void
type ClearEditOrganization = () => void
type ClearOrganization = () => any
type EditOrganization = (id: string, values: InitialValues) => (dispatch: Dispatch) => void
export type FetchOrganizationsSortByColumn = "name" | "createdAt"
interface FetchOrganizationsFilter {
  sortDirection?: "asc" | "desc"
  searchBy?: string
  sortBy?: FetchOrganizationsSortByColumn
  page?: number
  limit?: number
}
type FetchOrganizations = (filter?: FetchOrganizationsFilter) => (dispatch: Dispatch) => void
type FetchOrganization = (id: string) => (dispatch: Dispatch) => void
type UploadCSV = (file: any, organizationId: string) => (dispatch: Dispatch, getState: () => OrganizationsStore) => void
type UpdateUserCounter = (params: UpdateUserCounterParams) => { type: string; payload: { [key: string]: number } }
type UpdateSignup = () => () => void
type SetOrganizationToCreate = (values: CreateInitialValuesOrganization) => (dispatch: Dispatch) => void
type CreateOrganization = (values: CreateInitialValuesOrganization, manager: CreateInitialValuesManager) => (dispatch: Dispatch) => void

export interface InitialValues {
  externalOrgId?: string | null
  isExternal?: boolean
  active: boolean
  address?: AddressType
  contactPhone?: string | null
  email: string
  id: string
  name: string
  primaryContact: string
  phone?: string | null
  callhubLine?: string | null
}

export interface CreateInitialValuesOrganization {
  externalOrgId?: string | null
  isExternal?: boolean
  active: boolean
  address?: AddressType
  contactPhone?: string | null
  email: string
  name: string
  program?: OrganizationProgram
  primaryContact: string
  phone?: string | null
  callhubLine?: string | null
}

export interface CreateInitialValuesManager {
  email: string
  firstName: string
  lastName: string
  organizationId?: string
  password: string
  confirmPassword?: string
  phone?: string | null
  userName: string
}

export interface OrganizationsActions {
  clearCsvStatus: ClearCsvStatus
  clearEditOrganization: ClearEditOrganization
  clearOrganization: ClearOrganization
  createOrganization: CreateOrganization
  editOrganization: EditOrganization
  fetchOrganizations: FetchOrganizations
  fetchOrganization: FetchOrganization
  setOrganizationToCreate: SetOrganizationToCreate
  uploadCSV: UploadCSV
  updateSignup: UpdateSignup
  updateUserCounter: UpdateUserCounter
  clearUserCounter: UpdateUserCounter
}

class Organizations {
  public static FETCH_ORGANIZATIONS_SUCCESS = "FETCH_ORGANIZATIONS_SUCCESS"
  public static FETCH_ORGANIZATIONS_TRIGGER = "FETCH_ORGANIZATIONS_TRIGGER"
  public static FETCH_ORGANIZATIONS_ERROR = "FETCH_ORGANIZATIONS_ERROR"

  public static FETCH_ORGANIZATION_SUCCESS = "FETCH_ORGANIZATION_SUCCESS"
  public static FETCH_ORGANIZATION_TRIGGER = "FETCH_ORGANIZATION_TRIGGER"
  public static FETCH_ORGANIZATION_ERROR = "FETCH_ORGANIZATION_ERROR"

  public static CREATE_ORGANIZATION_TRIGGER = "CREATE_ORGANIZATION_TRIGGER"
  public static CREATE_ORGANIZATION_SUCCESS = "CREATE_ORGANIZATION_SUCCESS"
  public static CREATE_ORGANIZATION_ERROR = "CREATE_ORGANIZATION_ERROR"
  public static CREATE_ORGANIZATION_CLEAR = "CREATE_ORGANIZATION_CLEAR"

  public static CREATE_MANAGER_SUCCESS = "CREATE_MANAGER_SUCCESS"
  public static CREATE_MANAGER_TRIGGER = "CREATE_MANAGER_TRIGGER"
  public static CREATE_MANAGER_ERROR = "CREATE_MANAGER_ERROR"

  public static EDIT_ORGANIZATION_TRIGGER = "EDIT_ORGANIZATION_TRIGGER"
  public static EDIT_ORGANIZATION_SUCCESS = "EDIT_ORGANIZATION_SUCCESS"
  public static EDIT_ORGANIZATION_ERROR = "EDIT_ORGANIZATION_ERROR"

  public static SET_ORGANIZATION_TO_CREATE = "SET_ORGANIZATION_TO_CREATE"

  public static UPDATE_ORGANIZATION_SIGNUP = "UPDATE_ORGANIZATION_SIGNUP"
  public static UPDATE_ORGANIZATION_USER_COUNTER = "UPDATE_ORGANIZATION_USER_COUNTER"

  public static UPLOAD_CSV_SUCCESS = "UPLOAD_CSV_SUCCESS"
  public static UPLOAD_CSV_ERROR = "UPLOAD_CSV_ERROR"
  public static UPLOAD_CSV_PROCESSING = "UPLOAD_CSV_PROCESSING"
  public static UPLOAD_CSV_CLEAR = "UPLOAD_CSV_CLEAR"

  public static CLEAR_EDIT_ORGANIZATION = "CLEAR_EDIT_ORGANIZATION"

  public static CLEAR_ORGANIZATION = "CLEAR_ORGANIZATION"

  public static fetchOrganizations: FetchOrganizations = (filter) => async (dispatch) => {
    let res: AxiosResponse

    dispatch({
      type: Organizations.FETCH_ORGANIZATIONS_TRIGGER,
    })
    const { searchBy, page, sortBy, sortDirection, limit } = filter ?? {}
    try {
      res = await API.get("/v1/organizations", {
        headers: AuthHelper.getAdminHeaders(),
        params: {
          page: page || 0,
          sortBy,
          sortDirection,
          searchBy,
          limit: limit ?? 10,
        },
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: Organizations.FETCH_ORGANIZATIONS_ERROR,
      })

      return
    }

    dispatch({
      payload: {
        organizations: res.data.data.organizations,
        pager: res.data.data.pager,
      },
      type: Organizations.FETCH_ORGANIZATIONS_SUCCESS,
    })
  }

  public static fetchOrganization: FetchOrganization = (id) => async (dispatch) => {
    let res: AxiosResponse

    dispatch({
      type: Organizations.FETCH_ORGANIZATION_TRIGGER,
    })

    try {
      res = await API.get(`/v1/organizations/${id}`, { headers: AuthHelper.getAdminHeaders() })

      if (res.data.data) {
        dispatch({
          payload: res.data.data,
          type: Organizations.FETCH_ORGANIZATION_SUCCESS,
        })

        return
      }
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: Organizations.FETCH_ORGANIZATION_ERROR,
      })

      return
    }
  }

  public static createOrganization: CreateOrganization = (values, manager) => async (dispatch) => {
    let response: AxiosResponse

    dispatch({ type: Organizations.CREATE_ORGANIZATION_TRIGGER })
    dispatch({ type: Organizations.CREATE_MANAGER_TRIGGER })

    try {
      response = await API.post(
        "/v1/organizations",
        {
          ...getOrganizationForBody(values),
          orgAdmin: getManagerForBody(manager),
        },
        { headers: AuthHelper.getAdminHeaders() },
      )

      if (response.data.data) {
        const organization: Organization = response.data.data.organization
        const orgAdmin: any = response.data.data.orgAdmin

        dispatch({
          type: Organizations.CREATE_ORGANIZATION_SUCCESS,
          payload: organization,
        })

        dispatch({
          type: Organizations.CREATE_MANAGER_SUCCESS,
          payload: orgAdmin,
        })
      }

      return
    } catch (err) {
      if (err) {
        const errorMessage: string = err.response?.data?.message || "Oops!... Looks like an error occurred!"

        dispatch({
          type: Organizations.CREATE_ORGANIZATION_ERROR,
          payload: errorMessage,
        })

        dispatch({
          type: Organizations.CREATE_MANAGER_ERROR,
          payload: errorMessage,
        })
      }

      return
    }
  }

  public static editOrganization: EditOrganization = (id, values) => async (dispatch: Dispatch) => {
    let res: AxiosResponse

    dispatch({
      type: Organizations.EDIT_ORGANIZATION_TRIGGER,
    })

    try {
      res = await API.put(`/v1/organizations/${id}`, getOrganizationForBody(values), { headers: AuthHelper.getAdminHeaders() })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: Organizations.EDIT_ORGANIZATION_ERROR,
      })

      return
    }

    dispatch({
      payload: res.data.data.organization,
      type: Organizations.EDIT_ORGANIZATION_SUCCESS,
    })

    return
  }

  public static setOrganizationToCreate: SetOrganizationToCreate = (values) => (dispatch: Dispatch) => {
    dispatch({
      type: Organizations.SET_ORGANIZATION_TO_CREATE,
      payload: values,
    })
  }

  public static updateSignup = () => async () => {
    const userAuth: UserAuth = AuthHelper.getUserAuth()

    try {
      await API.put(`/v1/organizations/${userAuth.organizationId}`, { signupCompleted: true }, { headers: AuthHelper.getAdminHeaders() })
    } catch (err) {
      AuthHelper.setSignup(false.toString())
      return
    }
    AuthHelper.setSignup(true.toString())
  }

  public static clearOrganization = () => async (dispatch: Dispatch) => {
    dispatch({
      type: Organizations.CLEAR_ORGANIZATION,
    })
  }

  public static uploadCSV: UploadCSV = (file, organizationId) => async (dispatch: Dispatch, getState: () => OrganizationsStore) => {
    let res: AxiosResponse
    const csvErrorMessage = "An error occurred while processing the file"

    const bodyFormData: FormData = new FormData()
    bodyFormData.append("file", file)
    bodyFormData.set("organizationId", organizationId)

    dispatch({
      type: Organizations.UPLOAD_CSV_PROCESSING,
    })

    try {
      res = await API.post("/v2/personorganizations/csv-people", bodyFormData, {
        headers: {
          ...AuthHelper.getAdminHeaders(),
          "Content-type": "multipart/form-data",
        },
      })

      if (res.data.data) {
        const { organizations }: any = getState()
        dispatch({
          type: Organizations.UPLOAD_CSV_SUCCESS,
          payload: {
            totalCallers: organizations.totalCallers + res.data.data.callers,
            totalRecipients: organizations.totalRecipients + res.data.data.recipients,
            ...res.data.data,
          },
        })
      } else {
        dispatch({
          type: Organizations.UPLOAD_CSV_ERROR,
          payload: csvErrorMessage,
        })
      }
    } catch (err) {
      const responseErrorMessage = err.response?.data?.message
      dispatch({
        type: Organizations.UPLOAD_CSV_ERROR,
        payload: responseErrorMessage ?? csvErrorMessage,
      })
    }
  }

  public static clearCsvStatus = () => (dispatch: Dispatch) => {
    dispatch({
      type: Organizations.UPLOAD_CSV_CLEAR,
    })
  }

  public static clearEditOrganization = () => (dispatch: Dispatch) => {
    dispatch({
      type: Organizations.CLEAR_EDIT_ORGANIZATION,
    })
  }

  public static updateUserCounter: UpdateUserCounter = ({ roleType, totalOutreaches, totalRecipients, totalCallers, calculateTotalCallBack }) => {
    let payload: { [key: string]: number } = {
      [RoleCounterKey.TOTAL_CALLERS]: calculateTotalCallBack(totalCallers),
    }

    if (roleType.toLowerCase() === RoleType.OUTREACH.toLowerCase()) {
      payload = {
        [RoleCounterKey.TOTAL_OUTREACHES]: calculateTotalCallBack(totalOutreaches),
      }
    }

    if (roleType.toLowerCase() === RoleType.RECIPIENT.toLowerCase()) {
      payload = {
        [RoleCounterKey.TOTAL_RECIPIENTS]: calculateTotalCallBack(totalRecipients),
      }
    }

    return {
      type: Organizations.UPDATE_ORGANIZATION_USER_COUNTER,
      payload,
    }
  }

  public static clearUserCounter: UpdateUserCounter = ({ roleType }) => {
    let payload: { [key: string]: number } = {
      [RoleCounterKey.TOTAL_CALLERS]: 0,
    }

    if (roleType.toLowerCase() === RoleType.OUTREACH.toLowerCase()) {
      payload = {
        [RoleCounterKey.TOTAL_OUTREACHES]: 0,
      }
    }

    if (roleType.toLowerCase() === RoleType.RECIPIENT.toLowerCase()) {
      payload = {
        [RoleCounterKey.TOTAL_RECIPIENTS]: 0,
      }
    }

    return {
      type: Organizations.UPDATE_ORGANIZATION_USER_COUNTER,
      payload,
    }
  }
}

export default Organizations
