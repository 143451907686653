import React, { useState } from "react"
import { Field } from "formik"
import { Input, InputGroupAddon } from "reactstrap"

import Icon from "./Icon"
import { BooleanHook } from "../../types/HooksType"
import { PasswordButton, PasswordInputContainer } from "../styles/Onboarding"
import { secondaryPink } from "../../assets/colors"

interface Props {
  isInFormik?: boolean
  name?: string
  onChangeHandler?: any
  placeholder?: string
  value?: string
  validateValue?: (value: string) => void
  onFocus?: () => void
}

type HandleBlur = () => () => void
type HandleFocus = () => () => void
type HandlePasswordDisplay = () => () => void

const PasswordField: React.FC<Props> = ({ isInFormik, name, onChangeHandler, placeholder, value, validateValue, onFocus, ...rest }: Props) => {
  const [isFocused, setIsFocused]: BooleanHook = useState<boolean>(false)
  const [reveal, setReveal]: BooleanHook = useState<boolean>(false)

  const fieldType: "text" | "password" = reveal ? "text" : "password"
  const handlePasswordDisplay: HandlePasswordDisplay = () => () => setReveal(!reveal)
  const handleFocus: HandleFocus = () => () => {
    setIsFocused(true)
    if (onFocus) {
      onFocus()
    }
  }
  const handleBlur: HandleBlur = () => () => setIsFocused(false)

  if (isInFormik) {
    return (
      <PasswordInputContainer onBlur={handleBlur()} onFocus={handleFocus()} focused={isFocused ? secondaryPink : "#d7d7d7"}>
        <Field data-testid={`password-field-${name}`} {...rest} className='form-control-password-modal' placeholder={placeholder} type={fieldType} name={name} validate={validateValue} />
        <InputGroupAddon addonType='prepend'>
          <PasswordButton data-testid={`password-input-button-display-password-${name}`} type='button' onClick={handlePasswordDisplay()} active={reveal}>
            <Icon icon='simple-icon-eye' size={26} />
          </PasswordButton>
        </InputGroupAddon>
      </PasswordInputContainer>
    )
  }

  return (
    <PasswordInputContainer onBlur={handleBlur()} onFocus={handleFocus()} focused={isFocused ? secondaryPink : "#d7d7d7"}>
      <Input data-testid={`password-input-${name}`} onChange={onChangeHandler} className='form-control-password' placeholder={placeholder} type={fieldType} value={value} />
      <InputGroupAddon addonType='prepend'>
        <PasswordButton data-testid={`password-input-button-display-password-${name}`} onClick={handlePasswordDisplay()} active={reveal}>
          <Icon icon='simple-icon-eye' size={26} />
        </PasswordButton>
      </InputGroupAddon>
    </PasswordInputContainer>
  )
}

export default PasswordField
