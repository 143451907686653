import { Dispatch } from "redux"

import API from "../../Api"
import AuthHelper from "../../helpers/AuthHelper"
import { isNotNullOrEmpty } from "../../helpers/FormatHelper"
import { CreateOrganizationPersonStatus } from "../../types/PersonType"
import Modals from "./modals"

type Clear = () => (dispatch: Dispatch) => void
type Create = (values: CreateOrganizationPersonStatus) => (dispatch: Dispatch) => void
type Fetch = (organizationId: string) => (dispatch: Dispatch) => void
type Delete = (id: string) => (dispatch: Dispatch) => void
type SetDefault = (organization: string, statusId: string) => (dispatch: Dispatch) => void

export interface OrganizationPersonStatusActions {
  clearOrganizationPersonStatuses: Clear
  createOrganizationPersonStatus: Create
  fetchOrganizationPersonStatuses: Fetch
  setDefaultOrganizationPersonStatus: SetDefault
  deleteOrganizationPersonStatus: Delete
}

class OrganizationPersonStatusActionImpl {
  public static FETCH_ORGANIZATION_PERSON_STATUS_TRIGGER = "FETCH_ORGANIZATION_PERSON_STATUS_TRIGGER"
  public static FETCH_ORGANIZATION_PERSON_STATUS_SUCCESS = "FETCH_ORGANIZATION_PERSON_STATUS_SUCCESS"
  public static FETCH_ORGANIZATION_PERSON_STATUS_ERROR = "FETCH_ORGANIZATION_PERSON_STATUS_ERROR"

  public static CREATE_ORGANIZATION_PERSON_STATUS_TRIGGER = "CREATE_ORGANIZATION_PERSON_STATUS_TRIGGER"
  public static CREATE_ORGANIZATION_PERSON_STATUS_SUCCESS = "CREATE_ORGANIZATION_PERSON_STATUS_SUCCESS"
  public static CREATE_ORGANIZATION_PERSON_STATUS_ERROR = "CREATE_ORGANIZATION_PERSON_STATUS_ERROR"

  public static SET_DEFAULT_ORGANIZATION_PERSON_STATUS_TRIGGER = "SET_DEFAULT_ORGANIZATION_PERSON_STATUS_TRIGGER"
  public static SET_DEFAULT_ORGANIZATION_PERSON_STATUS_SUCCESS = "SET_DEFAULT_ORGANIZATION_PERSON_STATUS_SUCCESS"
  public static SET_DEFAULT_ORGANIZATION_PERSON_STATUS_ERROR = "SET_DEFAULT_ORGANIZATION_PERSON_STATUS_ERROR"

  public static DELETE_ORGANIZATION_PERSON_STATUS_TRIGGER = "DELETE_ORGANIZATION_PERSON_STATUS_TRIGGER"
  public static DELETE_ORGANIZATION_PERSON_STATUS_SUCCESS = "DELETE_ORGANIZATION_PERSON_STATUS_SUCCESS"
  public static DELETE_ORGANIZATION_PERSON_STATUS_ERROR = "DELETE_ORGANIZATION_PERSON_STATUS_ERROR"

  public static CLEAR_ORGANIZATION_PERSON_STATUS = "CLEAR_ORGANIZATION_PERSON_STATUS"

  public static fetchOrganizationPersonStatuses: Fetch = (organizationId) => async (dispatch) => {
    try {
      dispatch({
        type: OrganizationPersonStatusActionImpl.FETCH_ORGANIZATION_PERSON_STATUS_TRIGGER,
      })
      const res = await API.get("/v2/organizationpersonstatuses", {
        headers: AuthHelper.getAdminHeaders(),
        params: { organizationId: organizationId || "" },
      })
      dispatch({
        payload: res.data.data.rows,
        type: OrganizationPersonStatusActionImpl.FETCH_ORGANIZATION_PERSON_STATUS_SUCCESS,
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: OrganizationPersonStatusActionImpl.FETCH_ORGANIZATION_PERSON_STATUS_ERROR,
      })

      return
    }
  }

  public static createOrganizationPersonStatus: Create = (values) => async (dispatch) => {
    try {
      dispatch({
        type: OrganizationPersonStatusActionImpl.CREATE_ORGANIZATION_PERSON_STATUS_TRIGGER,
      })
      Modals.createModalTrigger(dispatch)
      const res = await API.post(
        "/v2/organizationpersonstatuses",
        {
          ...values,
          description: isNotNullOrEmpty(values.description) ? values.description : null,
        },
        { headers: AuthHelper.getAdminHeaders() },
      )
      if (res.data.data) {
        Modals.createModalSuccess(dispatch)
        dispatch({
          payload: res.data.data.organizationPersonStatus,
          type: OrganizationPersonStatusActionImpl.CREATE_ORGANIZATION_PERSON_STATUS_SUCCESS,
        })
      }
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: OrganizationPersonStatusActionImpl.CREATE_ORGANIZATION_PERSON_STATUS_ERROR,
      })
      Modals.createModalError(dispatch, err.response?.data?.message)

      return
    }
  }

  public static deleteOrganizationPersonStatus: Delete = (id) => async (dispatch) => {
    try {
      dispatch({
        type: OrganizationPersonStatusActionImpl.DELETE_ORGANIZATION_PERSON_STATUS_TRIGGER,
      })
      const res = await API.delete(`/v2/organizationpersonstatuses/${id}`, { headers: AuthHelper.getAdminHeaders() })

      if (res.data.data) {
        dispatch({
          payload: id,
          type: OrganizationPersonStatusActionImpl.DELETE_ORGANIZATION_PERSON_STATUS_SUCCESS,
        })
      }
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: OrganizationPersonStatusActionImpl.DELETE_ORGANIZATION_PERSON_STATUS_ERROR,
      })
      Modals.createModalError(dispatch, err.response?.data?.message ?? "Oops! Looks like an error occurred!")
      return
    }
  }

  public static setDefaultOrganizationPersonStatus: SetDefault = (organizationId, organizationPersonStatusId) => async (dispatch) => {
    try {
      dispatch({
        type: OrganizationPersonStatusActionImpl.SET_DEFAULT_ORGANIZATION_PERSON_STATUS_TRIGGER,
      })
      const res = await API.put(`/v1/organizations/${organizationId}/default-statuses/${organizationPersonStatusId}`, {}, { headers: AuthHelper.getAdminHeaders() })

      const organizationPersonStatusList = res.data.data.statuses ?? []

      if (res.data.data) {
        dispatch({
          payload: organizationPersonStatusList,
          type: OrganizationPersonStatusActionImpl.SET_DEFAULT_ORGANIZATION_PERSON_STATUS_SUCCESS,
        })
      }
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: OrganizationPersonStatusActionImpl.SET_DEFAULT_ORGANIZATION_PERSON_STATUS_ERROR,
      })
      Modals.createModalError(dispatch, err.response?.data?.message ?? "Oops! Looks like an error occurred!")
      return
    }
  }

  public static clearOrganizationPersonStatuses: Clear = () => (dispatch: Dispatch) => {
    dispatch({
      type: OrganizationPersonStatusActionImpl.CLEAR_ORGANIZATION_PERSON_STATUS,
    })
  }
}

export default OrganizationPersonStatusActionImpl
