import ActionType from "../../types/ActionType"

export interface SettingsActions {
  changeLocale: (locale: string) => ActionType
}

class Settings {
  public static CHANGE_LOCALE = "CHANGE_LOCALE"

  public static changeLocale = (locale: string) => {
    localStorage.setItem("currentLanguage", locale)

    return {
      payload: locale,
      type: Settings.CHANGE_LOCALE,
    }
  }
}

export default Settings
