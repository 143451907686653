// Messages colors
export const mutedGray = "rgb(144, 144, 144)"
export const semiMutedGray = "rgb(143, 143, 143)"

// Theme colors
export const baseLightBlue = "rgba(67, 168, 238, 1)"
export const baseColor = "rgba(44, 117, 187, 1)"
export const blackOpacityThirty = "rgba(0, 0, 0, 0.3)"
export const primaryBlue = "rgb(44,117,187)"
export const secondaryBlue = "rgba(44,117,187, 0.5)"
export const primaryPink = "rgba(235, 20, 139, 1)"
export const secondaryPink = "rgba(255, 133, 200, 1)"
export const primaryOrange = "rgba(243, 149, 33, 1)"
export const secondaryOrange = "rgba(194, 111, 10, 1)"
export const mediumWhite = "rgb(215, 215, 215)"

// Message colors
export const greenSuccess = "rgba(55, 167, 76, 1)"
export const greySuccess = "rgba(155, 155, 155, 1)"
export const redError = "rgba(231, 139, 139, 1)"

// Regular colors
export const green = "rgb(0, 128, 0)"
export const grey = "rgb(85, 85, 85)"
export const lightGrey = "rgb(180, 180, 180)"
export const purple = "rgba(169, 68, 159, 1)"
export const red = "rgb(255, 0, 0)"
export const redLight = "rgb(251, 120, 120)"
export const white = "rgb(255, 255, 255)"
export const whiteSmoke = "rgba(245, 245, 245)"

// Toast colors
export const blackOpacityEighty = "rgba(0, 0, 0, 0.8)"
export const errorRed = "rgb(248, 79, 49)"
export const successGreen = "rgb(35, 197, 82)"

export const HexadecimalColors = {
  baseColor: "#2C75BB",
  primaryBlue: "#2C75BB",
  primaryPink: "#EB148B",
  mutedPrimaryPink: "#FF85C8",
  mutedGray: "#909090",
}
