import React, { useEffect } from "react"
import { History } from "history"
import { IntlConfig, IntlProvider } from "react-intl"
import { useHistory } from "react-router-dom"
import { useIdleTimer } from "react-idle-timer"
import { toast, ToastContainer } from "react-toastify"
import { ErrorBoundary } from "react-error-boundary"
import { useQueryErrorResetBoundary } from "@tanstack/react-query"

import AppLocale from "./lang"
import AuthHelper from "./helpers/AuthHelper"
import Routes from "./router/Routes"
import withAuth, { AuthProps } from "./redux/hocs/withAuth"
import ErrorFallback from "./views/errorFallback"
import { EXPIRATION_TIME_90_MINUTES_IN_MS } from "./constants/defaultValues"

const App: React.FC<AuthProps> = ({ logout, userStatusLoading, getUserStatus }) => {
  const history: History = useHistory()
  const { reset: useQueryReset } = useQueryErrorResetBoundary()
  const getNavigatorLanguage = () => {
    return navigator.language.split("-")[0]
  }

  const currentAppLocale: IntlConfig = AppLocale[getNavigatorLanguage()]

  useEffect(() => {
    const id: string | null = AuthHelper.getUserAuth().userId
    if (!id) {
      return
    }
    if (!userStatusLoading) {
      getUserStatus(id, history)
    }
  }, [])

  const handleOnIdle = () => {
    if (AuthHelper.isLoggedIn()) {
      logout(history)
      window.location.reload()
    }
  }

  useIdleTimer({
    timeout: EXPIRATION_TIME_90_MINUTES_IN_MS,
    onIdle: handleOnIdle,
    debounce: 500,
  })

  return (
    <div className='h-100' data-testid='app-holder'>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <ErrorBoundary FallbackComponent={ErrorFallback.Component} onReset={ErrorFallback.onReset(useQueryReset)}>
          <Routes />
        </ErrorBoundary>
      </IntlProvider>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </div>
  )
}

export default withAuth(App)
