import { OptionValue } from "../components/common/FormSelectField"

type GetStateOptions = () => OptionValue[]
type GetStateShort = (state: string) => string

export interface StateItem {
  state: string
  stateShort: string
}

export const getStateOptions: GetStateOptions = () => {
  return states.map((state: StateItem) => ({
    label: state.state,
    value: state.state,
  }))
}

export const getStateShort: GetStateShort = (state) => {
  const found: StateItem | undefined = states.find((item: StateItem) => item.state === state)
  if (found) return found.stateShort
  return ""
}

const states: StateItem[] = [
  { stateShort: "AL", state: "Alabama" },
  { stateShort: "AK", state: "Alaska" },
  { stateShort: "AZ", state: "Arizona" },
  { stateShort: "AR", state: "Arkansas" },
  { stateShort: "CA", state: "California" },
  { stateShort: "CO", state: "Colorado" },
  { stateShort: "CT", state: "Connecticut" },
  { stateShort: "DE", state: "Delaware" },
  { stateShort: "DC", state: "District of Columbia" },
  { stateShort: "FL", state: "Florida" },
  { stateShort: "GA", state: "Georgia" },
  { stateShort: "HI", state: "Hawaii" },
  { stateShort: "ID", state: "Idaho" },
  { stateShort: "IL", state: "Illinois" },
  { stateShort: "IN", state: "Indiana" },
  { stateShort: "IA", state: "Iowa" },
  { stateShort: "KS", state: "Kansas" },
  { stateShort: "KY", state: "Kentucky" },
  { stateShort: "LA", state: "Louisiana" },
  { stateShort: "ME", state: "Maine" },
  { stateShort: "MD", state: "Maryland" },
  { stateShort: "MA", state: "Massachusetts" },
  { stateShort: "MI", state: "Michigan" },
  { stateShort: "MN", state: "Minnesota" },
  { stateShort: "MS", state: "Mississippi" },
  { stateShort: "MO", state: "Missouri" },
  { stateShort: "MT", state: "Montana" },
  { stateShort: "NE", state: "Nebraska" },
  { stateShort: "NV", state: "Nevada" },
  { stateShort: "NH", state: "New Hampshire" },
  { stateShort: "NJ", state: "New Jersey" },
  { stateShort: "NM", state: "New Mexico" },
  { stateShort: "NY", state: "New York" },
  { stateShort: "NC", state: "North Carolina" },
  { stateShort: "ND", state: "North Dakota" },
  { stateShort: "OH", state: "Ohio" },
  { stateShort: "OK", state: "Oklahoma" },
  { stateShort: "OR", state: "Oregon" },
  { stateShort: "PA", state: "Pennsylvania" },
  { stateShort: "RI", state: "Rhode Island" },
  { stateShort: "SC", state: "South Carolina" },
  { stateShort: "SD", state: "South Dakota" },
  { stateShort: "TN", state: "Tennessee" },
  { stateShort: "TX", state: "Texas" },
  { stateShort: "UT", state: "Utah" },
  { stateShort: "VT", state: "Vermont" },
  { stateShort: "VA", state: "Virginia" },
  { stateShort: "WA", state: "Washington" },
  { stateShort: "WV", state: "West Virginia" },
  { stateShort: "WI", state: "Wisconsin" },
  { stateShort: "WY", state: "Wyoming" },
]

export default states
