// tslint:disable: no-any
import SimpleCrypto, { PlainData } from "simple-crypto-js"

type HandleEncrypt = (storageLabel: string, data: PlainData) => void
type HandleDecrypt<T> = (cipherData: string) => T | null

const simpleCrypto: SimpleCrypto = new SimpleCrypto(window._env_.REACT_APP_SECRET_KEY || window._env_.REACT_APP_SECRET_KEY_TEST)

const encryptLocalStorage: HandleEncrypt = (storageLabel, data) => {
  const cipherData: PlainData = simpleCrypto.encrypt(data)

  localStorage.setItem(storageLabel, cipherData)
}

const decryptLocalStorage: HandleDecrypt<any> = (cipherData) => {
  try {
    const dataStorage: string | null = localStorage.getItem(cipherData)
    if (dataStorage) {
      const decipherData: any = simpleCrypto.decrypt(dataStorage)
      return decipherData
    }
  } catch (e) {
    console.error("Error during decrypt local data")
  }
  return null
}

export { encryptLocalStorage, decryptLocalStorage }
