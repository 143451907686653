// tslint:disable:no-increment-decrement
import PasswordValidator from "password-validator"

type ValidateParams = (password: string, validateWords: string[]) => boolean

class PasswordUtils {
  private static MIN_PASSWORD_LENGTH = 12
  private static CHAR_COUNT = 3

  public static validatePassword: ValidateParams = (password, validateWords) => {
    return (
      PasswordUtils.validateSymbol(password) &&
      PasswordUtils.validateLength(password) &&
      PasswordUtils.validateUppercase(password) &&
      PasswordUtils.validateLowercase(password) &&
      PasswordUtils.validateDigits(password) &&
      validateWords.every((word) => PasswordUtils.validateContainsWord(password, word))
    )
  }

  public static validateSymbol = (password: string) => {
    const schema: PasswordValidator = new PasswordValidator()
    schema.has().symbols()
    return schema.validate(password)
  }

  public static validateLength = (password: string) => {
    const schema: PasswordValidator = new PasswordValidator()
    schema.is().min(PasswordUtils.MIN_PASSWORD_LENGTH)
    return schema.validate(password)
  }

  public static validateUppercase = (password: string) => {
    const schema: PasswordValidator = new PasswordValidator()
    schema.has().uppercase()
    return schema.validate(password)
  }

  public static validateLowercase = (password: string) => {
    const schema: PasswordValidator = new PasswordValidator()
    schema.has().lowercase()
    return schema.validate(password)
  }

  public static validateDigits = (password: string) => {
    const schema: PasswordValidator = new PasswordValidator()
    schema.has().digits()
    return schema.validate(password)
  }

  public static validateContainsWord = (password: string, word: string) => {
    if (!password || !password.length) {
      return true
    }
    const iterations: number = word.length - PasswordUtils.CHAR_COUNT
    if (iterations < 0) {
      return true
    }
    const splited: string[] = []
    for (let i = 0; i <= iterations; i++) {
      splited.push(word.slice(i, i + PasswordUtils.CHAR_COUNT))
    }
    return !splited.some((wordPart: string) => password.includes(wordPart))
  }

  public static checkNonPrintingCharacters = (value: string) => {
    if (!value) {
      return true
    }
    const found: string[] | null = value.match(/[^ -~]/)
    if (found) {
      return found.length === 0
    }
    return true
  }
}

export default PasswordUtils
