// tslint:disable: no-any
import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Button, CardBody, CardFooter, Col, Row, Table } from "reactstrap"

import { device } from "../../utils/device"
import { baseColor, primaryBlue, primaryPink, secondaryBlue } from "../../assets/colors"

const ErrorAlert: React.FC = styled.div`
  color: #ff0000;
  font-size: 0.8rem;
`

const Title: React.FC<any> = styled(Col)`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 11px;
  border-radius: 5px;
  align-items: center;

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.tablet} {
    font-size: 20px;
  }

  @media ${device.laptop} {
    justify-content: flex-start;
    width: max-content;
    font-size: 26px;
  }
`

const InformationContainer: React.FC<any> = styled.div`
  font-size: 15px;
  margin-top: 20px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .half-width {
    width: 50%;
  }
`
const Header: React.FC<any> = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  .container-name-detail {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @media ${device.laptop} {
      width: 100%;
      justify-content: flex-start;
    }
  }

  .organizations-list-header {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    align-items: center;
  }

  .organization-detail {
    justify-content: flex-start;
  }

  .organization-list-header-text {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .organization-name {
    text-align: center;
    width: 100%;
    margin-right: 20px;
    justify-content: center;
    display: flex;
    font-size: 1.75rem;

    @media ${device.laptop} {
      line-height: 0.9;
      width: initial;
      margin-right: 0;
      justify-content: flex-start;
    }
  }

  .organization-buttons-container {
    display: flex;
  }

  .organization-edit-button {
    border: 1px solid ${primaryPink};
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    margin: 0 15px;
    color: ${primaryPink};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;

    :hover {
      opacity: 0.5;
      transition: 0.2s;
      cursor: pointer;
    }
  }

  .organization-add-button {
    border: 1px solid ${primaryPink};
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    color: ${primaryPink};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;

    :hover {
      opacity: 0.5;
      transition: 0.2s;
      cursor: pointer;
    }
  }

  .statistics {
    display: flex;
    justify-content: flex-start;
    border-left: outset;
    padding: 12px;
    flex-wrap: wrap;

    @media ${device.laptop} {
      flex-wrap: nowrap;
    }
  }

  .amount-header {
    width: 130px;
    text-align: "center";
    margin: 0 5px 0 5px;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
  }

  @media ${device.laptop} {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`

const StyledScrollContainer: React.FC<any> = styled.div`
  display: flex;
  justify-content: center;

  .onboarding {
    height: 300px;

    @media ${device.tablet} {
      width: 100%;
      height: 300px;
    }

    @media ${device.laptop} {
      width: 100%;
      height: 300px;
    }
  }

  .detail-modal {
    height: 300px;
  }
`

const Content: React.FC<any> = styled.div`
  display: flex;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  .first-head-info {
    font-weight: bold;
    padding: 15px;

    .address-info {
      color: ${primaryPink};
    }
  }

  .head-info {
    min-width: 15%;
    margin-right: 5%;
    font-weight: bold;
    border-left: outset;
    padding: 15px;
  }

  .head-info-light {
    font-weight: lighter;
  }

  border-left: none;
  :first-child {
    border-left: none;
  }
`

const ButtonsContainer: React.FC<any> = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 8px;

  @media ${device.tablet} {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
`

const CircleButtonContainer: React.FC<any> = styled.div`
  border: 1px solid ${primaryPink};
  max-width: 40px;
  min-width: 40px;
  height: 40px;
  margin: 0 5px 0 1%;
  color: ${primaryPink};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  :hover {
    cursor: pointer;
    opacity: 0.5;
    transition: 0.2s;
  }
`

const UserNotesButtonContainer: React.FC<any> = styled.div`
  border: 1px solid ${primaryPink};
  max-width: 40px;
  min-width: 40px;
  height: 40px;
  margin: 0 5px 0 1%;
  color: ${primaryPink};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  :hover {
    cursor: pointer;
    opacity: 0.5;
    transition: 0.2s;
  }
`

const NoUserContainer: React.FC<any> = styled.div`
  display: flex;
  justify-content: center;
  height: 80px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
  font-size: 18px;
  min-height: 300px;
`

const LoaderContainer: React.FC = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  opacity: 3;
`

const ThemedButton: React.FC<any> = styled(Button)`
  && {
    margin-left: 10px;
    margin-right: 10px;
    width: 100px;
  }
`

const LinkDiv: React.FC<React.HTMLAttributes<HTMLDivElement>> = styled.div`
  color: ${baseColor};
  max-width: fit-content;

  :hover {
    cursor: pointer;
    color: ${baseColor};
    text-decoration: underline;
  }
`

const LinkTd: React.FC<any> = styled.td`
  color: ${baseColor};

  :hover {
    cursor: pointer;
    color: ${baseColor};
    text-decoration: underline;
  }
  a {
    color: ${baseColor};
  }
`

const LinkSpan: React.FC<any> = styled(Link)`
  color: ${baseColor};

  :hover {
    cursor: pointer;
    color: ${baseColor};
    text-decoration: underline;
  }
`

const BorderlessHeader: React.FC<any> = styled.th`
  && {
    border: none;
  }
  width: 50px;
`

const BorderlessTopHeader: React.FC<any> = styled.th`
  && {
    border: none;
    vertical-align: top;
  }
  width: 50px;
`

const BorderlessCenterHeader: React.FC<any> = styled.th`
  text-align: center;
  && {
    border: none;
  }
  width: 50px;
`
const EmailTd: React.FC<any> = styled.td`
  max-height: 40px;
  width: 50px;
`

const EmailTh: React.FC<any> = styled.th`
  max-height: 50px;
  && {
    border: none;
  }
  width: 50px;
`

const CheckedContainer: React.FC = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 25px;
`

const StyledCardBody: React.FC<any> = styled(CardBody)`
  overflow-y: scroll;
  height: 200px;
  width: 100%;
  behavior: smooth;
  padding: 0px;
`

const CustomRow: React.FC<any> = styled(Row)`
  justify-content: center;
`

const InviteIconContainer: React.FC<any> = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: flex-end;
  color: ${primaryBlue};

  :hover {
    cursor: pointer;
    color: ${secondaryBlue};
  }
`

const PagerContainer: React.FC<any> = styled(CardFooter)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  flex-wrap: wrap;

  .page-selected {
    text-align: center;
    width: 100%;
    margin-top: 10px;
    color: ${primaryBlue};
  }
`

const ListHeader: React.FC = styled.h1`
  padding-bottom: 10px;
  margin-bottom: 0;
`

const ExportButtonContainer: React.FC<any> = styled.div`
  right: 15px;
  position: absolute;
  top: 0;
`

const CustomTable: React.FC<any> = styled(Table)`
  table-layout: fixed;
  width: 640px;

  @media ${device.tablet} {
    width: 100%;
  }

  @media ${device.laptopL} {
    width: 99%;
  }

  @media ${device.desktopL} {
    width: 100%;
  }
`
const StickyHeaderContainer: React.FC<any> = styled.div`
  min-height: 20vh;
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  :hover,
  :focus {
    ::-webkit-scrollbar {
      border-radius: 50px;
      background: #f8f8f8;
    }
  }
`
const StickyHeaderTable: React.FC<any> = styled(Table)`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  thead {
    position: sticky;
    top: -1px;
    z-index: 1;
    background: white;
  }
  th {
    border: none;
  }

  td {
    vertical-align: middle;
    border: none;
  }
  th:not(:first-child),
  td:not(:first-child) {
    text-align: center;
  }
`

const TdStyled: React.FC<any> = styled.td`
  text-align: center;
`

const TdCustomMessage: React.FC<any> = styled.td`
  text-align: center;
  vertical-align: middle !important;

  :hover {
    background-color: white;
  }
`

export {
  BorderlessHeader,
  BorderlessCenterHeader,
  BorderlessTopHeader,
  ButtonsContainer,
  CheckedContainer,
  Content,
  CustomRow,
  CustomTable,
  StickyHeaderContainer,
  StickyHeaderTable,
  CircleButtonContainer,
  EmailTd,
  EmailTh,
  ErrorAlert,
  ExportButtonContainer,
  Header,
  InformationContainer,
  InviteIconContainer,
  LinkDiv,
  ListHeader,
  LinkTd,
  LinkSpan,
  LoaderContainer,
  NoUserContainer,
  PagerContainer,
  StyledCardBody,
  StyledScrollContainer,
  ThemedButton,
  TdCustomMessage,
  TdStyled,
  Title,
  UserNotesButtonContainer,
}
