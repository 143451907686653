// tslint:disable: no-any
import styled from "styled-components"
import { Button, InputGroup, InputGroupProps, Progress, Tooltip } from "reactstrap"

import { baseColor, mediumWhite, primaryBlue, primaryPink, secondaryPink } from "../../assets/colors"
import { Colxx } from "../common/CustomBootstrap"
import { device } from "../../utils/device"

type PasswordButtonProps = React.HTMLProps<HTMLButtonElement> & { active?: boolean }
type PasswordInputContainerProps = InputGroupProps & { focused?: string }

const fileNameStyle: any = {
  marginTop: "15px",
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
}

const InputError: React.FC<any> = styled.div`
  color: gray;
  margin: 8px 0 8px 0;
  font-size: 13px;
`

const OnboardingContainer: React.FC<any> = styled.div`
  background: ${baseColor};
`

const IntroCol: React.FC<any> = styled.div`
  text-align: center;
  overflow: hidden;

  .callhub-logo {
    margin: auto;
    padding: 0 10px;
  }

  .callhub-brand-text {
    border-top: 1px solid ${secondaryPink};
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1.6em;
    color: #808080;
    padding: 15px;
    width: 80%;
    margin: auto;
  }

  .callhub-icons {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
  }
`

const CustomTooltip: React.FC<any> = styled(Tooltip)`
  position: absolute;
  width: 200px;
  top: 90px;

  @media ${device.mobileL} {
    top: 90px;
    right: 20px;
    left: 0px;
  }

  @media ${device.laptop} {
    top: 110px;
    right: 20px;
    left: 0px;
  }

  @media ${device.tablet} {
    top: 130px;
    right: 20px;
    left: 0px;
  }
`

const ProgressCol: React.FC<any> = styled(Colxx)`
  margin: auto;
  text-align: center;
  padding: 80px 15px 15px 15px;

  .callhub-title {
    font-weight: bold;
    font-size: 2em;
    color: black;
    margin-bottom: 10px;
  }

  .callhub-subtitle {
    font-weight: bold;
    font-size: 1.8em;
    color: black;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .stepper {
    margin: auto;
    height: 60px;

    margin-bottom: 20px;
    margin-top: 50px;

    @media ${device.laptop} {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  .first-step {
    margin: auto;
    text-align: center;
  }

  .second-step {
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    padding: 10px;

    .download-button-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
    }

    .csv-instructions {
      width: 100%;
      margin-top: 20px;
      justify-content: center;
    }

    .csv-instructions-link {
      color: ${primaryBlue};

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .third-step {
    margin: auto;
    text-align: center;
    padding: 10px;

    .upload-file-button {
      min-width: 100px;
      border-radius: 5px;
    }

    .upload-container {
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 60px 10px 60px 10px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      background: #e9e9e9;
      border-radius: 4px;
    }
  }

  .fourth-step {
    margin: auto;
    font-weight: bold;
    color: gray;
  }

  .step-buttons {
    display: flex;
    justify-content: ${({ step }) => (step > 1 && step < 4 ? "space-around" : "center")};
    margin-top: 3%;
    margin-bottom: 3%;
    text-align: center;
    max-width: ${({ step }) => (step === 2 ? "100%" : "80%")};
    margin-left: auto;
    margin-right: auto;
  }

  .step-buttons-container {
    margin: auto;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .download-button {
    margin-top: 10px;
  }
`

const ProgressBarContainer: React.FC = styled.div`
  text-align: center;
  padding-top: 25px;
`

const ProgressBar: React.FC<any> = styled(Progress)`
  height: 12px;
  border-radius: 10px;
`

const PasswordFieldContainer: React.FC = styled.div`
  display: flex;

  & > div {
    max-width: 450px;
  }
`

const PasswordInputContainer: React.FC<PasswordInputContainerProps> = styled(InputGroup)`
  flex-wrap: nowrap;
  justify-content: center;
  margin: 4px auto 6px auto;
  width: 100%;
  padding-right: 1px;
  border: 1px solid ${({ focused }) => focused};
`

const PasswordButton: React.FC<PasswordButtonProps> = styled.button`
  background: white;
  color: ${({ active }: PasswordButtonProps) => (active ? primaryPink : "#d7d7d7")};
  border: none;

  :hover {
    cursor: pointer;
    color: ${secondaryPink};
  }
`

const StyledButton: React.FC<any> = styled(Button)`
  min-width: 140px;
  max-width: 160px;
  font-size: 15px;
  margin: 10px;
`

const HeaderItems: React.FC<any> = styled.div`
  @media ${device.tablet} {
    :first-child {
      border-left: none;
    }

    border-left: 2px solid ${mediumWhite};
    border-bottom: none;
    text-align: initial;
    margin-right: 5%;
    font-weight: bold;
    padding: 15px;
  }

  :first-child {
    border-left: none;
  }

  border-left: none;
  text-align: center;
  border-bottom: 1px solid ${mediumWhite};
  margin-right: 5%;
  font-weight: bold;
  padding: 15px;
  width: 100%;
`

const HeaderStatics: React.FC<any> = styled.div`
  @media ${device.tablet} {
    border-left: 2px solid ${mediumWhite} !important;
  }

  border-left: none !important;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  border-left: outset;
  padding: 12px;
`

const StatusHeader: React.FC<any> = styled.div`
  display: flex;
  justify-content: center;
`

const StatusText: React.FC<any> = styled.span`
  text-align: center;
  font-size: 18px;
  width: 100%;
  color: gray;
  font-weight: lighter;
`

const ContainerNameDetail: React.FC<any> = styled.div`
  padding: 10px;
`

export {
  CustomTooltip,
  ContainerNameDetail,
  fileNameStyle,
  HeaderItems,
  HeaderStatics,
  InputError,
  IntroCol,
  OnboardingContainer,
  PasswordButton,
  PasswordFieldContainer,
  PasswordInputContainer,
  ProgressCol,
  ProgressBar,
  ProgressBarContainer,
  StatusHeader,
  StatusText,
  StyledButton,
}
