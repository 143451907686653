import { UserProfileNameEnum } from "../constants/views"
import { Person } from "./PersonType"

export interface LoginResponse {
  data: LoginData
  status: boolean
  timestamp: string
}

export interface LoginData {
  user: Person
  userName: string
  passwordStatus: string
  token: string
  isSuperAdmin: boolean
  profiles: PersonOrganizationProfile[]
  features: Feature[]
  mfa?: MFAAuthOptions
  availableMfaPhones?: MFAPhoneOptions[]
}

export interface PersonOrganizationProfile {
  id: string
  deletedAt: any
  personId: string
  securityProfileId: string
  organizationId: string
  tosAccepted: any
  securityProfile: SecurityProfile
  organization: Organization
}

export interface SecurityProfile {
  id: string
  name: string
  permissions: Permission[]
}

export interface Permission {
  module: string
  type: string
  securityProfileId: string
  id: string
  deletedAt: any
  createdAt: string
  updatedAt: string
}

export enum PermissionEnum {
  BROADCASTS = "broadcasts",
  CALLERSURVEYS = "callerSurveys",
  CALLLISTQUEUES = "callListQueues",
  CALLLISTQUEUESEDITOR = "callListQueuesEditor",
  CALLS = "calls",
  EXTERNALUSERS = "externalUsers",
  FEATURE = "feature",
  NOTESPERSON = "notesPerson",
  ORGANIZATIONPERSONSTATUSES = "organizationPersonStatuses",
  ORGANIZATIONS = "organizations",
  ORGANIZATIONSFEATURES = "organizationsFeatures",
  OUTREACHES = "outreaches",
  PEOPLE = "people",
  PERMISSIONS = "permissions",
  PERSONDETAILS = "personDetails",
  PERSONORGANIZATIONS = "personorganizations",
  PERSONORGANIZATIONSSURVEYS = "personorganizationssurveys",
  POLICIES = "policies",
  PROFILES = "profiles",
  PROGRAMS = "programs",
  RECIPIENTS = "recipients",
  ROLES = "roles",
  SURVEYS = "surveys",
  VOLUNTEERS = "volunteers",
  LoginMFA = "loginmfa",
}

export enum CanRuleEnum {
  CREATE = "create",
  READ = "read",
  EDIT = "edit",
  DELETE = "delete",
}

export const CanRuleAll = [CanRuleEnum.CREATE, CanRuleEnum.READ, CanRuleEnum.EDIT, CanRuleEnum.DELETE]

export type CanRuleType = CanRuleEnum.CREATE | CanRuleEnum.READ | CanRuleEnum.EDIT | CanRuleEnum.DELETE

export const PermissionProfileFullAccess = {
  profile: UserProfileNameEnum.SUPER_ADMIN,
  canRules: CanRuleAll,
}

export interface PermissionProfile {
  profile: UserProfileNameEnum
  canRules: CanRuleType[]
}

export interface PermissionRuleByModule {
  module: PermissionEnum
  profile: UserProfileNameEnum
  canEdit: boolean
  canDeleteAsAdmin: boolean
  canCreate: boolean
  canReadAsAdmin: boolean
}

export type PermissionAcl = {
  [key in PermissionEnum]?: PermissionProfile[]
}

export const permissionAclByModule: PermissionAcl = {
  [PermissionEnum.CALLLISTQUEUES]: [
    {
      profile: UserProfileNameEnum.SUPER_ADMIN,
      canRules: CanRuleAll,
    },
    {
      profile: UserProfileNameEnum.ORG_ADMIN,
      canRules: CanRuleAll,
    },
    {
      profile: UserProfileNameEnum.ORG_USER,
      canRules: [CanRuleEnum.READ],
    },
  ],
  [PermissionEnum.CALLLISTQUEUESEDITOR]: [
    {
      profile: UserProfileNameEnum.SUPER_ADMIN,
      canRules: CanRuleAll,
    },
    {
      profile: UserProfileNameEnum.ORG_ADMIN,
      canRules: CanRuleAll,
    },
    {
      profile: UserProfileNameEnum.ORG_USER,
      canRules: CanRuleAll,
    },
  ],
}

export interface Organization {
  id: string
  signupCompleted: boolean
}

export interface Feature {
  id?: string
  name: string
  routePrefix: string
  activeValidation: boolean
}

export interface Configuration {
  name: string
  identifier: ConfigurationIdentifierEnum
  description: string
  active: boolean
}

export enum ConfigurationIdentifierEnum {
  OPEN_PROFILE_IN_NEW_TAB = "OPEN_PROFILE_IN_NEW_TAB",
}

export interface MFAAuthOptions {
  mfaRequired: boolean
  mfaVerified: boolean
}

export interface MFAPhoneOptions {
  name: string
  phone: string
}
