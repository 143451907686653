export enum BroadcastTypesEnum {
  VOICE = "voice",
  EMAIL = "email",
  SMS = "sms",
}
export enum BroadcastTransactionStatusEnum {
  PENDING = "pending",
  SUCCESS = "success",
  ERROR = "error",
}

export type BroadcastType = BroadcastTypesEnum.VOICE | BroadcastTypesEnum.EMAIL | BroadcastTypesEnum.SMS

export const BroadcastTypes: string[] = [BroadcastTypesEnum.VOICE, BroadcastTypesEnum.EMAIL, BroadcastTypesEnum.SMS]

export enum BroadcastStatusEnum {
  DRAFT = "draft",
  SCHEDULED = "scheduled",
  SENT = "sent",
  ERROR = "error",
}
export type BroadcastStatusType = BroadcastStatusEnum.DRAFT | BroadcastStatusEnum.SCHEDULED | BroadcastStatusEnum.SENT | BroadcastStatusEnum.ERROR

export const BroadcastStatusTypes: string[] = [BroadcastStatusEnum.DRAFT, BroadcastStatusEnum.SCHEDULED, BroadcastStatusEnum.SENT, BroadcastStatusEnum.ERROR]

export enum BroadcastResponseActionEnum {
  SAVE = "save",
  SAVE_EXIT = "save_exit",
  SUBMIT = "submit",
}

export type BroadcastResponseActionType = BroadcastResponseActionEnum.SAVE | BroadcastResponseActionEnum.SAVE_EXIT | BroadcastResponseActionEnum.SUBMIT

export enum SendBroadcastStatusEnum {
  ERROR = "error",
  OPT_OUT = "opt_out",
  PENDING = "pending",
  SUCCESS = "success",
}

export type SendBroadcastStatusType = SendBroadcastStatusEnum.SUCCESS | SendBroadcastStatusEnum.PENDING | SendBroadcastStatusEnum.ERROR | SendBroadcastStatusEnum.OPT_OUT

export const BroadcastTransactionStatusTypes: string[] = [SendBroadcastStatusEnum.SUCCESS, SendBroadcastStatusEnum.PENDING, SendBroadcastStatusEnum.ERROR, SendBroadcastStatusEnum.OPT_OUT]

export type BroadcastSortBy = "title" | "name" | "recipients" | "type" | "replies" | "status" | "updatedAt"

export type BroadcastSortDirection = "asc" | "desc"

export enum BroadcastTransactionTypeEnum {
  STANDARD = "STANDARD",
  EPHEMERAL = "EPHEMERAL",
}
