import { Sizes } from "react-sizes"

interface Size {
  mobileS: string
  mobileM: string
  mobileL: string
  tablet: string
  laptop: string
  laptopL: string
  desktop: string
  desktopL: string
}

const size: Size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "1920px",
  desktopL: "2560px",
}

export const device: Size = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
}

export type MapSizesToPropsFunc = (width: Sizes) => { isMobile: boolean; isTablet: boolean; isLaptop: boolean; isDesktop: boolean; width: number }
export type MapSizesToProps = { isMobile: boolean; width: number; isTablet: boolean; isLaptop: boolean; isDesktop: boolean }
export const mapSizesToProps: MapSizesToPropsFunc = ({ width }) => ({
  isMobile: width < 769,
  isTablet: width < 1024 && width > 768,
  isLaptop: width < 1441 && width > 1023,
  isDesktop: width > 1440,
  width,
})
