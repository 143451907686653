import ActionType from "../../types/ActionType"
import Pager from "../actions/pager"

export interface PagerStore {
  outreachQueuesPage: number
  broadcastsPage: number
  callLogPage: number
  callLogsPage: number
  allCallerSurveysPage: number
  actionRequiredCallerSurveysPage: number
  notesPersonPage: number
  notesPersonListPage: number
  surveysPersonPage: number
  volunteersPage: number
  recipientsPage: number
  personOrganizationsPage: number
  organizationsPage: number
  organizationCallersPage: number
  organizationRecipientsPage: number
  organizationOutreachesPage: number
}

const INITIAL_STATE: PagerStore = {
  outreachQueuesPage: 1,
  broadcastsPage: 1,
  callLogPage: 1,
  callLogsPage: 1,
  allCallerSurveysPage: 1,
  actionRequiredCallerSurveysPage: 1,
  notesPersonPage: 1,
  notesPersonListPage: 1,
  surveysPersonPage: 1,
  volunteersPage: 1,
  recipientsPage: 1,
  personOrganizationsPage: 1,
  organizationsPage: 1,
  organizationCallersPage: 1,
  organizationRecipientsPage: 1,
  organizationOutreachesPage: 1,
}

export default (state = INITIAL_STATE, action: ActionType): PagerStore => {
  switch (action.type) {
    case Pager.SET_CURRENT_PAGE_OUTREACH_QUEUES: {
      return {
        ...state,
        outreachQueuesPage: action.payload,
      }
    }

    case Pager.SET_CURRENT_PAGE_BROADCASTS: {
      return {
        ...state,
        broadcastsPage: action.payload,
      }
    }

    case Pager.SET_CURRENT_PAGE_VOLUNTEERS: {
      return {
        ...state,
        volunteersPage: action.payload,
      }
    }

    case Pager.SET_CURRENT_PAGE_RECIPIENTS: {
      return {
        ...state,
        recipientsPage: action.payload,
      }
    }

    case Pager.SET_CURRENT_PAGE_CALL_LOGS: {
      return {
        ...state,
        callLogsPage: action.payload,
      }
    }

    case Pager.SET_PAGE_CALL_LOG: {
      return {
        ...state,
        callLogsPage: action.payload,
      }
    }

    case Pager.SET_CURRENT_PAGE_ALL_CALLER_SURVEYS: {
      return {
        ...state,
        allCallerSurveysPage: action.payload,
      }
    }

    case Pager.SET_CURRENT_PAGE_ACTION_REQUIRED_CALLER_SURVEYS: {
      return {
        ...state,
        actionRequiredCallerSurveysPage: action.payload,
      }
    }

    case Pager.SET_CURRENT_PAGE_PERSON_ORGANIZATIONS: {
      return {
        ...state,
        personOrganizationsPage: action.payload,
      }
    }

    case Pager.SET_CURRENT_PAGE_ORGANIZATIONS: {
      return {
        ...state,
        organizationsPage: action.payload,
      }
    }

    case Pager.SET_CURRENT_PAGE_ORGANIZATION_VOLUNTEERS: {
      return {
        ...state,
        organizationCallersPage: action.payload,
      }
    }

    case Pager.SET_CURRENT_PAGE_ORGANIZATION_RECIPIENTS: {
      return {
        ...state,
        organizationRecipientsPage: action.payload,
      }
    }

    case Pager.SET_CURRENT_PAGE_ORGANIZATION_OUTREACHES: {
      return {
        ...state,
        organizationOutreachesPage: action.payload,
      }
    }

    case Pager.SET_CURRENT_PAGE_NOTES_PERSON: {
      return {
        ...state,
        notesPersonPage: action.payload,
      }
    }

    case Pager.SET_CURRENT_PAGE_NOTES_PERSON_LIST: {
      return {
        ...state,
        notesPersonListPage: action.payload,
      }
    }

    case Pager.SET_CURRENT_PAGE_SURVEYS_PERSON: {
      return {
        ...state,
        surveysPersonPage: action.payload,
      }
    }

    case Pager.RESET_PAGES: {
      return {
        ...state,
        ...INITIAL_STATE,
      }
    }

    default:
      return { ...state }
  }
}
