import { ExportStatusEnum } from "../../helpers/ExportHelper"
import ActionType from "../../types/ActionType"
import { CallLog, ExportedCallLogs } from "../../types/CallLogsType"
import CallLogsActions from "../actions/callLogs"

export interface CallLogsStore {
  callee: string
  caller: string
  callLogs: CallLog[]
  callLogsExportStatus: ExportStatusEnum
  callLogAnonymousExportStatus: ExportStatusEnum
  callLogsToExport: ExportedCallLogs | null
  callLogsAnonymousToExport: ExportedCallLogs | null
  callLogsReportRequestStatus: ExportStatusEnum
  duration: number
  errorMessage: string
  loadingCallLogs: boolean
  totalCallLogsPages: number
  page: number
  status: string
  dateFrom: string
  dateTo: string
}

const INITIAL_STATE: CallLogsStore = {
  callee: "",
  caller: "",
  callLogs: [],
  callLogsExportStatus: ExportStatusEnum.NOT_EXPORTED,
  callLogAnonymousExportStatus: ExportStatusEnum.NOT_EXPORTED,
  callLogsReportRequestStatus: ExportStatusEnum.NOT_EXPORTED,
  callLogsToExport: null,
  callLogsAnonymousToExport: null,
  duration: 0,
  errorMessage: "",
  loadingCallLogs: false,
  totalCallLogsPages: 1,
  page: 1,
  status: "",
  dateFrom: "",
  dateTo: "",
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case CallLogsActions.FETCH_CALL_LOGS_TRIGGER: {
      return {
        ...state,
        loadingCallLogs: true,
      }
    }

    case CallLogsActions.FETCH_CALL_LOGS_SUCCESS: {
      return {
        ...state,
        loadingCallLogs: false,
        callLogs: action.payload.callLogs,
        caller: action.payload.callLogs.caller,
        calle: action.payload.callLogs.calle,
        totalCallLogsPages: action.payload.pager.totalPages,
        page: action.payload.pager,
        status: action.payload.callLogs.status,
        duration: action.payload.callLogs.duration,
        dateFrom: action.payload.callLogs.dateFrom,
        dateTo: action.payload.callLogs.dateTo,
      }
    }

    case CallLogsActions.FETCH_CALL_LOGS_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        loadingCallLogs: false,
      }
    }

    case CallLogsActions.EXPORT_CALL_LOGS_TRIGGER: {
      return {
        ...state,
        callLogsToExport: null,
        callLogsExportStatus: ExportStatusEnum.TRIGGERED,
      }
    }

    case CallLogsActions.EXPORT_CALL_LOGS_SUCCESS: {
      return {
        ...state,
        callLogsToExport: action.payload,
        callLogsExportStatus: ExportStatusEnum.SUCCESS,
      }
    }

    case CallLogsActions.EXPORT_CALL_LOGS_ERROR: {
      return {
        ...state,
        callLogsToExport: null,
        callLogsExportStatus: ExportStatusEnum.ERROR,
      }
    }

    case CallLogsActions.CREATE_REPORT_REQUEST_CALL_LOGS_TRIGGER: {
      return {
        ...state,
        callLogsReportRequestStatus: ExportStatusEnum.TRIGGERED,
      }
    }

    case CallLogsActions.CREATE_REPORT_REQUEST_CALL_LOGS_SUCCESS: {
      return {
        ...state,
        callLogsReportRequestStatus: ExportStatusEnum.SUCCESS,
      }
    }

    case CallLogsActions.CREATE_REPORT_REQUEST_CALL_LOGS_ERROR: {
      return {
        ...state,
        callLogsReportRequestStatus: ExportStatusEnum.ERROR,
      }
    }

    case CallLogsActions.EXPORT_CALL_LOGS_CLEAR: {
      return {
        ...state,
        callLogsToExport: null,
        callLogsExportStatus: ExportStatusEnum.NOT_EXPORTED,
      }
    }

    case CallLogsActions.ANONYMOUS_EXPORT_CALL_LOGS_TRIGGER: {
      return {
        ...state,
        callLogsAnonymousToExport: null,
        callLogAnonymousExportStatus: ExportStatusEnum.TRIGGERED,
      }
    }

    case CallLogsActions.ANONYMOUS_EXPORT_CALL_LOGS_SUCCESS: {
      return {
        ...state,
        callLogsAnonymousToExport: action.payload,
        callLogAnonymousExportStatus: ExportStatusEnum.SUCCESS,
      }
    }

    case CallLogsActions.ANONYMOUS_EXPORT_CALL_LOGS_ERROR: {
      return {
        ...state,
        callLogsAnonymousToExport: null,
        callLogAnonymousExportStatus: ExportStatusEnum.ERROR,
      }
    }

    case CallLogsActions.ANONYMOUS_EXPORT_CALL_LOGS_CLEAR: {
      return {
        ...state,
        callLogsAnonymousToExport: null,
        callLogAnonymousExportStatus: ExportStatusEnum.NOT_EXPORTED,
      }
    }

    default: {
      return state
    }
  }
}
