import { Dispatch } from "redux"

type SetCurrentPage = (page: number) => void
type ResetPages = () => void

export interface PagerActions {
  setCurrentPageOutreachQueues: SetCurrentPage
  setCurrentPageBroadcasts: SetCurrentPage
  setCurrentPageCallLogs: SetCurrentPage
  setPageCallLog: SetCurrentPage
  setCurrentPageAllCallerSurveys: SetCurrentPage
  setCurrentPageActionRequiredCallerSurveys: SetCurrentPage
  setCurrentPageCallers: SetCurrentPage
  setCurrentPageNotesPerson: SetCurrentPage
  setCurrentPageNotesPersonList: SetCurrentPage
  setCurrentPageRecipients: SetCurrentPage
  setCurrentPagePersonOrganizations: SetCurrentPage
  setCurrentPageOrganizations: SetCurrentPage
  setCurrentPageOrganizationOutreaches: SetCurrentPage
  setCurrentPageOrganizationRecipients: SetCurrentPage
  setCurrentPageOrganizationCallers: SetCurrentPage
  setCurrentPageSurveysPerson: SetCurrentPage
  resetPages: ResetPages
}

class Pager {
  public static SET_CURRENT_PAGE_OUTREACH_QUEUES = "SET_CURRENT_PAGE_OUTREACH_QUEUES"
  public static SET_CURRENT_PAGE_BROADCASTS = "SET_CURRENT_PAGE_BROADCASTS"
  public static SET_CURRENT_PAGE_NOTES_PERSON = "SET_CURRENT_PAGE_NOTES_PERSON"
  public static SET_CURRENT_PAGE_NOTES_PERSON_LIST = "SET_CURRENT_PAGE_NOTES_PERSON_LIST"
  public static SET_CURRENT_PAGE_SURVEYS_PERSON = "SET_CURRENT_PAGE_SURVEYS_PERSON"
  public static SET_CURRENT_PAGE_VOLUNTEERS = "SET_CURRENT_PAGE_VOLUNTEERS"
  public static SET_CURRENT_PAGE_RECIPIENTS = "SET_CURRENT_PAGE_RECIPIENTS"
  public static SET_CURRENT_PAGE_PERSON_ORGANIZATIONS = "SET_CURRENT_PAGE_PERSON_ORGANIZATIONS"
  public static SET_CURRENT_PAGE_ORGANIZATIONS = "SET_CURRENT_PAGE_ORGANIZATIONS"
  public static SET_CURRENT_PAGE_ORGANIZATION_VOLUNTEERS = "SET_CURRENT_PAGE_ORGANIZATION_VOLUNTEERS"
  public static SET_CURRENT_PAGE_ORGANIZATION_RECIPIENTS = "SET_CURRENT_PAGE_ORGANIZATION_RECIPIENTS"
  public static SET_CURRENT_PAGE_ORGANIZATION_OUTREACHES = "SET_CURRENT_PAGE_ORGANIZATION_OUTREACHES"
  public static SET_CURRENT_PAGE_CALL_LOGS = "SET_CURRENT_PAGE_CALL_LOGS"
  public static SET_CURRENT_PAGE_ALL_CALLER_SURVEYS = "SET_CURRENT_PAGE_ALL_CALLER_SURVEYS"
  public static SET_CURRENT_PAGE_ACTION_REQUIRED_CALLER_SURVEYS = "SET_CURRENT_PAGE_ACTION_REQUIRED_CALLER_SURVEYS"
  public static SET_PAGE_CALL_LOG = "SET_PAGE_CALL_LOG"
  public static RESET_PAGES = "RESET_PAGES"

  public static setCurrentPageOutreachQueues: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_OUTREACH_QUEUES,
      payload: page,
    })
  }

  public static setCurrentPageBroadcasts: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_BROADCASTS,
      payload: page,
    })
  }

  public static setCurrentPageCallers: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_VOLUNTEERS,
      payload: page,
    })
  }

  public static setCurrentPageRecipients: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_RECIPIENTS,
      payload: page,
    })
  }

  public static setCurrentPageCallLogs: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_CALL_LOGS,
      payload: page,
    })
  }

  public static setCurrentPageAllCallerSurveys: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_ALL_CALLER_SURVEYS,
      payload: page,
    })
  }

  public static setCurrentPageActionRequiredCallerSurveys: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_ACTION_REQUIRED_CALLER_SURVEYS,
      payload: page,
    })
  }

  public static setPageCallLog: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_PAGE_CALL_LOG,
      payload: page,
    })
  }

  public static setCurrentPagePersonOrganizations: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_PERSON_ORGANIZATIONS,
      payload: page,
    })
  }

  public static setCurrentPageOrganizations: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_ORGANIZATIONS,
      payload: page,
    })
  }

  public static setCurrentPageOrganizationCallers: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_ORGANIZATION_VOLUNTEERS,
      payload: page,
    })
  }

  public static setCurrentPageOrganizationRecipients: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_ORGANIZATION_RECIPIENTS,
      payload: page,
    })
  }

  public static setCurrentPageOrganizationOutreaches: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_ORGANIZATION_OUTREACHES,
      payload: page,
    })
  }

  public static setCurrentPageNotesPerson: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_NOTES_PERSON,
      payload: page,
    })
  }

  public static setCurrentPageNotesPersonList: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_NOTES_PERSON_LIST,
      payload: page,
    })
  }

  public static setCurrentPageSurveysPerson: SetCurrentPage = (page) => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.SET_CURRENT_PAGE_SURVEYS_PERSON,
      payload: page,
    })
  }

  public static resetPages: ResetPages = () => (dispatch: Dispatch) => {
    dispatch({
      type: Pager.RESET_PAGES,
    })
  }
}

export default Pager
