// tslint:disable: no-any
import React from "react"
import { CSVLink } from "react-csv"

import Icon from "./Icon"
import IntlMessages from "../../helpers/IntlMessages"
import { DownloadButton } from "../styles/Buttons"

interface Header {
  label: string
  key: string
}

const CsvDownload: React.FC = () => {
  const headers: Header[] = [
    { label: "Role", key: "role" },
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "User Name", key: "userName" },
    { label: "Phone", key: "phone" },
    { label: "Phone Type", key: "personDetail.phoneType" },
    { label: "Alternate Phone", key: "phoneAlternate" },
    { label: "Alternate Phone Type", key: "personDetail.phoneAlternateType" },
    { label: "Email", key: "email" },
    { label: "Preferred Name", key: "preferredName" },
    { label: "Program", key: "programName" },
    { label: "PIN", key: "pin" },
    { label: "Gender", key: "personDetail.gender" },
    { label: "Zip Code", key: "personDetail.zipCode" },
    { label: "Date of Birth", key: "personDetail.dateOfBirth" },
    { label: "Hobbies", key: "personDetail.hobbies" },
    { label: "Phone Call Behavior", key: "personDetail.phoneCallBehavior" },
    { label: "Preferred Time For Call", key: "personDetail.preferredTimeForCall" },
    {
      label: "Preferred Method of Communication",
      key: "personDetail.preferredMethodOfCommunication",
    },
    { label: "Preferred Language", key: "personDetail.preferredLanguage" },
    { label: "External ID", key: "externalPersonId" },
  ]

  const fileData: any = [
    {
      role: "RECIPIENT",
      firstName: "Test",
      lastName: "Test",
      userName: "Test",
      phone: "+15555555555",
      phoneAlternate: "",
      email: "",
      preferredName: "",
      program: "Test",
      pin: "",
      externalPersonId: "",
    },
    {
      role: "CALLER",
      firstName: "Test",
      lastName: "Test",
      userName: "Test",
      phone: "+13025555555",
      phoneAlternate: "",
      email: "",
      preferredName: "",
      program: "Test",
      pin: "1234",
      externalPersonId: "",
      personDetail: {
        zipCode: "47488",
        dateOfBirth: "1987-02-14",
        gender: "Male",
        preferredLanguage: "English",
        hobbies: "Play soccer",
        phoneCallBehavior: "Somewhat Talkative",
        preferredTimeForCall: "Morning|Afternoon|Evening",
        preferredMethodOfCommunication: "SMS|Email|Voice",
        phoneType: "Mobile",
        phoneAlternateType: "Landline",
      },
    },
  ]

  return (
    <CSVLink headers={headers} data={fileData} filename='callhub-example.csv' className='download-button'>
      <DownloadButton data-testid='csv-download-example-button' className='download-button' color='light'>
        <Icon icon='simple-icon-cloud-download' size='30' />
        <IntlMessages id='onboarding.download-csv' />
      </DownloadButton>
    </CSVLink>
  )
}

export default CsvDownload
