import ActionType from "../../types/ActionType"
import PersonOrganizationSurveysActions from "../actions/personOrganizationSurveys"
import { PersonOrganizationSurveyType } from "../../types/PersonOrganizationSurveyType"

export interface PersonOrganizationSurveysStore {
  errorMessage: string
  loadingPersonOrganizationSurvey: boolean
  personOrganizationSurveySuccess: string
  personOrganizationSurvey: PersonOrganizationSurveyType | null
}

const INITIAL_STATE: PersonOrganizationSurveysStore = {
  errorMessage: "",
  loadingPersonOrganizationSurvey: true,
  personOrganizationSurvey: null,
  personOrganizationSurveySuccess: "",
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case PersonOrganizationSurveysActions.FETCH_PERSON_ORGANIZATION_SURVEYS_TRIGGER: {
      return {
        ...state,
        loadingPersonOrganizationSurvey: true,
        personOrganizationSurveySuccess: "",
      }
    }

    case PersonOrganizationSurveysActions.FETCH_PERSON_ORGANIZATION_SURVEYS_SUCCESS: {
      return {
        ...state,
        loadingPersonOrganizationSurvey: false,
        personOrganizationSurvey: action.payload,
        personOrganizationSurveySuccess: "",
      }
    }

    case PersonOrganizationSurveysActions.FETCH_PERSON_ORGANIZATION_SURVEYS_ERROR: {
      return {
        ...state,
        loadingPersonOrganizationSurvey: false,
        errorMessage: action.payload,
      }
    }

    case PersonOrganizationSurveysActions.CREATE_PERSON_ORGANIZATION_SURVEY_AND_ANSWER_TRIGGER: {
      return {
        ...state,
        loadingPersonOrganizationSurvey: true,
        personOrganizationSurveySuccess: "",
      }
    }

    case PersonOrganizationSurveysActions.CREATE_PERSON_ORGANIZATION_SURVEY_AND_ANSWER_SUCCESS: {
      return {
        ...state,
        loadingPersonOrganizationSurvey: false,
        personOrganizationSurveySuccess: "success",
      }
    }

    case PersonOrganizationSurveysActions.CREATE_PERSON_ORGANIZATION_SURVEY_AND_ANSWER_ERROR: {
      return {
        ...state,
        loadingPersonOrganizationSurvey: false,
        errorMessage: action.payload,
        personOrganizationSurveySuccess: "",
      }
    }

    case PersonOrganizationSurveysActions.COMPLETE_PERSON_ORGANIZATION_SURVEY_ANSWERS_TRIGGER: {
      return {
        ...state,
        loadingPersonOrganizationSurvey: true,
        personOrganizationSurveySuccess: "",
      }
    }

    case PersonOrganizationSurveysActions.COMPLETE_PERSON_ORGANIZATION_SURVEY_ANSWERS_SUCCESS: {
      return {
        ...state,
        loadingPersonOrganizationSurvey: false,
        personOrganizationSurveySuccess: "success",
      }
    }

    case PersonOrganizationSurveysActions.COMPLETE_PERSON_ORGANIZATION_SURVEY_ANSWERS_ERROR: {
      return {
        ...state,
        loadingPersonOrganizationSurvey: false,
        errorMessage: action.payload,
        personOrganizationSurveySuccess: "",
      }
    }

    case PersonOrganizationSurveysActions.CLEAN_PERSON_ORGANIZATION_SURVEY_STATUS: {
      return {
        ...state,
        personOrganizationSurveySuccess: "",
      }
    }

    default:
      return state
  }
}
