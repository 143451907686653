import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { Organization } from "../types/OrganizationType"

dayjs.extend(utc)

interface FilterOption {
  label: string
  value: string | undefined
}

export interface NameOptions {
  dates: Date[]
  organization: FilterOption
  program: FilterOption
  number: number
}

export interface ExportDateLimitsResponse {
  fromDate: string | null | undefined
  toDate: string | null | undefined
}

type DownloadFromBlob = (data: Blob, fileName: string) => void
type FixStringForFileName = (str: string) => string
type GetCurrentDate = () => string
type GetFileName = (options: NameOptions) => string
type GetOrganizationLabel = (organizations: Organization[], organizationId: string) => string
type GetOrganizationFilterForOrgAdmin = (organizations: Organization[], organizationId: string) => FilterOption
export type ExportButtonStatusMessageId = { [key: string]: string }

export const fixStringForFileName: FixStringForFileName = (str: string) => str.replace(/[\\/:*?"<>|\s]/g, "_")

export const getCurrentDateString: GetCurrentDate = () => {
  const currentDate: Date = new Date()
  return `${currentDate.getMonth() + 1}_${currentDate.getDate()}_${currentDate.getFullYear()}`
}

export const getFileName: GetFileName = ({ dates: [start, end], number, organization, program }) => {
  const organizationName: string = organization?.value ? fixStringForFileName(organization?.label) : "All_Organizations"
  const programName: string = program?.value ? fixStringForFileName(program.label) : "All_Programs"

  const startDate: string = dayjs(start).utc().format("MM-DD-YYYY")
  const endDate: string = dayjs(end).utc().format("MM-DD-YYYY")
  const parsedDate: string = !start ? "All_Time" : !end ? fixStringForFileName(startDate) : fixStringForFileName(`${startDate}-${endDate}`)
  const currentDate: string = getCurrentDateString()

  return `CallStats${number}_${organizationName}_${programName}_${parsedDate}_${currentDate}.csv`
}

export const getOrganizationLabel: GetOrganizationLabel = (organizations, organizationId) => (organizations.find((org) => org.id === organizationId) || {}).name || "Organization"

export const getOrganizationFilterForOrgAdmin: GetOrganizationFilterForOrgAdmin = (organizations, organizationId) => ({
  label: getOrganizationLabel(organizations, organizationId || ""),
  value: organizationId || "",
})

export const downloadFromBlob: DownloadFromBlob = (data: Blob, fileName: string) => {
  const url: string = window.URL.createObjectURL(new Blob([data]))
  const link: HTMLAnchorElement = document.createElement("a")
  link.href = url
  link.setAttribute("download", fileName)
  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}

export const exportDateLimits = (from?: string | null | undefined, to?: string | null | undefined): ExportDateLimitsResponse => {
  if (!from && !to) {
    return {
      fromDate: dayjs().subtract(90, "day").toISOString(),
      toDate: dayjs().toISOString(),
    }
  }

  const fromDate = dayjs(from)
  const toDate = dayjs(to)

  if (from && !to) {
    const today = dayjs()
    const fromDate90DaysAhead = fromDate.add(90, "day")
    return {
      fromDate: from,
      toDate: fromDate90DaysAhead.isAfter(today) ? today.toISOString() : fromDate90DaysAhead.toISOString(),
    }
  }

  if (!from && to) {
    return {
      fromDate: toDate.subtract(90, "day").toISOString(),
      toDate: to,
    }
  }

  if (toDate.diff(fromDate, "days") > 365) {
    return {
      fromDate: toDate.subtract(1, "year").toISOString(),
      toDate: to,
    }
  }

  return { fromDate: from, toDate: to }
}

export const calculateExportDatesRange = (from: Date | null, to: Date | null) => {
  const isoFrom = from && dayjs(from).toISOString()
  const isoTo = to && dayjs(to).toISOString()

  const { fromDate, toDate } = exportDateLimits(isoFrom, isoTo)

  const formattedFromDate = dayjs(fromDate).format("MM/DD/YYYY")
  const formattedToDate = dayjs(toDate).format("MM/DD/YYYY")

  return `${formattedFromDate} - ${formattedToDate}`
}
export const calculateInitialExportDateRangesFromTo = () => {
  const { fromDate, toDate } = exportDateLimits()

  const formattedFromDate = dayjs(fromDate).format("MM/DD/YYYY")
  const formattedToDate = dayjs(toDate).format("MM/DD/YYYY")

  return {
    fromDate: formattedFromDate,
    toDate: formattedToDate,
  }
}

export enum ExportStatusEnum {
  NOT_EXPORTED = 0,
  TRIGGERED,
  SUCCESS,
  DOWNLOAD_COMPLETED,
  ERROR,
}

export const getExportButtonColorByStatus = (status: ExportStatusEnum) => {
  switch (status) {
    case ExportStatusEnum.ERROR:
      return "danger"
    case ExportStatusEnum.SUCCESS:
      return "success"
    case ExportStatusEnum.DOWNLOAD_COMPLETED:
      return "success"
    default:
      return "primary"
  }
}
export const exportButtonStatusMessageId: ExportButtonStatusMessageId = {
  [ExportStatusEnum.DOWNLOAD_COMPLETED]: "general.download-complete",
  [ExportStatusEnum.NOT_EXPORTED]: "general.export",
  [ExportStatusEnum.TRIGGERED]: "general.processing",
  [ExportStatusEnum.SUCCESS]: "general.download",
  [ExportStatusEnum.ERROR]: "general.error",
}

export const anonymizeExportButtonStatusMessageId: ExportButtonStatusMessageId = {
  [ExportStatusEnum.DOWNLOAD_COMPLETED]: "general.download-complete",
  [ExportStatusEnum.NOT_EXPORTED]: "general.anonymize-export",
  [ExportStatusEnum.TRIGGERED]: "general.processing",
  [ExportStatusEnum.SUCCESS]: "general.download",
  [ExportStatusEnum.ERROR]: "general.error",
}
