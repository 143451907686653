import { Dispatch } from "redux"
import { AxiosResponse } from "axios"

import API from "../../Api"
import AuthHelper from "../../helpers/AuthHelper"

type ClearPersonDetailDefinitions = () => (dispatch: Dispatch) => void
type FetchPersonDetailDefinitions = () => (dispatch: Dispatch) => void

export interface PersonDetailsActions {
  clearPersonDetailDefinitions: ClearPersonDetailDefinitions
  fetchPersonDetailDefinitions: FetchPersonDetailDefinitions
}

class PersonDetails {
  public static FETCH_PERSONDETAILDEFINITIONS_TRIGGER = "FETCH_PERSONDETAILDEFINITIONS_TRIGGER"
  public static FETCH_PERSONDETAILDEFINITIONS_SUCCESS = "FETCH_PERSONDETAILDEFINITIONS_SUCCESS"
  public static FETCH_PERSONDETAILDEFINITIONS_ERROR = "FETCH_PERSONDETAILDEFINITIONS_ERROR"

  public static CLEAR_PERSONDETAILDEFINITIONS = "CLEAR_PERSONDETAILDEFINITIONS"

  public static fetchPersonDetailDefinitions: FetchPersonDetailDefinitions = () => async (dispatch) => {
    let res: AxiosResponse
    dispatch({
      type: PersonDetails.FETCH_PERSONDETAILDEFINITIONS_TRIGGER,
    })

    try {
      res = await API.get("/v2/persondetails/definitions", {
        headers: AuthHelper.getAdminHeaders(),
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: PersonDetails.FETCH_PERSONDETAILDEFINITIONS_ERROR,
      })

      return
    }
    dispatch({
      payload: res.data.data.personDetailDefinitions,
      type: PersonDetails.FETCH_PERSONDETAILDEFINITIONS_SUCCESS,
    })
  }

  public static clearPersonDetailDefinitions: ClearPersonDetailDefinitions = () => (dispatch: Dispatch) => {
    dispatch({
      type: PersonDetails.CLEAR_PERSONDETAILDEFINITIONS,
    })
  }
}

export default PersonDetails
